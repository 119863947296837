export const TRANSLATIONS_EN = {
    send: 'Send',
    subscribeMsg: 'Contact us via e-mail.',
    sendMsg:'Send Message',
    writeUrEmail: 'Write your mail here',
    aboutOjee: 'About Ogee',
    applyFinishingCompany: 'Finishing companies applying',
    terms: 'Terms of usage',
    urRate: 'Your evaluation of our services',
    applycomplains: 'Send a Complaint',
    privacyPolicy: 'Privacy Policy',
    FAQ: 'Frequently asked questions',
    right: '@2023- All rights reserved to Ogeedecor',
    designedBy: 'Developed by ',
    recommendedForU: 'Recommended for you',
    roomName: 'Room name',
    roomDetails: 'Kitchen / economy / modern / 40 m',
    viewMore: 'See More',
    viewMoreFrom: 'See More ',
    roomGallary: 'Gallery',
    roomType: 'Kitchen',
    roomType2: 'Living Rooms',
    roomType3: 'Offices',
    searchFor: 'Press here to search by filtering',
    rooms: 'Rooms',
    all: 'All',
    bedrooms: 'BedRooms',
    kidsRoom: 'Kids Bedroom',
    livingRoom: 'Livingroom',
    receptionRoom: 'Reception',
    bathRooms: 'Bathrooms',
    wachingRoom: 'Laundry room',
    offices: 'Offices',
    road: 'Corridor',
    headroad: 'Balcony & Terrace',
    gamingRoom: 'Gaming Room',
    rawMaterialAndProducts: 'Raw materials and products',
    woodenGlade: 'Wooden Glade',
    wallpaper: 'WallPaper',
    stone: 'Stone',
    gypsumDecoration: 'Gypsum decorations',
    HDF: 'HDF wood',
    galde3d: '3D Glade',
    naturalWood: 'Natural wood',
    marble: 'Marble',
    egyptionGranite: 'Egyptian granite',
    ceramic: 'Ceramic',
    porcelain: 'Porcelain',
    designType: 'Design Type',
    classic: 'Classic',
    newClassic: 'New-Classic',
    modern: 'Modern',
    simple: 'Minimalist',
    finishingCost: 'Finishing Cost',
    economic: 'Economy',
    medium: 'Medium',
    dulex: 'Luxurios',
    history: 'The date',
    random: 'Random',
    fromNewToOld: 'From new to old',
    fromOldToNew: 'From the old to the new',
    roomSize: 'Room size',
    fromSmallToBig: 'From small to large',
    formBigToSmall: 'From big to small',
    coast: 'Cost',
    lowestToHightPrice: 'From lowest price to highest price',
    highestToLowestPrice: 'From the highest price to the lowest price',
    search: 'Search',
    viewMoreOfRooms: 'Explore more than {{numberOfRooms}} different room types',
    viewMoreOf: 'See more from {{roomName}}',
    neadMoreRoomsQuestion: 'Want to see more rooms?',
    useSearch: 'Use the filter search to find hundreds of rooms to inspire you.',
    readArtical: 'Read the Article',
    articalsMayLiked: 'Articles you may like',
    articalsMayLinked: 'Related Articles',
    seeMore: 'See more',
    designType: 'Designtype',
    DesignSeeMore_title: 'Browse different designs from which you can be inspired and implement your unit design',
    DesignSeeMore_description: 'Ogee is the first platform to provide you with a large gallery of different interior designs that will help you choose the type of design you would like to implement.',
    Register: 'Register Now',
    header_title: 'Pricing the approximate cost of your unit',
    header_description: 'Ogee is the first platform to provide you with the choice of finishing cost and interior design that you would like to implement from anywhere.',
    header_title_2: 'With Ogee, you will not need a specialist to estimate the finishing cost',
    header_description_2: 'Come with you at every step in pricing the finishing of your unit yourself, with ease, freely choosing the appropriate cost for your budget',
    header_title_3: 'Choose from the finishing services available on the platform',
    header_description_3: 'Ogee provides you with various finishing services that will help you finish off your unit easily, including interior design, supervision of implementation, and other services.',
    addUnit: 'Add Unit',
    add: 'Add',
    cancelbackToHome: 'Cancel and Return to the home page',
    home: 'Back to the home page',
    UnitForm: 'Add Unit',
    rooms: 'Rooms',
    backToRooms: 'Back to rooms',
    writeUnitName: 'Write Your Unit Name',
    unitName: 'Unit Name',
    unitType: 'Unit type according to registration',
    residential: 'Residential',
    commercial: 'Commercial',
    managerial: 'Administrative',
    next: 'Next',
    soon: 'Soon',
    whatTypeUnit: 'What is your unit type?',
    selectlocation: 'Choose the location of the unit',
    selectRegion: 'Select the unit area',
    selectFloor: 'Select the floor',
    addRoom: 'Add Room',
    edit: 'Edit',
    update:'update',
    roomsFooterQoute: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم ',
    saveToDraft: 'Save to draft',
    viewPrice: 'Show Price',
    sendToDesign: 'Submit a design request',
    agreeToTermsAndConditions: 'By clicking submit, you agree to',
    terms: 'Terms of usage',
    and: ' and ',
    ploicy: 'Privacy Policy',
    costCaution: '” Attention: the Approximate cost of finishing may change every 7 days“',
    approxCost: 'Estimated Finishing Cost',
    negotiateCost: 'Is the estimated cost more than your budget?',
    pressHere: ' Press Here',
    costCoationTitle: 'Note:',
    costCoationline1: 'The price that you see in front of you is an estimated price that does not include profits for any party. And the final price will be offered to you after design.',
    costCoationline2: 'This price was built on well-made materials that ensure sustainability and a price equal to quality.',
    costCoationline3: 'The estimated price is an indicative price to help Ogee\'s friends finish their units at a cost that is appropriate to what is required.',
    costCoationline4: 'The price may be increased or decreased by no more than 10%.',
    costCoationline5: 'The calculated cost does not include furniture and lighting units of all kinds, nor does it include kitchen units, dressing room units, and bathroom furniture.. The approximate cost includes finishing works only.',
    costCoationline6: 'In the event that you order the full set of Ogee Engineering services, you will receive a 20% discount upon contracting and guarantee your work... The most important thing is that you adhere to your budget and get what you want in terms of quality, time commitment, and a place that suits you.',
    selectyourPlan: 'Choose the service',
    mostSelected: 'Most Chosen',
    selectDate: ' اختر معاد المعاينة',
    selectYourDate: 'اختر معاد المعينة المناسب لك',
    cancelDate: 'إلغاء ميعاد المعاينة',
    sendRequest: 'إرسال طلب معاينة',
    to: 'الي',
    status: 'الحالة',
    bookedDate: 'نعتزر لك فلا يمكنك حجز هذا الموعد لعدم وجود مواعيد متاحة',
    avaDate: 'هذا الموعد متاح للحجز ، سيقوم المعاين بالحضور في الموعد المحدد، نرجوا الإلتزام بالميعاد',
    ava: 'متاح الحجز',
    booked: 'محجوز',
    confirmMsgBookUnit: 'Are you sure to send the request?',
    contactNumber: 'The number to be contacted',
    comfirmRequest: 'Send confirmation',
    backToHomePage: 'Back to homepage',
    DoneSent: 'Sent Succesfully',
    thanks: 'Thanks',
    willContactU: 'Your request has been sent to the Ogee team, and they will contact you within 48 hours at  {{mobileNumber}}',
    rateOhjee: 'Rate our services via',
    googlePlay: 'Google play',
    appStore: 'App store',
    saveInfo: 'Save Information',
    roomFormFooterMsg: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم ',//TODO:REMOVE
    roomName: 'Roomname',
    selectReceptionShap: 'Select Room Shape',
    lShap: 'In the form of a letter L',
    squareShape: 'In the form of a square',
    roomSize: 'Room Space',
    detailsSetting: 'Detailed Settings',
    addFromFav: 'Add From Favorites',
    designType: 'Design Type',
    finishingCosy: 'Finishing Cost',
    detailsUnitMsg: 'In order to add the unit details,you need to select the Design Type and Finishing Cost.',
    walls: 'Walls',
    dominantColo: 'Main Colour',
    wallShap: 'Wall Shape',
    adds: 'Add-On',
    ciel: 'Ceiling',
    floor: 'Floor',
    floorMaterial: 'Floor Material',
    doors: 'Doors',
    doorCount: 'Doors Number',
    width: 'Width',
    lenght: 'Length',
    height: 'Height',
    doorMatrial: 'Doors Material',
    windowMaterial: 'Windows Material',
    windows: 'Windows',
    windowsCount: 'Windows Number',
    addWindow: 'Add-On',
    roomAccessories: 'Room Accessories',
    roomAccessoriesNote: 'This Feature allows you to prepare and install the air conditioner.',
    containAC: 'Air conditioning unit is included',
    notContainAC: 'Air conditioning unit is not included',
    accessories: '{{rommName}} Accessories',
    bathaccessories: 'Bathroom Accessories',
    bathaccessoriesNote: 'Please select accesories for plumbing and electrical installation only',
    bathBase: 'قاعدة',
    bathTub: 'Tub',
    bathShower: 'Shower',
    bathBathTub: 'Bathtub',
    washingMachine: 'Washing Machine',
    bahJacuzzi: 'Jacuzzi',
    electricHeater: 'Electric heater',
    gazHeater: 'Gas heater',
    kitchenaccessories: 'kitchen Accessories',
    dishWasher: 'Dishwasher',
    refrigertaor: 'Refrigerator',
    coojker: 'Cooker',
    deepFreezer: 'Deep Freezer',
    washingMachine: 'Washing Machine',
    microwave: 'Microwave',
    luandryRoomAccessories: 'Laundry room Accessories',
    luandryRoomAccessoriesNote: 'Please select accessories for plumbing and electrical installation',
    twoWashingMachine: 'Two Washing machines',
    height: 'Height',
    Warning: 'Note',
    WarningMsg: 'When adding a design to a room of likes, you will not be able to modify some fields. If you want to modify, you can click on detailed settings.',
    ok: 'Ok',
    withoutAddons: 'Without Adding',
    color561A24: 'color 1',
    colorDF970E: 'color 2',
    colorDDAB4C: 'color 3',
    colorF4CC8E: 'color',
    color0062A9: 'color',
    colorE5313E: 'color',
    colorFFD90A: 'color',
    colorFFFFFF: 'color',
    color29272A: 'color',
    colorA4B3B0: 'color',
    color5B2620: 'color',
    colorAA6941: 'color',
    color102A1F: 'color',
    colorB4D3D5: 'color',
    color015939: 'color',
    color546471: 'color',
    pleaseSelectValue: 'Please choose',
    deleteOK: 'Confirm',
    deleteCancel: 'Cancel',
    unFinished: 'Unfinished',
    finished: 'Finished',
    inComplete: 'Un Completed',
    roomName: 'Roomname',
    doorsErrMsg: 'Please complete the entries related to the doors',
    windowsErrMsg: 'Please complete the entries related to the Windows',
    area: '㎡',
    selectColor: 'Please select a colour',
    studio: 'Studio',
    roomsResult: '(Search Results : {{roomResultLength}}Room)',
    Rooms: 'Rooms',
    keyWords: 'Keywords',
    description: 'Description',
    searchResult: "Search Results",
    next: "Next",
    prev: "Back",
    createAccount: 'Create an Account',
    signIn: 'Login',
    mustLoginSentence: 'Create an account to enjoy Ogee\'s Full features and Services', // to know the cost of finishing the unit
    emptyPage: 'The page is empty',
    uCanAddFavDesign: 'You can add a design to the',
    uaeItAtUrUnit: 'And use it in the design of your unit',
    HomePage: 'home page',
    materialsAndProducts: 'Materials & Products companies Applying',
    favoriteRooms: 'Favorites',
    notifications: 'Notifications',
    groundFloor: 'Ground Floor',
    firstFloor: 'First Floor',
    secondFloor: 'Second Floor',
    thirdFloor: 'Third Floor',
    fourthFloor: 'Fourth Floor',
    fifthFloor: 'Fifth Floor',
    moreThanFifthFloor: 'More Than Fifth Floor',
    MianPage: 'Home Page',
    favPage: 'Favorites',
    packagesPage: 'Services',
    draftPage: 'Draft',
    logOut: 'Log Out',
    guest: 'Sign In',
    guest_name:'Guest',
    createAccout: 'Sign Up',
    more: 'More',
    topNavMsg: 'Create an account to enjoy Ogee\'s Full features and Services.',
    regNow: 'Create an account',
    residential: 'Residential',
    commercial: 'Commercial',
    managerial: 'Managerial',
    house: 'House',
    studio: 'Studio',
    villa: 'Villa',
    duplex: 'Duplex',
    noFavRoomsOfThisType: 'There is currently no liked rooms to this room type',
    FreqAskedQuestion: 'Frequently Asked Question',
    emptyDraft: 'draft is empty',
    noUnits: 'You do not have a unit at the moment, you can start adding your unit details.',
    userName: 'User Name',
    phoneNumber: 'Phone Number',
    eMail: 'E-Mail',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    confirmOn: 'When you click to continue, you agree to',
    termsAndCond: 'terms of use',
    privayPolicy: 'and privacy policy',
    veifyOTP: 'Phone number verification',
    enterOTP: 'Please enter the verificaton code sent in a text message to your number',
    coundRecieveOTP: 'Didn\'t you get the message?',
    resendOTP: 'Resend verification code',
    loginHeader: 'Login',
    forgotPassword: 'Did you forget your password ?',
    worngUserOrPassword: 'Please verify your username or password',
    areUsure: '”Are you sure ?“',
    areUsurefromDuplicate: 'Are you sure to duplicate this room?',
    forgetPassword: 'Did you forget your password ?',
    acceptOn: 'When you press continue, you agree to ',
    userNotExist: 'User not Exist!',
    PhoneRequired: 'Phone Number required!',
    invalidOTP: 'Invalid OTP, Please check again',
    enterNewPassword: 'Enter the new password',
    requiredFileds: 'Please fill required fields',
    RequiredField: 'this field is required',
    passwordMustMatch: 'passwords not matching',
    passwordNotComplex: 'Password must contain at least 1 capital and with min length 6 characters',
    firstName: 'First Name',
    lastName: 'Last Name',
    userName: 'Name',
    gender: 'Gender',
    dob: 'Date of Birth',
    saveData: 'Save Data',
    pressHere: 'Press Here',
    male: 'Male',
    female: 'Female',
    savedSuccessfully: 'Data saved successfully',
    companyName: 'Company Name',
    companyAddress: 'Company Address',
    contactPerson: 'contact Person',
    comapnyPhone: 'Phone number',
    personPosition: 'Job title',
    commercialRegister: 'Commerical Register',
    taxNumber: 'Tax Number',
    previuosWorks: 'Is there any previous work to review?',
    yes: 'Yes',
    no: 'No',
    sendApplication: 'send',
    message: 'Message',
    typeYourComplain: 'Write the subject of the complaint',
    willRespondSoon: 'The Ogee team will study your complaint and get back to you as soon as possible.',
    send: 'Send',
    complaintSentSuccessfully: 'complaint Sent Successfully',
    chooseArea: 'Please choose from the following areas',
    requestSendSuccessfuly: 'request Sent Successfully',
    whoWeAre: 'Who we Are?',
    completeUnitData: 'Please the rooms data to be able to calculate the unit finioshing cost',
    validData: 'Please enter valid data',
    english:'English',
    arabic:'العربية',
    downloadTheApp:'Download the application now!',
    viewMoreOf:'See all features',
    maxNoOfDoors_windows:'Please note that the maximum number of doors and windows is 2.',
    warn:'" alert"',
    fine:'Alright',
    filedRequried:'{{field}} should not be empty',
    passwordInvalid:"password not matched",
    otpInvalid:"Invalid number",
    password6Char:"password must be at least 6 characters or number",
    invalidEmail:"Email not valid",
    invalidPhone:"Phone number not valid",
    selectRoomType:'please select room Type!',
};