import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './RoomDetails.module.scss';
import { useTranslation } from "react-i18next";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as Icons from '../../Assets/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  selectLang
} from '../../Reducer/LangSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
  LIKE_ROOM,
  UN_LIKE_ROOM
} from '../../Graphql/Home/mutations';
import {
  selectHome,
  addList
} from '../../Reducer/HomeSlice';
import {
  MainURL
} from '../../ENV';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { MustLoginModal } from '../../components';

const RoomDetails = () => {
  // let { index } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  var lang = useSelector(selectLang);
  var home = useSelector(selectHome);
  var user = useSelector(selectUser);
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  let { isLiked } = useParams();
  const room = home.roomDetialedObject;
  const getRoomArea = (item) => {
    let area;
    if (item?.width) {
      area = item?.width * item?.length
    }
    if (item?.one) {
      let third = item?.two - item?.four
      let fourth = item?.one - item?.three
      area = (item?.one * item?.four) + (third * fourth)
    }
    if (!area){
      area = 0
    }
    area = area.toFixed(2);
    return lang.name === 'AR' ? area.toLocaleString('ar-EG') : area;
  }
  const [callLikedRoom, likeRoomResp] = useMutation(LIKE_ROOM);
  const [callUnLikedRoom, unlikeRoomResp] = useMutation(UN_LIKE_ROOM);
  const [like, setLike] = useState(false);
  const on_click_likeRoom = (roomId) => {
    if (!user.user.userId) {
      setNotLoggedIn(true)
    } else {
      var nextAction = !like;
      if (nextAction) {
        callLikedRoom({
          variables: {
            userID: +user.user.userId,
            roomId: +roomId
          }
        }).then(result => {
          if (result.data.LikeRoom.done) {
            setLike(nextAction);
          }
        });
      } else {
        callUnLikedRoom({
          variables: {
            userID: +user.user.userId,
            roomId: +roomId
          }
        }).then(result => {
          if (result.data.UnLikeRoom.done) {
            setLike(nextAction);
          }
        });
      }
      navigate(`/RoomDetails/${nextAction ? 1 : 0}`, { replace: true })
    }
  }
  useEffect(() => {
    setLike(isLiked === "0" ? false : true);
  }, []);
  const getRoomImageURL = (image) => {
    var fullURL = MainURL + image;
    return [fullURL];
  }
  // if (!user.user.userId) {
  //   return (
  //     <div className={styles.RoomDetails}>
  //       <MustLoginModal />
  //     </div>)
  // }
  return (
    <div className={styles.RoomDetails}>
      {notLoggedIn && <MustLoginModal />}
      <div className="container">
        <div className={styles.header}>
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6">
              <div className={styles.navLink}>
                {lang.name === 'AR' ? <Icons.AiOutlineArrowRight /> : <Icons.AiOutlineArrowLeft />}
                <Link to='/'>{t('home')}</Link>
                {/* <span> \ </span>
                <Link to='/searchResult'>{t('searchResult')}</Link> */}
                <span> \ </span>
                <Link to={`/roomDetails/${isLiked ? 1 : 0}`}>{lang.name === 'AR' ? room.arabic_name : room.room_names_Fk?.english_name}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className={styles.slider}>
                <div className={styles.likeBtncontainer}>
                  <button className={styles.like_btn} type='button' onClick={() => on_click_likeRoom(room.id)}> </button>
                  {like ? <object className={styles.like_obj} type="image/svg+xml" data={Icons.redLoveIcon}></object> : <object className={styles.like_obj} type="image/svg+xml" data={Icons.loveIcon}></object>}
                </div>
                <div className={styles.image} style={{
                  backgroundImage: `url(${getRoomImageURL(room.image_url)})`
                }}></div>
                <div className={styles.byOgee}>
                  By OGEE
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles.roomName}>
                <h1>{lang.name === 'AR' ? room.arabic_name : room.room_names_Fk?.english_name}</h1>
                <div className={styles.details}>
                  <span>
                    {lang.name === 'AR' ? room.arabic_name : room.room_names_Fk?.english_name}
                  </span>
                  <span> </span>
                  <span>
                    {lang.name === 'AR' ? room.finishing_style_Fk?.arabic_name : room.finishing_style_Fk?.english_name}
                  </span>
                  <span> / </span>
                  <span>
                    {lang.name === 'AR' ? room.finishing_cost_Fk?.arabic_name : room.finishing_cost_Fk?.english_name}
                  </span>
                  <span> / </span>
                  <span>
                    {`${getRoomArea(room)}${t('area')}`}
                  </span>
                </div>
              </div>
              <div className={styles.description}>
                <p className={styles.headLine}>{t('description')}</p>
                <p>{lang.name === 'AR' ? room.Gallery_photo_description : room.Gallery_photo_description_english}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className="row">
            <div className="col-12">
              <p className={styles.headLine}>{t('keyWords')}</p>
            </div>
            <div className="col-12">
              <div className={styles.keyWords}>
                <div className={styles.keyWord}><span>{t('all')}</span></div>
                <div className={styles.keyWord}><span>{lang.name === 'AR' ? room.arabic_name : room.room_names_Fk?.english_name}</span></div>
                <div className={styles.keyWord}>{lang.name === 'AR' ? room.finishing_style_Fk?.arabic_name : room.finishing_style_Fk?.english_name}</div>
                <div className={styles.keyWord}>{lang.name === 'AR' ? room.finishing_cost_Fk?.arabic_name : room.finishing_cost_Fk?.english_name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RoomDetails.propTypes = {};

RoomDetails.defaultProps = {};

export default RoomDetails;
