import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomInput.module.scss';
import { useState } from 'react';
import { validatorsInput } from '../../services/validators';

const CustomInput = ({ className, name, autocomplete, value, readOnly, placeholder, inlineStyle, type, acceptOnly, validators, onChange }) => {

  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setError(validatorsInput(validators, value));
    let ChangeValue = false
    if (value === '') {
      ChangeValue = true
    } else {
      switch (acceptOnly) {
        case 'number':
          let reg = RegExp('\\d', 'i')
          // console.log(value, reg.test(value));
          if (reg.test(value))
            ChangeValue = true
          break;
        default:
          ChangeValue = true
          break;
      }
    }
    if (ChangeValue)
      onChange(value, name);
  }
  return (
    <>
      <input
        name={name}
        className={className + ' form-control mb-1'}
        value={value}
        placeholder={placeholder}
        type={type}
        autocomplete={autocomplete}
        style={inlineStyle}
        readOnly={readOnly}
        onChange={handleChange} />
      {error && <span className='text-danger'>{error.message}</span>}
    </>
  )
};

CustomInput.propTypes = {
  label: PropTypes.string,
  conainerClasses: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validators: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  acceptOnly: PropTypes.oneOf(['number']),
  inlineStyle: PropTypes.object,
  autocomplete:PropTypes.string
};

CustomInput.defaultProps = {
  label: '',
  conainerClasses: '',
  name: '',
  value: '',
  placeholder: '',
  type: 'text',
  validators: [],
  readOnly: false,
  acceptOnly: '',
  inlineStyle: {},
  autocomplete:'off'
};

export default CustomInput;
