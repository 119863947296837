import { combineReducers, configureStore } from '@reduxjs/toolkit'
import UnitReducer from './Reducer/UnitSlice'
import LangSlice from './Reducer/LangSlice';
import HomeSlice from './Reducer/HomeSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import userReducer from './Reducer/UserSLice'
import { useSelector } from 'react-redux';

const rootReducer = combineReducers({
    unit: UnitReducer,
    lang: LangSlice, 
    user:userReducer,
    
    home: HomeSlice
})

// state => state.user
const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)

// export const store = configureStore({
//     reducer: {
//         unit: UnitReducer,
//         lang: LangSlice
//     }
// },
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;