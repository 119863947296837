import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './UnitRoomForm.module.scss';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { t } from 'i18next';
import { CustomInput, RoomCard } from '../../../components'
import { Modal, Button, Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  addList,
  selectUnit,
  setUnitRoomData,
  emptyRoomDimentionsOnShapChange,
  setUnitRoomDoorsOrWindowsCount,
  setUnitRoomDoorsData,
  setUnitRoomWindowsData,
  toggleUnitRoomAccessories,
  setCurrentRoomValues,
  setUnitIfnoParam
} from '../../../Reducer/UnitSlice';
import { selectLang } from '../../../Reducer/LangSlice';
import {
  GET_FINISHING_COSTS_AND_FINISHING_STYLES,
  GET_DECORATIONS,
  GET_LIKED_ROOMS,
} from '../../../Graphql/UnitForm/Queries';
import {
  ADD_ROOM,
  UPDATE_ROOM
} from '../../../Graphql/UnitForm/mutations';
import {
  selectUser
} from '../../../Reducer/UserSLice';
import { MustLoginModal } from '../../../components';
import { MainURL } from '../../../ENV';
import { LoadingSpinner } from '../../../Pages';
import { OgeeButton } from '../../../components';
import { toast } from 'react-toastify';

const UnitRoomForm = () => {
  let { index, updateFlag } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var unit = useSelector(selectUnit);
  let lang = useSelector(selectLang);
  var user = useSelector(selectUser);
  var room = unit?.rooms[index]?.roomFk;
  const [activeFields, setActiveFields] = useState(false);
  const [utilityFactors, setUtilityFactors] = useState([]);
  const [show, setShow] = useState(true);
  const [selectedLikedRoomIDs, setSelectedLikedRoomIDs] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showWarn, setshowWarn] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [roomShap, setRoomShap] = useState('SqureShape'); //LShape  SqureShape
  const { data, loading, error } = useQuery(GET_FINISHING_COSTS_AND_FINISHING_STYLES);
  // let [getFinishingCostAndFinishingStyles, finishingCostAndFinishingStyles] = useLazyQuery(GET_FINISHING_COSTS_AND_FINISHING_STYLES);
  const [getDecorationsList, decorationsList] = useLazyQuery(GET_DECORATIONS);
  const likedRooms = useQuery(GET_LIKED_ROOMS, {
    variables: { userId: +user.user.userId },
  });
  const [addRoom, roomInfo] = useMutation(ADD_ROOM);
  const [updateRoom, updaeRoomInfo] = useMutation(UPDATE_ROOM);
  const defualtRoomName = (room) => {
    if (!updateFlag) {
      if (lang.name == 'AR') {
        return room.room_names_Fk.arabic_name;
      }
      else {
        return room.room_names_Fk.english_name;
      }
    }
    if (room.name == null) {
      if (lang.name == 'AR') {
        return room.room_names_Fk.arabic_name;
      }
      else {
        return room.room_names_Fk.english_name;
      }
    } else {
      return room.name;
    }
  }
  const onChangeRoomName = (value) => {
    dispatch(setUnitRoomData({ index: index, param: { name: 'name', value: value } }));
  }
  const onClick_setRoomShap = (roomShap) => {
    setRoomShap(roomShap);
    dispatch(emptyRoomDimentionsOnShapChange({ index: index, roomShap: roomShap }));
  }
  const onChange_setUnitRoomData = (name, value) => {
    dispatch(setUnitRoomData({ index: index, param: { name: name, value: value } }));
  }
  const getRoomImageURL = (image) => {
    var fullURL = MainURL + image;
    return [fullURL];
  }
  const selectColors = (id) => {
    if (room?.wall_color) {
      return room?.wall_color;
    } else {
      if (id == 10) {
        return '#7FB2D8';
      }
      if (id == 9) {
        return '#BBAB7A';
      }
      if (id == 6) {
        return '#88ACD8';
      }
      if (id == 5) {
        return '#848CD8';
      }
      if (id == 4) {
        return '#048971';
      }
      if (id == 3) {
        return '#AAC699';
      }
      if (id == 2) {
        return '#93A09E';
      }
      if (id == 8) {
        return '#E17A77';
      }
      if (id == 1) {
        return '#D8A33F';
      }
      if (id == 7) {
        return '#99B9C0';
      }
      if (id == 13) {
        return '#5A5955';
      }
      else {
        return '#048971';
      }
    }
  };
  const incDoors = () => {
    let value = room._count.doors;
    if (room._count.doors == 2) {
      setshowWarn(true);
      return;
    } else {
      if (!room._count.doors)
        value = 0
      dispatch(setUnitRoomDoorsOrWindowsCount({ index: index, param: { name: 'doors', value: ++value } }));
    }
  }
  const decDoors = () => {
    let value = room._count.doors;
    if (room._count.doors == 0) {
      return;
    } else {
      if (!room._count.doors)
        value = 1
      dispatch(setUnitRoomDoorsOrWindowsCount({ index: index, param: { name: 'doors', value: --value } }));
    }
  }
  const onChange_setUnitRoomDoorsData = (name, value) => {
    dispatch(setUnitRoomDoorsData({ index: index, param: { name: name, value: value } }));
  }
  const onChange_doorMaterial = (id) => {
    let doorMaterial = unit.DoorDecorations.filter(item => {
      return item.id == id;
    });
    dispatch(setUnitRoomDoorsData({ index: index, param: { name: 'doorMaterial', value: doorMaterial[0] } }));
  }
  const incWindows = () => {
    let value = room._count.windows;
    if (room._count.windows == 2) {
      setshowWarn(true);
      return;
    } else {
      if (!room._count.windows)
        value = 0
      dispatch(setUnitRoomDoorsOrWindowsCount({ index: index, param: { name: 'windows', value: ++value } }));
    }
  }
  const decWindows = () => {
    let value = room._count.windows
    if (room._count.windows == 0) {
      return;
    } else {
      if (!room._count.windows)
        value = 1
      dispatch(setUnitRoomDoorsOrWindowsCount({ index: index, param: { name: 'windows', value: --value } }));
    }
  }
  const onChange_windowMaterial = (id) => {
    let windowMaterial = unit.WindowDecorations.filter(item => {
      return item.id == id;
    });
    dispatch(setUnitRoomWindowsData({ index: index, param: { name: 'windowMaterial', value: windowMaterial[0] } }));
  }
  const onChange_windowAddonMaterial = (id) => {
    let windowAddonMaterial = unit.WindowAddonDecorations.filter(item => {
      return item.id == id;
    });
    dispatch(setUnitRoomWindowsData({ index: index, param: { name: 'windowAddonMaterial', value: windowAddonMaterial[0] } }));
  }
  const onChange_setUnitRoomWindowsData = (name, value) => {
    dispatch(setUnitRoomWindowsData({ index: index, param: { name: name, value: value } }));
  }
  const onChange_wallMaterial = (id) => {
    let wallMaterial = unit.WallDecorations.filter(item => {
      return item.id == id;
    });
    dispatch(setUnitRoomData({ index: index, param: { name: 'wallMaterial', value: wallMaterial[0] } }));
  }
  const onChange_wallAddOns = (name, event) => {
    event.preventDefault();
    let value = event.target.value;
    let itemList = null;
    if (!value) {
      dispatch(setUnitRoomData({ index: index, param: { name: name, value: null } }));
      return;
    }
    unit?.WallAddonDecorations.forEach(item => {
      if (item.id == value) {
        itemList = item;
        return;
      }
    });
    switch (name) {
      case 'wallMaterialAddon1':
        if (room?.wallMaterialAddon2?.id != value) {
          dispatch(setUnitRoomData({ index: index, param: { name: name, value: itemList } }));
        }
        break;
      case 'wallMaterialAddon2':
        if (room?.wallMaterialAddon1?.id != value) {
          dispatch(setUnitRoomData({ index: index, param: { name: name, value: itemList } }));
        }
        break;
    }
  }
  const onChange_cielingMaterial = (id) => {
    let cielingMaterial = unit.CeilingDecorations.filter(item => {
      return item.id == id;
    });
    dispatch(setUnitRoomData({ index: index, param: { name: 'cielingMaterial', value: cielingMaterial[0] } }));
  }
  const onChange_cielingAddOns = (name, event) => {
    event.preventDefault();
    let value = event.target.value;
    let itemList = null;
    if (!value) {
      dispatch(setUnitRoomData({ index: index, param: { name: name, value: null } }));
      return;
    }
    unit?.CeilingAddonDecorations.forEach(item => {
      if (item.id == value) {
        itemList = item;
        return;
      }
    });
    switch (name) {
      case 'cielingMaterialAddon1':
        if (room?.cielingMaterialAddon2?.id != value) {
          dispatch(setUnitRoomData({ index: index, param: { name: name, value: itemList } }));
        }
        break;
      case 'cielingMaterialAddon2':
        if (room?.cielingMaterialAddon1?.id != value) {
          dispatch(setUnitRoomData({ index: index, param: { name: name, value: itemList } }));
        }
        break;
    }
  }
  const onChange_floorMaterial = (id) => {
    let floorMaterial = unit.FloorDecorations.filter(item => {
      return item.id == id;
    });
    dispatch(setUnitRoomData({ index: index, param: { name: 'floorMaterial', value: floorMaterial[0] } }));
  }
  const onChange_toggleAccessories = (value) => {
    dispatch(toggleUnitRoomAccessories({ index: index, value: value }));
  };
  const isAcceChecked = (item) => {
    var flag = false;
    console.log(room.utility_factors);
    room.utility_factors.forEach(element => {
      if (element.utility_factor_Fk.id == item.utility_factor_Fk.id) {
        flag = true
      }
    });
    return flag;
  };
  const getValueOfListById = (list, id) => {
    let result;
    list.filter(item => {
      if (item.id == id) {
        result = item;
        return;
      }
    })
    return result;
  }
  const onClick_likedRoom = (likedRoomID) => {
    if (selectedLikedRoomIDs.includes(likedRoomID)) {
      setActiveFields(false);
      setSelectedLikedRoomIDs((current) => current.filter((roomId) => roomId !== likedRoomID));
      dispatch(setUnitRoomData({ index: index, param: { name: "wallMaterial", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "wallMaterialAddon1", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "wallMaterialAddon2", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "wall_color", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "cielingMaterial", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "cielingMaterialAddon1", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "cielingMaterialAddon2", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "floorMaterial", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "finishing_cost_Fk", value: null } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "finishing_style_Fk", value: null } }));
    } else {
      setSelectedLikedRoomIDs([likedRoomID]);
      var likedRoomData = likedRooms.data.getLikedrooms.filter(item => item.roomID == likedRoomID)[0].roomFk;
      dispatch(setUnitRoomData({ index: index, param: { name: "wallMaterial", value: likedRoomData.wallMaterial } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "wallMaterialAddon1", value: likedRoomData.wallMaterialAddon1 } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "wallMaterialAddon2", value: likedRoomData.wallMaterialAddon2 } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "wall_color", value: likedRoomData.wall_color } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "cielingMaterial", value: likedRoomData.cielingMaterial } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "cielingMaterialAddon1", value: likedRoomData.cielingMaterialAddon1 } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "cielingMaterialAddon2", value: likedRoomData.cielingMaterialAddon2 } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "floorMaterial", value: likedRoomData.floorMaterial } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "finishing_cost_Fk", value: likedRoomData.finishing_cost_Fk } }));
      dispatch(setUnitRoomData({ index: index, param: { name: "finishing_style_Fk", value: likedRoomData.finishing_style_Fk } }));
      setDecorationDoorsAndWindows();
    }


    // // console.log(selectedLikedRoomIDs);
  }
  const onClick_isAirConditioning = (flag) => {
    dispatch(setUnitRoomData({ index: index, param: { name: 'isAirConditioning', value: flag } }));
  }
  const saveRoom = () => {
    let procced = true;
    setErrorMsg(null);
    let doors = {};
    let windows = {};
    let accessories = [];
    if (room?._count.doors >= 1) {
      if (!room.doors[0]?.height || !room.doors[0]?.width || !room.doors[0]?.doorMaterial) {
        procced = false;
        setErrorMsg('doorsErrMsg');
      } else {
        doors = {
          decorationID: +room.doors[0].doorMaterial.id,
          width: +room.doors[0].width,
          height: +room.doors[0].height,
          doors_count: +room._count.doors,
        };
      }
    }
    if (room?._count.windows >= 1) {
      if (!room.windows[0]?.height || !room.windows[0]?.width || !room.windows[0]?.windowMaterial) {
        procced = false;
        setErrorMsg('windowsErrMsg');
      } else {
        windows = {
          decorationID: +room.windows[0].windowMaterial.id,
          decorationAddonID: room?.windows[0]?.windowAddonMaterial?.id ? +room.windows[0].windowAddonMaterial.id : -1,
          width: +room.windows[0].width,
          height: +room.windows[0].height,
          windows_count: +room._count.windows,
        };
      }
    }
    if (room.utility_factors.length >= 1) {
      room.utility_factors.forEach(element => {
        accessories.push(element.utility_factor_Fk.id)
      });
    }
    if (procced) {
      if (updateFlag) {
        // console.log(unit.rooms);
        // console.log(unit.rooms[index].roomFk.id);
        // console.log(unit.rooms[index].roomFk);
        updateRoom({
          variables: {
            userId: +user.user.userId,
            roomID: unit.rooms[index].roomFk.id,
            roomNameId: room?.room_names_Fk?.id,
            utilities: accessories,
            name: room?.name,
            aircon: !!room.isAirConditioning,
            width: roomShap == 'LShape' ? null : (room.width === '' ? null : +room.width),
            length: roomShap == 'LShape' ? null : (room.length === '' ? null : +room.length),
            height: room.height === '' ? null : +room.height,
            one: room.one === '' ? null : +room.one,
            two: room.two === '' ? null : +room.two,
            three: room.three === '' ? null : +room.three,
            four: room.four === '' ? null : +room.four,
            wallcolor: room.wall_color,
            costId: !room.finishing_cost_Fk?.id ? -1 : +room.finishing_cost_Fk?.id,
            styleId: !room.finishing_style_Fk?.id ? -1 : +room.finishing_style_Fk?.id,
            wallMatID: !room.wallMaterial?.id ? -1 : +room.wallMaterial?.id,
            wallAddonId: !room.wallMaterialAddon1?.id ? -1 : +room.wallMaterialAddon1?.id,
            wallAddon2: !room.wallMaterialAddon2?.id ? -1 : +room.wallMaterialAddon2?.id,
            cielingMatID: !room.cielingMaterial?.id ? -1 : + room.cielingMaterial?.id,
            cielingAddon: !room.cielingMaterialAddon1?.id ? -1 : +room.cielingMaterialAddon1?.id,
            cielingAddon2: !room.cielingMaterialAddon2?.id ? -1 : +room.cielingMaterialAddon2?.id,
            floorMatID: !room.floorMaterial?.id ? -1 : +room.floorMaterial?.id,
            doors: doors,
            windows: windows
          },
        }).then(results => {
          if (results.data.update_room.done) {
            let newValue = unit?.rooms?.length;
            dispatch(setUnitIfnoParam({ name: 'roomsCount', value: ++newValue }));
            navigate('/UnitForm/rooms');
          } else {
            console.error(results.error);
          }
        });
      } else {
        addRoom({
          variables: {
            userId: +user.user.userId,
            unitId: +unit.info.id,
            utilities: accessories,
            name: room?.name,
            aircon: !!room.isAirConditioning,
            width: roomShap == 'LShape' ? null : (room.width === '' ? null : +room.width),
            length: roomShap == 'LShape' ? null : (room.length === '' ? null : +room.length),
            height: room.height === '' ? null : +room.height,
            one: room.one === '' ? null : +room.one,
            two: room.two === '' ? null : +room.two,
            three: room.three === '' ? null : +room.three,
            four: room.four === '' ? null : +room.four,
            wallcolor: room.wall_color,
            roomId: room.id,
            costId: !room.finishing_cost_Fk?.id ? -1 : +room.finishing_cost_Fk?.id,
            styleId: !room.finishing_style_Fk?.id ? -1 : +room.finishing_style_Fk?.id,
            wallMatID: !room.wallMaterial?.id ? -1 : +room.wallMaterial?.id,
            wallAddonId: !room.wallMaterialAddon1?.id ? -1 : +room.wallMaterialAddon1?.id,
            wallAddon2: !room.wallMaterialAddon2?.id ? -1 : +room.wallMaterialAddon2?.id,
            cielingMatID: !room.cielingMaterial?.id ? -1 : + room.cielingMaterial?.id,
            cielingAddon: !room.cielingMaterialAddon1?.id ? -1 : +room.cielingMaterialAddon1?.id,
            cielingAddon2: !room.cielingMaterialAddon2?.id ? -1 : +room.cielingMaterialAddon2?.id,
            floorMatID: !room.floorMaterial?.id ? -1 : +room.floorMaterial?.id,
            doors: doors,
            windows: windows
          },
        }).then(results => {
          if (results.data.add_room) {
            let newValue = unit?.rooms?.length;

            dispatch(setUnitIfnoParam({ name: 'roomsCount', value: ++newValue }))
            navigate('/UnitForm/rooms');
          } else {
            console.error(results.error);
          }
        });
      }
    }

  }
  useEffect(() => {
    if (!room?.name) {
      // console.log(defualtRoomName(room));
      dispatch(setUnitRoomData({ index: index, param: { name: 'name', value: defualtRoomName(room) } }));
      if (!updateFlag) {
        dispatch(setUnitRoomData({ index: index, param: { name: 'id', value: room?.room_names_Fk?.id } }));
      }
    }
  }, []);
  // useEffect(() => {
  //   getFinishingCostAndFinishingStyles().then(result => {
  //     dispatch(addList({ name: 'finishingCostList', value: finishingCostAndFinishingStyles.data.getFinishingcostandstyle.finishing_costs }));
  //     dispatch(addList({ name: 'fisnishingStyle', value: finishingCostAndFinishingStyles.data.getFinishingcostandstyle.finishing_styles }));
  //   });
  // });
  useEffect(() => {
    if (room?.finishing_cost_Fk?.id && room?.finishing_style_Fk?.id) {
      getDecorationsList({
        variables: {
          finish_cost_id: room.finishing_cost_Fk.id,
          finish_style_id: room.finishing_style_Fk.id,
          RoomTypeID: room.room_names_Fk.id
        }
      }).then(result => {
        setActiveFields(true);
        let decorations = result?.data?.getdecorations;
        for (const listName in decorations) {
          dispatch(addList({ name: listName, value: decorations[listName] }));
        }
      });
    } else {
      setActiveFields(false);
    }
  }, [room.finishing_cost_Fk, room.finishing_style_Fk]);

  // useEffect(() => {
  //   room = unit.rooms[index].roomFk;
  //   setRoomShap(room.length ? 'SqureShape' : 'LShape');
  //   // console.log("room shap changed");
  //   // if (roomShap == 'SqureShape') {
  //   //   if(!room.length)
  //   //     dispatch(emptyRoomDimentionsOnShapChange({ index: index, roomShap: roomShap }));
  //   // }
  //   // if (roomShap == 'LShape') {
  //   //   if(!room.one)
  //   //     dispatch(emptyRoomDimentionsOnShapChange({ index: index, roomShap: roomShap }));
  //   // }
  // }, [unit.roomShap]);

  const setDecorationDoorsAndWindows = () => {
    if (unit.DoorDecorations && unit.WindowDecorations) {
      if (room.doors.length == 0)
        dispatch(setUnitRoomData({
          index: index, param: {
            name: 'doors', value: [{
              width: 0.9,
              height: 2.2,
              doorMaterial: unit.DoorDecorations[0]
            }]
          }
        }));
      if (room.windows.length == 0)
        dispatch(setUnitRoomData({
          index: index, param: {
            name: 'windows', value: [{
              height: 1.2,
              width: 1.2,
              windowMaterial: unit.WindowDecorations[0],
              windowAddonMaterial: null
            }]
          }
        }));
      if (room._count.doors == 0)
        dispatch(setUnitRoomDoorsOrWindowsCount({ index: index, param: { name: 'doors', value: 1 } }));
      if (room._count.windows == 0)
        dispatch(setUnitRoomDoorsOrWindowsCount({ index: index, param: { name: 'windows', value: 1 } }));
      if (!room.height)
        dispatch(setUnitRoomData({
          index: index, param: {
            name: 'height', value: 2.7
          }
        }));
    }
  }

  useEffect(() => {
    var utility_factors_array = [];
    unit?.roomNameList.forEach(element => {
      if (element.id == room?.room_names_Fk?.id) {
        if (element.utility_factors.length != 0) {
          element.utility_factors.forEach(element => {
            let tempObject = {
              utility_factor_Fk: {
                id: element.id,
                english_name: element.english_name,
                arabic_name: element.arabic_name
              }
            }
            utility_factors_array.push(tempObject)
          });
        }
      }
    });
    setUtilityFactors(utility_factors_array);
  }, [unit.roomNameList]);
  useEffect(() => {
    setDecorationDoorsAndWindows();
  }, [unit.DoorDecorations, unit.WindowDecorations])
  useEffect(() => {
    setOpen(false);
  }, [room.wall_color]);
  useEffect(() => {
    if (!room.height)
      dispatch(setUnitRoomData({
        index: index, param: {
          name: 'height', value: 2.7
        }
      }));

  }, [])
  if (!user.user.userId) {
    return (
      <div className={styles.UnitRoomForm}>
        <MustLoginModal />
      </div>)
  }
  // useEffect(() => { }, [room.wallMaterialAddon2, room.wallMaterialAddon1])
  // onChange={(e) => { onChange_setUnitRoomData('', e.target.value) }}
  if (loading) return (
    <div className={styles.UnitRoomForm}>
      <LoadingSpinner />
    </div>
  );
  if (error) return `Submission error! of finishingCostAndFinishingStyles ${error.message}`;
  if (likedRooms?.loading) return (
    <div className={styles.UnitRoomForm}>
      <LoadingSpinner />
    </div>
  );
  if (likedRooms?.error) return `Submission error! of likedRooms ${likedRooms.error.message}`;
  return (
    <div className={styles.UnitRoomForm}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-3'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')} />
              <Link to='/'>{t('backToHomePage')}</Link>
            </div>
            <div className={styles.mid + ' col-6'}>
              <h3>{t('roomName')}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className={styles.section}>
            <p className={styles.title}><Icons.RiEditLine /> <span>{t('roomName')}</span> </p>
            <CustomInput
              placeholder={t('roomName')}
              value={room?.name}
              onChange={(e) => onChangeRoomName(e)}
            />
          </div>
          <div className={styles.section + ' ' + styles.receptionShap}>
            <p className={styles.title}><Icons.TbWall /> <span>{t('selectReceptionShap')}</span> </p>
            <div className={styles.inner}>
              <div className="row">
                <div className="col-12 col-lg-3 text-center h-fit-content m-auto">
                  <div className="row">
                    <div className="col-6 col-lg-12">
                      {room.room_names_Fk.id == '8' &&
                        <div className={`${styles.shapContainer} ${roomShap === 'LShape' && styles.active}`} onClick={() => { onClick_setRoomShap('LShape') }}>
                          <div className={styles.imgContainer}>
                            <img src={Images.LShape} alt="L shape" />
                          </div>
                          <p>{t('lShap')}</p>
                        </div>
                      }
                    </div>
                    <div className="col-6 col-lg-12">
                      <div className={`${styles.shapContainer} ${roomShap === 'SqureShape' && styles.active}`} onClick={() => { onClick_setRoomShap('SqureShape') }}>
                        <div className={styles.imgContainer}>
                          <img src={Images.squareShape} alt="square shape" />
                        </div>
                        <p>{t('squareShape')}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className={styles.bigShapContainer}>
                    {/* <p>{roomShap === 'LShape' ? '1' : '2'}</p> */}
                    {roomShap === 'LShape' && <img src={Images.BigLshape} alt="big L shape" />}
                    {roomShap === 'SqureShape' && (lang.name === 'AR' ? <img src={Images.BigSquareShape} alt="big Rec shape" /> : <img src={Images.squareShape_en} alt="square shape" />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.section + ' ' + styles.roomSpace}>
            <p className={styles.title}><Icons.IoCubeOutline /> <span>{t('roomSize')}</span> </p>
            <div className={`row ${roomShap === 'LShape' ? 'justify-content-between' : ''}`}>
              {roomShap === 'LShape' ?
                <>
                  <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                    <p className={styles.label + " required"}> <span>1</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text required'
                      type="text"
                      placeholder='3,5 metre'
                      acceptOnly='number'
                      value={room?.one}
                      onChange={(value) => onChange_setUnitRoomData('one', value)} />
                  </div>
                  <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                    <p className={styles.label + " required"}> <span>2</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text required'
                      type="text"
                      placeholder='3,5 metre'
                      acceptOnly='number'
                      value={room?.two}
                      onChange={(value) => { onChange_setUnitRoomData('two', value) }} />
                  </div>
                  <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                    <p className={styles.label + " required"}> <span>3</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      placeholder='3,5 metre'
                      acceptOnly='number'
                      value={room?.three}
                      onChange={(value) => { onChange_setUnitRoomData('three', value) }} />
                  </div>
                  <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                    <p className={styles.label + " required"}> <span>4</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      placeholder='3,5 metre'
                      acceptOnly='number'
                      value={room?.four}
                      onChange={(value) => { onChange_setUnitRoomData('four', value) }} />
                  </div>
                </> :
                <>
                  <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                    <p className={styles.label + " required"}> <span>{t('width')}</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      placeholder='3,5 metre'
                      acceptOnly='number'
                      value={room?.width}
                      onChange={(value) => { onChange_setUnitRoomData('width', value) }} />
                  </div>
                  <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                    <p className={styles.label + " required"}> <span>{t('lenght')}</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      placeholder='3,5 metre'
                      acceptOnly='number'
                      value={room?.length}
                      onChange={(value) => { onChange_setUnitRoomData('length', value) }} />
                  </div>
                </>}
              <div className={styles.spaceLength + " col-4 col-md-3 col-lg-auto"}>
                <p className={styles.label + " required"}> <span>{t('height')}</span> <Icons.BiMoveHorizontal /> </p>
                <CustomInput
                  className='input-group-text'
                  type="text"
                  placeholder='3,5 metre'
                  acceptOnly='number'
                  value={room?.height}
                  onChange={(value) => { onChange_setUnitRoomData('height', value) }} />
              </div>
            </div>
          </div>
          <div className={styles.section + ' ' + styles.designType}>
            <ul className={selectedLikedRoomIDs.length == 0 ? styles.nav_tabs + " roomFomr_customTabs nav nav-tabs row " : styles.nav_tabs + " roomFomr_customTabs nav nav-tabs row " + styles.fav_selected} id="myTab" role="tablist">
              <li className="nav-item col-12 col-md-6" role="presentation">
                <button className="nav-link active" id="setting-tab" data-bs-toggle="tab" data-bs-target="#setting" type="button" role="tab" aria-controls="setting" aria-selected="true"> <Icons.VscSettings /> <span>{t('detailsSetting')}</span> </button>
              </li>
              <li className="nav-item col-12 col-md-6" role="presentation">
                <button className="nav-link" id="favDesign-tab" data-bs-toggle="tab" data-bs-target="#favDesign" type="button" role="tab" aria-controls="favDesign" aria-selected="false"> <Icons.AiOutlineHeart /> <span>{t('addFromFav')}</span> </button>
              </li>
            </ul>
            <div className={styles.nav_tabs_content + " tab-content"} id="myTabContent">
              <div className={styles.setting + " tab-pane fade show active"} id="setting" role="tabpanel" aria-labelledby="setting-tab">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p> <img src={Icons.colorPalet} alt="" /> <span>{t('designType')}</span> </p>
                    <select
                      className='form-select'
                      value={!!room?.finishing_style_Fk?.id ? room?.finishing_style_Fk?.id : ''}
                      name="designType"
                      onChange={(e) => onChange_setUnitRoomData('finishing_style_Fk', getValueOfListById(data?.getFinishingcostandstyle?.finishing_styles, e.target.value))}>
                      <option disabled value="">{t('pleaseSelectValue')}</option>
                      {data?.getFinishingcostandstyle?.finishing_styles.map((item, index) => {
                        return (
                          <option
                            key={`designType${item.id}`}
                            value={item.id}>
                            {lang.name === 'AR' ? item.arabic_name : item.english_name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-lg-6">
                    <p> <Icons.CgDollar /> <span>{t('finishingCosy')}</span> </p>
                    <select
                      className='form-select'
                      name="finishingCost"
                      value={!!room?.finishing_cost_Fk?.id ? room?.finishing_cost_Fk?.id : ''}
                      onChange={(e) => onChange_setUnitRoomData('finishing_cost_Fk', getValueOfListById(data?.getFinishingcostandstyle?.finishing_costs, e.target.value))}>
                      <option disabled value="">{t('pleaseSelectValue')}</option>
                      {data?.getFinishingcostandstyle?.finishing_costs.map((item, index) => {
                        return (
                          <option
                            key={`finishingCost${item.id}`}
                            value={item.id}>
                            {lang.name === 'AR' ? item.arabic_name : item.english_name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <p className={styles.note}>{t('detailsUnitMsg')}</p>
              </div>
              <div className={styles.favDesign + " tab-pane fade"} id="favDesign" role="tabpanel" aria-labelledby="favDesign-tab">
                <div className={styles.roomsContainer}>
                  {likedRooms?.data?.getLikedrooms?.filter(item => item?.roomFk?.room_names_Fk?.id == room?.room_names_Fk?.id).map((likedRoom, index) => {
                    return (
                      <div
                        className={styles.romItem}
                        key={`roomLiked${likedRoom.roomID}`}
                        onClick={() => onClick_likedRoom(likedRoom.roomID)}>
                        <RoomCard
                          checked={selectedLikedRoomIDs.includes(likedRoom.roomID)}
                          enableRedirect={false}
                          imagesList={getRoomImageURL(likedRoom?.roomFk?.image_url)}
                          roomId={likedRoom.roomID}
                          roomName={lang.name === 'AR' ? likedRoom.roomFk?.arabic_name : likedRoom.roomFk?.english_name}
                          roomDetails={<>
                            <span>
                              {lang.name === 'AR' ? likedRoom.roomFk?.room_names_Fk.arabic_name : likedRoom.roomFk?.room_names_Fk.english_name}
                            </span>
                            <span> </span>
                            <span>
                              {lang.name === 'AR' ? likedRoom.roomFk?.finishing_style_Fk.arabic_name : likedRoom.roomFk?.finishing_style_Fk.english_name}
                            </span>
                            <span> / </span>
                            <span>
                              {lang.name === 'AR' ? likedRoom.roomFk?.finishing_cost_Fk.arabic_name : likedRoom.roomFk?.finishing_cost_Fk.english_name}
                            </span>
                          </>}
                          isLiked={true}
                          roomRate={4.5} />
                      </div>
                    )
                  })}
                  {likedRooms?.data?.getLikedrooms?.filter(item => item?.roomFk?.room_names_Fk?.id == room?.room_names_Fk?.id).length == 0 &&
                    <div className={styles.noLikedRoomsOfType}>
                      <p>{t('noFavRoomsOfThisType')}</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.dependableFields} ${activeFields ? styles.activeFields : ''}`}>
            {selectedLikedRoomIDs.length == 0 &&
              <>
                <div className={styles.section + ' ' + styles.walls}>
                  <p className={styles.title}> <Icons.TbWall /> <span>{t('walls')}</span> </p>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <p className={styles.label + " required"}>{t('dominantColo')}</p>
                      <div className="UnitRoomForm_colorPicker">
                        <Button
                          onClick={() => setOpen(!open)}
                          aria-controls="UnitRoomForm_colorPicker-collapse-text"
                          aria-expanded={open}
                          variant=''
                        >
                          <div className="color" style={{ backgroundColor: `#${selectColors(room?.room_names_Fk?.id).substring(1, 7)}` }}></div>
                          {/* <span>{t(`color${selectColors(room?.room_names_Fk?.id).substring(1, 7)}`).includes('color') ? t('selectColor') : t(`color${selectColors(room?.room_names_Fk?.id).substring(1, 7)}`)}</span> */}
                        </Button>
                        <Collapse in={open}>
                          <div id="UnitRoomForm_colorPicker-collapse-text" className="colorPalet">
                            {unit?.colorPallet.map((color, index) => {
                              return (
                                <div key={`color${index}`} className="color" onClick={() => onChange_setUnitRoomData('wall_color', color)} style={{ backgroundColor: color }}></div>
                              )
                            })}
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className={styles.label + " required"}>{t('wallShap')}</p>
                      <select
                        className={styles.m_b_30 + ' form-select'}
                        name="wallShap"
                        value={room?.wallMaterial?.id}
                        onChange={(e) => { onChange_wallMaterial(e.target.value) }}>
                        <option value="">{t('pleaseSelectValue')}</option>
                        {unit?.WallDecorations?.map((item, index) => {
                          return (
                            <option key={`wallShap${item.id}`} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className={styles.label + " required"}>{t('adds')}</p>
                      <select
                        className={styles.m_b_30 + ' form-select'}
                        name="adds"
                        value={room?.wallMaterialAddon1?.id}
                        onChange={(event) => { onChange_wallAddOns('wallMaterialAddon1', event) }}>
                        <option value="">{t('withoutAddons')}</option>
                        {unit?.WallAddonDecorations?.map((item) => {
                          return (
                            <option key={`wallAdds${item.id}`} disabled={room?.wallMaterialAddon2?.id == item.id} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className={styles.label}>{t('adds')}</p>
                      <select
                        className={styles.m_b_30 + ' form-select'}
                        name="adds"
                        value={room?.wallMaterialAddon2?.id}
                        onChange={(event) => { onChange_wallAddOns('wallMaterialAddon2', event) }}>
                        <option value="">{t('withoutAddons')}</option>
                        {unit?.WallAddonDecorations?.map((item, index) => {
                          return (
                            <option key={`wallAdds2${item.id}`} disabled={room?.wallMaterialAddon1?.id == item.id} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className={styles.section + ' ' + styles.cieling}>
                  <p className={styles.title}> <img src={Icons.roof} alt="" /> <span>{t('ciel')}</span> </p>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <p className={styles.label + " required"}>{t('ciel')}</p>
                      <select
                        className={styles.m_b_30 + ' form-select'}
                        name="ciel"
                        value={room?.cielingMaterial?.id}
                        onChange={(e) => { onChange_cielingMaterial(e.target.value) }}>
                        <option value="">{t('pleaseSelectValue')}</option>
                        {unit?.CeilingDecorations?.map((item, index) => {
                          return (
                            <option key={`ciel${item.id}`} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className={styles.label + " required"}>{t('adds')}</p>
                      <select
                        className={styles.m_b_30 + ' form-select'}
                        name="ceiladds1"
                        value={room?.cielingMaterialAddon1?.id}
                        onChange={(event) => { onChange_cielingAddOns('cielingMaterialAddon1', event) }}>
                        <option value="">{t('withoutAddons')}</option>
                        {unit?.CeilingAddonDecorations?.map((item) => {
                          return (
                            <option key={`cielAdds1${item.id}`} disabled={room?.cielingMaterialAddon2?.id == item.id} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className={styles.label}>{t('adds')}</p>
                      <select
                        className='form-select'
                        name="ceiladds2"
                        value={room.cielingMaterialAddon2?.id}
                        onChange={(event) => { onChange_cielingAddOns('cielingMaterialAddon2', event) }}>
                        <option value="">{t('withoutAddons')}</option>
                        {unit?.CeilingAddonDecorations?.map((item) => {
                          return (
                            <option key={`cielAdds2${item.id}`} disabled={room?.cielingMaterialAddon1?.id == item.id} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className={styles.section + ' ' + styles.floor}>
                  <p className={styles.title}> <img src={Icons.floor} alt="" /> <span>{t('floor')}</span> </p>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <p className={styles.label + " required"}>{t('floorMaterial')}</p>
                      <select
                        className={styles.m_b_30 + ' form-select'}
                        name="floorMaterial"
                        value={room?.floorMaterial?.id}
                        onChange={(e) => { onChange_floorMaterial(e.target.value) }}>
                        <option value="">{t('pleaseSelectValue')}</option>
                        {unit?.FloorDecorations?.map((item, index) => {
                          return (
                            <option key={`floorMaterial${item.id}`} value={item.id}>
                              {lang.name === 'AR' ? item.arabic_name : item.english_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </>}
            <div className={styles.section + ' ' + styles.doors}>
              <p className={styles.title}> <Icons.BsDoorClosed /> <span>{t('doors')}</span> </p>
              <div className="row">
                <div className="col-6 col-lg-auto">
                  <p className={styles.label}>{t('doors')}</p>
                  <div className={styles.counter}>
                    <button onClick={() => decDoors()}><Icons.AiOutlineMinusCircle /></button>
                    <input
                      className='text-center'
                      type="text"
                      value={room?._count?.doors}
                      readOnly />
                    <button onClick={() => incDoors()}><Icons.GrAddCircle /></button>
                  </div>
                </div>
                <div className={styles.spaceLength + " col-6 col-lg-auto"}>
                  <p className={styles.label}><span>{t('height')}</span> <Icons.MdHeight /> </p>
                  <CustomInput
                    className='input-group-text'
                    type="text"
                    value={room?.doors[0]?.height}
                    acceptOnly='number'
                    onChange={(value) => onChange_setUnitRoomDoorsData('height', value)}
                    placeholder='3,5 metre' />
                </div>
                <div className={styles.spaceLength + " col-6 col-lg-auto"}>
                  <p className={styles.label}><span>{t('width')}</span> <Icons.BiMoveHorizontal /> </p>
                  <CustomInput
                    className='input-group-text'
                    type="text"
                    value={room?.doors[0]?.width}
                    acceptOnly='number'
                    onChange={(value) => onChange_setUnitRoomDoorsData('width', value)}
                    placeholder='3,5 metre' />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <p className={styles.label}>{t('doorMatrial')}</p>
                  <select
                    className={styles.m_b_30 + ' form-select'}
                    name="doorMatrial"
                    value={room?.doors[0]?.doorMaterial?.id}
                    onChange={(e) => { onChange_doorMaterial(e.target.value) }}>
                    <option value="">{t('pleaseSelectValue')}</option>
                    {unit?.DoorDecorations?.map((item, index) => {
                      return (
                        <option key={`floorMaterial${item.id}`} value={item.id}>
                          {lang.name === 'AR' ? item.arabic_name : item.english_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.section + ' ' + styles.windows}>
              <div className={styles.windowsBody}>
                <p className={styles.title}><img src={Icons.widnow} alt="" /> <span>{t('windows')}</span> </p>
                <div className="row">
                  <div className="col-6 col-lg-auto">
                    <p className={styles.label}>{t('windowsCount')}</p>
                    <div className={styles.counter}>
                      <button onClick={() => decWindows()}><Icons.AiOutlineMinusCircle /></button>
                      <input
                        className='text-center'
                        type="text"
                        value={room?._count?.windows}
                        readOnly />
                      <button onClick={() => incWindows()}><Icons.GrAddCircle /></button>
                    </div>
                  </div>
                  <div className={styles.spaceLength + " col-6 col-lg-auto"}>
                    <p className={styles.label}> <span>{t('height')}</span> <Icons.MdHeight /> </p>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      value={room?.windows[0]?.height}
                      acceptOnly='number'
                      onChange={(value) => onChange_setUnitRoomWindowsData('height', value)}
                      placeholder='3,5 metre' />
                  </div>
                  <div className={styles.spaceLength + " col-6 col-lg-auto"}>
                    <p className={styles.label}> <span>{t('width')}</span> <Icons.BiMoveHorizontal /> </p>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      value={room?.windows[0]?.width}
                      acceptOnly='number'
                      onChange={(value) => onChange_setUnitRoomWindowsData('width', value)}
                      placeholder='3,5 metre' />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p className={styles.label}>{t('windowMaterial')}</p>
                    <select
                      className={styles.m_b_30 + ' form-select'}
                      name="windowMaterial"
                      value={room?.windows[0]?.windowMaterial?.id}
                      onChange={(e) => { onChange_windowMaterial(e.target.value) }}>
                      <option value="">{t('pleaseSelectValue')}</option>
                      {unit?.WindowDecorations?.map((item, index) => {
                        return (
                          <option key={`windowMaterial${item.id}`} value={item.id}>
                            {lang.name === 'AR' ? item.arabic_name : item.english_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-lg-6">
                    <p className={styles.label}>{t('adds')}</p>
                    <select
                      className={styles.m_b_30 + ' form-select'}
                      name="windowAddonMaterial"
                      value={room?.windows[0]?.windowAddonMaterial?.id}
                      onChange={(e) => { onChange_windowAddonMaterial(e.target.value) }}>
                      <option value="">{t('pleaseSelectValue')}</option>
                      {unit?.WindowAddonDecorations?.map((item, index) => {
                        return (
                          <option key={`windowAddons${item.id}`} value={item.id}>
                            {lang.name === 'AR' ? item.arabic_name : item.english_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.section + ' ' + styles.roomAccessories}>
              <p className={styles.title}> <img src={Icons.AC} alt="" /> <span>{t('roomAccessories')}</span> </p>
              <p className={styles.note}>{t('roomAccessoriesNote')}</p>
              <div className="row">
                <div className={"col-12 col-lg-6"}>
                  <div className={`${styles.choice} ${room.isAirConditioning && styles.active}`} onClick={() => onClick_isAirConditioning(true)}>
                    <span>{t('containAC')}</span><Icons.BsCheck2 />
                  </div>
                </div>
                <div className={"col-12 col-lg-6"}>
                  <div className={`${styles.choice} ${!room.isAirConditioning && styles.active}`} onClick={() => onClick_isAirConditioning(false)}>
                    <span>{t('notContainAC')}</span><Icons.BsCheck2 />
                  </div>
                </div>
              </div>
            </div>
            {utilityFactors.length != 0 &&
              <div className='accessories-container'>
                <div className={styles.section + ' ' + styles.Accessories}>
                  <p className={styles.title}>
                    <Icons.TbBath />
                    <span>{t('accessories', { rommName: lang.name === 'AR' ? room.room_names_Fk.arabic_name : room.room_names_Fk.english_name })}</span>
                  </p>
                  <p className={styles.note}>{t('bathaccessoriesNote')}</p>
                  <div className={styles.innerBody + " text-center"}>
                    {utilityFactors?.map((item, index) => {
                      return (
                        <div className={styles.option} key={`accessories${index}`}>
                          <input type="checkbox" checked={isAcceChecked(item)} onChange={() => onChange_toggleAccessories(item)} />
                          <p>{lang.name === 'AR' ? item.utility_factor_Fk.arabic_name : item.utility_factor_Fk.english_name}</p>
                        </div>
                      )
                    })}

                  </div>
                </div>
              </div>
            }
          </div>
          {errorMsg && <div className={styles.errorMsg}>
            <p>{t(errorMsg)}</p>
          </div>}
        </div>
        <Modal id={"roomWarnModal"} show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body >
            <p>"{t('Warning')}"</p>
            <p>{t('WarningMsg')}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className='modalButton' onClick={handleClose}>
              {t('ok')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              {/* <p>{t('roomsFooterQoute')}</p> */}
            </div>
            <div className={styles.mid + ' col-12 col-lg-4'}>
              <OgeeButton className='btn text-center' onClick={() => saveRoom()} ><span>{t('saveInfo')}</span></OgeeButton>
            </div>
          </div>
        </div>
      </div>
      <Modal id={"warnModal"} show={showWarn} onHide={() => setshowWarn(false)} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div className="doneFlag">
            <Icons.RiFileWarningLine />
          </div>
          <p>{t('warn')}</p>
          <p>{t('maxNoOfDoors_windows')}</p>
        </Modal.Body>
        <Modal.Footer >
          <Button className='btn_ok' onClick={() => setshowWarn(false)}>
            {t('fine')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UnitRoomForm.propTypes = {};

UnitRoomForm.defaultProps = {};

export default UnitRoomForm;
