import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { URL } from "../ENV";



//Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = from([
  errorLink,
  new HttpLink({ uri: URL })
])



const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
export const apolloClient = new ApolloClient({
  uri: URL,
  cache: new InMemoryCache({
    addTypename: false
  }),
  addTypename: false,
  defaultOptions: defaultOptions
})



