import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from "react-bootstrap";
import * as images from '../../Assets/images';
import { Link } from 'react-router-dom'
import styles from './ResetPassword.module.scss'
import { IoIosEye, IoMdEyeOff } from 'react-icons/io'
import { t } from 'i18next';
import { CHANGE_PASSWORD } from "../../Graphql/Authentication/mutations"
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



const ResetPassword = () => {

  const user = useSelector(selectUser);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmaPsswordInput, setConfirmaPsswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  }
  const handleConfirmPasswordChange = (evnt) => {
    setConfirmaPsswordInput(evnt.target.value);
  }
  const [call_changePassword, { loading, error, data }] = useMutation(CHANGE_PASSWORD);
  const [changePassword, setChangePassword] = useState(true);
  const navigate = useNavigate()
  const changeIcon = changePassword === true ? false : true;
  const onSubmitClick = (e) => {
    e.preventDefault();
    if (passwordInput && confirmaPsswordInput) {
      if (passwordInput == confirmaPsswordInput) {
        // var hasUpperCase = /[A-Z]/.test(passwordInput);
        // var hasLowerCase = /[a-z]/.test(passwordInput);
        // var hasNumbers = /\d/.test(passwordInput);
        // var hasNonalphas = /\W/.test(passwordInput);
        // console.log(passwordInput.length >= 6);
        // console.log(hasUpperCase + " " + hasLowerCase + " " + hasNumbers + "  " + hasNonalphas);
        //&& hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas
        if (passwordInput.length >= 6  ) {
          call_changePassword({
            variables: {
              password: passwordInput,
              phone: user.user.phone
            }
          })
        } else {
          toast.warn(t('passwordNotComplex'), { autoClose: 1000 });
        }
      } else {
        toast.warn(t('passwordMustMatch'), { autoClose: 1000 });
      }
    } else {
      toast.warn(t('requiredFileds'), { autoClose: 1000 });
    }


  }
  useEffect(() => {
    if(data?.ChangePassword?.password){
      navigate('/Login');
    }
  }, [data]);
  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <>
      <div className={styles.ResetPassword}>
        <div className="container">
          <Card className={styles.Login}>
            <div className=" p-4 box">
              <div className="row d-flex justify-content-center  text-center align-items-center ">
                <img src={images.ResetPass} alt="Reset Password" className={styles.ResetPass} />
                <h6>{t('enterNewPassword')}</h6>
              </div>
              <Form onSubmit={(e) => onSubmitClick(e)}>
                <Form.Group className={styles.passwordContainer + " border-bottom mt-2"} >
                  <Form.Control
                    type={changePassword ? "password" : "text"}
                    onChange={handlePasswordChange}
                    value={passwordInput}
                    placeholder={t('password')}
                  />
                  <showpassword className={styles.showPass} onClick={() => { setChangePassword(changeIcon); }}>
                    {changeIcon ? <IoIosEye size={30} style={{ color: "#A9A9A9" }} /> : <IoMdEyeOff size={30} style={{ color: "#A9A9A9" }} />}
                  </showpassword>
                </Form.Group>
                <Form.Group className=" row border-bottom mt-2" >
                  <Form.Control
                    type={changePassword ? "password" : "text"}
                    placeholder={t('confirmPassword')}
                    onChange={handleConfirmPasswordChange}
                    value={confirmaPsswordInput}
                    className='col '
                  />
                </Form.Group>
                <div className="p-4 box  text-center mt-2">
                  <p >{t('acceptOn')}</p>
                  <Link to="/TermsOfUse">{t('terms')}</Link> &nbsp;
                  <Link to='/PrivacyPolicy'>{t('privacyPolicy')}</Link>
                </div>
                <div className="d-flex justify-content-end align-items-center mt-2 ">
                  <Button variant="primary" type="Submit">
                    {t('next')}
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default ResetPassword