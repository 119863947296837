
import React, { useState } from 'react';
import styles from './TopNav.module.scss';
import * as images from '../../Assets/images';
import { Link } from 'react-router-dom'
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Reducer/UserSLice';


const Topnav = () => {
  var user = useSelector(selectUser);
  if (user.isLoggedIn) {
    return null;
  }
  return (
    <div className={styles.topNav}>
      <div className="container">
        <div className="row justify-content-between">
          <div className={styles.right + " col-auto"}>
            {!user.isLoggedIn && <p>{t('topNavMsg')}</p>}
            <p style={{ color: '#FFC536' }} >  <Link to='/Signup'>
              {!user.isLoggedIn ? t('regNow') : null}</Link> </p>
          </div>
          <div className={styles.Mobile_right  + " col-auto"}>
            <p>{t('downloadTheApp')}</p>
          </div>
          <div className={styles.left  + " col-auto"}>
            <Link to='https://apps.apple.com/eg/app/ogeedecor/id6443860535' target="_blank"><img src={images.appStore} alt="appStore" /></Link>
            <Link to='https://play.google.com/store/apps/details?src=AppAgg.com&id=com.ogee&referrer=utm_source%3DAppAgg.com%26utm_medium%3Dreferral%26utm_campaign%3DAppAgg&pli=1' target="_blank"> <img src={images.googlePlay} alt="googlePlay" /></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topnav