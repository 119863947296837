import { gql } from "@apollo/client"

export const REGISTER = gql`       
mutation addUser(
  $name: String!,
  $email: String!,
  $phone:String!,
  $password: String! 
  ) {
    register(
      userInput: {
      name: $name
      email: $email
      phone: $phone
      password: $password
      }){
        name
        password
        email
        id
      }
}`
export const UPDATE_USER_PROFILE = gql`       
mutation(
  $userId: ID!,
  $name: String!,
  $email: String!,
  $dob:String!,
  $gender: String! 
  ){
  Update_Profile(userID:$userId,
  updated_userInput:{
    new_name: $name
    new_email: $email
    new_date_of_birth: $dob
    new_gender: $gender
  }
  ){
    id
    name
    phone
    email
    gender
    date_of_birth
  }
}
`
export const CHANGE_PASSWORD = gql`
mutation changepass(
    $password: String!,
    $phone:String!
    ){
      ChangePassword(phone:$phone, password:$password){
        name
        password
        # _id
        email
      }
     }
`
export const SEND_REQUESTED_PACKAGES = gql`
mutation sendRequestedPackages(
  $userId: ID!,
  $unitId: Int,
  $packageIDs:[ID]!
){
    sendRequest(
        userID:$userId
        unitID: $unitId
        packageIDs: $packageIDs
    ){
        done
    }
}
`
