import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filterBarKeyList: [],
    roomDetialedObject: {},
};

export const HomeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        addList: (state, action) => {
            state[action.payload.name] = action.payload.value
        },
        toggleFilterItem: (status, action) => {
            status[action.payload.name].map((item, index) => {
                if (item.id != action.payload.value) {
                    // This isn't the item we care about - keep it as-is
                    return item;
                }
                item.isActive = !item.isActive;
                if (item.isActive) {
                    var keyObject = {
                        keyList: action.payload.name,
                        index: index,
                        keyWord: item
                    }
                    status.filterBarKeyList.push(keyObject)
                } else {
                    status.filterBarKeyList = status.filterBarKeyList.filter(keyObject => {
                        return keyObject.keyWord.id != item.id;
                    })
                }
                // Otherwise, this is the one we want - return an updated value
                return {
                    ...item,
                    ...status[action.payload.name]
                }
            })
        },
        resetHomeStatus: (status, action) => {
            localStorage.clear();
            return initialState;
        },
        setroomDetialedObject: (state, action) => {
            state.roomDetialedObject = action.payload.value
        },
    }
});

export const {
    addList,
    toggleFilterItem,
    resetHomeStatus,
    setroomDetialedObject,
} = HomeSlice.actions;


export const selectHome = (state) => state.home;


export default HomeSlice.reducer;