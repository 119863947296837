import React, { useState, useEffect } from 'react'
import styles from './ApplyingCompanies.module.scss';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { APPLY_COMPANIES } from '../../Graphql/Services/mutauions';
import { t } from 'i18next';
import * as Icons from '../../Assets/icons';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { APPSTOREURL, GOOGLEPLAYURL } from '../../ENV';
import { toast } from 'react-toastify';
import { CustomInput } from '../../components';
import { Validators } from '../../services/validators';

const ApplyingCompanies = () => {
  const user = useSelector(selectUser)
  const navigate = useNavigate()
  const [companyData, setCompanyData] = useState({
    name: '',
    address: '',
    commercialRegister: '',
    taxNumber: '',
    hasPreviousWork: false,
    contactPerson: '',
    personPosition: '',
    personEmail: '',
    personPhone: '',
  })
  const onChange = (value, name) => {
    setCompanyData({ ...companyData, [name]: value })
  }
  const [addCompany, { loading, error, data }] = useMutation(APPLY_COMPANIES);
  const onSubmit_sendApplication = (e) => {
    e.preventDefault();
    var errorFlag = false;
    console.log(companyData);
    for (const key in companyData) {
      if (Object.hasOwnProperty.call(companyData, key)) {
        const element = companyData[key];
        if (key == 'hasPreviousWork') {
          continue
        }
        if (!element) {
          toast.warn(t('requiredFileds'), { autoClose: 1000 });
          console.log("key " + key);
          errorFlag = true;
          return;
        }
      }
    }
    addCompany({
      variables: {
        userID: +user.user.userId,
        name: companyData.name,
        commercial_record: companyData.commercialRegister,
        tax_id: companyData.taxNumber,
        is_work_for_review: companyData.hasPreviousWork,
        contact_person: companyData.contactPerson,
        cp_phone: companyData.personPhone,
        cp_title: companyData.personPosition,
        email: companyData.personEmail,
      }
    })
  }
  useEffect(() => {
    if (data?.FinishingCompany?.done) {
      toast.success(t('requestSendSuccessfuly'), { autoClose: 1000 });
      navigate('/');
    }
  }, [data]);
  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <div class={styles.ApplyingCompanies}>
      {loading && <LoadingSpinner />}
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-12 col-lg-8'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')}/>
              <Link to='/'>{t('home')}</Link>
              <span> \ </span>
              <Link to='/ApplyingFinishingCompanies'>{t('applyFinishingCompany')}</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className={styles.formContainer}>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('companyName')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='name'
                      value={companyData.name}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('companyAddress')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      autocomplete="off"
                      type="text"
                      name='address'
                      value={companyData.address}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('taxNumber')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      acceptOnly='number'
                      name='taxNumber'
                      value={companyData.taxNumber}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('commercialRegister')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='commercialRegister'
                      acceptOnly='number'
                      value={companyData.commercialRegister}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('contactPerson')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='contactPerson'
                      value={companyData.contactPerson}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('personPosition')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='personPosition'
                      value={companyData.personPosition}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('email')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='personEmail'
                      value={companyData.personEmail}
                      validators={[
                        {
                          check: Validators.required, message: t('RequiredField'),
                          check: Validators.email, message: t('validData')
                        }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('phone')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='personPhone'
                      acceptOnly='number'
                      value={companyData.personPhone}
                      validators={[
                        {
                          check: Validators.required, message: t('RequiredField'),
                          check: Validators.mobile, message: t('validData')
                        }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.question + " required"}>
                  <p>{t('previuosWorks')}</p>
                  <div className={styles.answer}>
                    <input type="radio" name='previuosWorks' />
                    <label htmlFor="previuosWorks">{t('yes')}</label>
                  </div>
                  <div className={styles.answer}>
                    <input type="radio" name='previuosWorks' />
                    <label htmlFor="previuosWorks">{t('no')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              {/* <p><span>{t('rateOhjee')}</span> <Link to={GOOGLEPLAYURL} target="_blank">{t('googlePlay')}</Link>{t('and')}<Link to={APPSTOREURL} target="_blank">{t('appStore')}</Link></p> */}
            </div>
            <div className={styles.mid + ' col-12 col-lg-4 text-center'}>
              <button className='btn' type='button' onClick={(e) => onSubmit_sendApplication(e)} ><span>{t('sendApplication')}</span><Icons.AiOutlineArrowLeft /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyingCompanies