import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UnitInspectionDate.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
  selectUnit
} from '../../../Reducer/UnitSlice';
import {
  selectLang
} from '../../../Reducer/LangSlice';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const UnitInspectionDate = () => {
  const navigate = useNavigate();
  let unit = useSelector(selectUnit);
  let lang = useSelector(selectLang);
  const [date, setDate] = useState(new Date());
  const [dateToBook, setdateToBook] = useState('');
  const [dateList, checkDate] = useState([
    {
      id: '1',
      from: '9ص',
      to: '11ص',
      checked: false,
      isFree: false,
      description: 'نعتزر لك فلا يمكنك حجز هذا الموعد لعدم وجود مواعيد متاحة'
    },
    {
      id: '2',
      from: '1م',
      to: '3م',
      checked: false,
      isFree: true,
      description: 'هذا الموعد متاح للحجز ، سيقوم المعاين بالحضور في الموعد المحدد، نرجوا الإلتزام بالميعاد'
    },
    {
      id: '3',
      from: '4م',
      to: '6م',
      checked: false,
      isFree: false,
      description: 'نعتزر لك فلا يمكنك حجز هذا الموعد لعدم وجود مواعيد متاحة'
    },
  ]);
  const onDateClicked = () => {
    //TODO functionality
  }
  const saveToAndRedirectToDraft = () => {
    //TODO save
    navigate(`/UnitDraft`);
  }
  const saveAndGoToThanksPage = () => {
    navigate(`/UnitForm/thanks`);
  }
  const constViewDateOnLocale = (date) => {
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString(lang.locale, options);
  }
  return (
    <div className={styles.UnitInspectionDate}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <Icons.AiOutlineArrowLeft />
              <Link to='/'>{t('cancelBackToHome')}</Link>
            </div>
            <div className={styles.mid + ' col-12 col-lg-4'}>
              <h3> <span>{t('selectDate')}</span> / <span>{`${unit.info.name}`}</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="calender" dir='ltr'>
                <Calendar
                  onChange={setDate}
                  onClickDay={() => onDateClicked()}
                  value={date}
                  calendarType={'Arabic'}
                  locale={lang.locale} />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="flex-col justify-content-end">
                <div className={styles.dates}>
                  <p>{constViewDateOnLocale(date)}</p>
                  <div className={styles.dateList}>
                    {dateList.map(item => {
                      return (
                        <div className={styles.dateData} key={item.id}>
                          <div className='d-flex'>
                            <div className={styles.dateTime}>
                              <p>{item.from}</p>
                              <p>{t('to')}</p>
                              <p>{item.to}</p>
                            </div>
                            <div className={styles.dateDescription}>
                              <p>{t('status')} <span>{item.isFree ? t('ava') : t('booked')}</span> </p>
                              <p>{item.description}</p>
                            </div>
                          </div>
                          <div className={styles.selectAction}>
                            <input type="radio" onClick={() => setdateToBook(item.id)} name="dateToBook" id="dateToBook" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.comfirmationModal + " modal fade"} id="comfirmationModal" tabindex="-1" aria-labelledby="comfirmationModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className={styles.ModalContent + " modal-content"}>
              <div className={styles.modalHeader + " modal-header"}>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className={styles.modalBody + " modal-body"}>
                <p>{t('confirmMsgBookUnit')}</p>
                <p>{t('contactNumber')}</p>
                <div className={styles.inputContainer}>
                  <Icons.RiEditLine />
                  <input className={'input-group-text'} type="text" />
                </div>
              </div>
              <div className={styles.modalFooter + " modal-footer"}>
                <button type="button" onClick={() => saveAndGoToThanksPage()} className={styles.modalButton + " btn"} data-bs-dismiss="modal"><Icons.FiSend /><span>{t('comfirmRequest')} </span> </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <p>{t('selectYourDate')} </p>
            </div>
            <div className={styles.mid + ' col-12 col-lg-5'}>
              <button className='btn' onClick={() => saveToAndRedirectToDraft()} ><Icons.AiOutlineStop /> <span>{t('cancelDate')}</span></button>
              <button className='btn' data-bs-toggle="modal" data-bs-target="#comfirmationModal"><span>{t('sendRequest')}</span><Icons.AiOutlineArrowLeft /> </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UnitInspectionDate.propTypes = {};

UnitInspectionDate.defaultProps = {};

export default UnitInspectionDate;
