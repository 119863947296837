const regex = {
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    number: '',
    arabic: '',
    arabicEnglish: '',
    english: '',
    nid: '',
    mobile: /^01[0125][0-9]{8}$/
}

export class Validators {
    static email(value, message) {
        if (value) {
            const result = regex.email.test(value);
            if (!result)
                return { error: true, message };
        }
        return false;
    }
    static required(value, message) {
        if (!value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }
    static mobile(value, message) {
        var regexMobile = new RegExp(regex.mobile, 'gm');
        if (value) {
            const result = regexMobile.test(value);
            if (!result)
                return { error: true, message };
        }
        return false;
    }
    static number(value, message) {
        const length = value ? value.toString().length : 0;
        if (length > 0) {
            const result = regex.number.test(value);
            if (!result) {
                return { error: true, message };
            }
        }
        return false;
    }
}
export const validatorsInput = (Validators, value) => {
    let error = false;
    if (Validators && Validators.length) {
        Validators.forEach(validator => {
            error = validator.check(value, validator.message);
        });
    }
    return error;
}