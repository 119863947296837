
import { toast } from 'react-toastify';
import { t } from 'i18next';

function Validation(values) {
   let error = {}
   const email_pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
   const password_pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/
   const phone_pattern = /^01[0125][0-9]{8}$/;
   if (values.name === "") {
      error.name = t('filedRequried',{field:t('userName')})
      // toast.error(error.name, { autoClose: 1000 });
   }
   if (values.phone === "") {
      error.phone = t('filedRequried',{field:t('phoneNumber')})
      // toast.error(error.phone, { autoClose: 1000 });
   }
   if (!phone_pattern.test(values.phone)) {
      error.phone = t('invalidPhone')
      // toast.error(error.phone, { autoClose: 1000 });
   }
   // if (values.email === "") {
   //    error.email = "Email should not be empty"
   //    // toast.error(error.email, { autoClose: 1000 });
   // }
   if (values.email) {
      if (!email_pattern.test(values.email)) {
         error.email = t('invalidEmail')
         // toast.error(error.email, { autoClose: 1000 });
      }
   }
   if (values.password === "") {
      error.password = t('filedRequried',{field:t('password')})
      // toast.error(error.password, { autoClose: 1000 });
   }
   if (values.password.length < 6) {
      error.password = t('password6Char')
      // toast.error(error.password, { autoClose: 1000 });
   } 
   // else {
   //    var hasUpperCase = /[A-Z]/.test(values.password);
   //    var hasLowerCase = /[a-z]/.test(values.password);
   //    // var hasNumbers = /\d/.test(values.password);
   //    // var hasNonalphas = /\W/.test(values.password);
   //    if (hasUpperCase + hasLowerCase < 2) //+ hasNumbers + hasNonalphas
   //       error.password = "password not complex enough";
   //    // toast.error(error.password, { autoClose: 1000 });
   // }
   // console.log(values.confirmPassword +"  "+ values.password);
   if (values.confirmPassword !== values.password) {
      error.confirmPassword = t('passwordInvalid')
      // toast.error(error.confirmPassword, { autoClose: 1000 });
   }
   if (values.storeOtp !== values.OTP) {
      error.values = t('otpInvalid')
      // toast.error(error.values, { autoClose: 1000 });
   }
   return error;


}

export default Validation;