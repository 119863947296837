
import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from "react-bootstrap";
import * as images from '../../Assets/images';
import { Link } from 'react-router-dom'
import styles from './Signup.module.scss'
import { IoIosEye, IoMdEyeOff } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import { REGISTER } from "../../../src/Graphql/Authentication/mutations"
import { useMutation, useQuery } from '@apollo/client';
import Validation from '../Validation/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { setPhone, setRegisterMode } from '../../Reducer/UserSLice';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

function Signup() {
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: ""
  })
  const [errors, setErrors] = useState({})
  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }
  const [addUser, UserData] = useMutation(REGISTER);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(errors);
    handleValidation(e);
    // if(Object.keys(errors).length==0){
    addUser({
      variables: {
        name: values.name,
        email: values.email,
        password: values.password,
        phone: values.phone
      }
    })
    // }

    return false;
  }
  useEffect(() => {
    if (UserData?.data?.register) {
      dispatch(setRegisterMode())
      navigate('/Verification');
      dispatch(setPhone(values.phone))
    }
  }, [UserData?.data]);
  useEffect(() => {
    if (UserData?.error) {
      toast.error(UserData?.error?.message, { autoClose: 1000 });
    }
  }, [UserData?.error]);

  useEffect(() => {
    setErrors({})
  }, []);

  function handleValidation(e) {
    e.preventDefault();
    setErrors(Validation(values))
  }
  return (
    <>
      <div className={styles.Signup}>
        {UserData?.loading && <LoadingSpinner />}
        <div className="container">
          <Card className={styles.Login}>
            <div className=" p-4 box">
              <div className="d-flex justify-content-between align-items-center">
                <h6><Link to="/Signup">{t('createAccount')}</Link></h6> &nbsp;
                <p ><Link to="/Login">{t('signIn')}</Link></p>
              </div>
              <Form autoComplete='false'>
                <Form.Group className="border-bottom mt-4" >
                  <Form.Control
                    type="text"
                    placeholder={t('userName')}
                    name="name"
                    value={values.name}
                    onChange={onChange}
                    autoComplete="off"
                    required
                  />
                  {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                </Form.Group>
                <Form.Group className="border-bottom mt-2" >
                  <Form.Control
                    type="text"
                    pattern="[0-9]*"
                    placeholder={t('phoneNumber')}
                    name="phone"
                    value={values.phone}
                    onChange={onChange}
                    autoComplete="off"
                    required
                  />
                  {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
                </Form.Group>

                <Form.Group className="border-bottom mt-2" >
                  <Form.Control
                    type="email"
                    placeholder={t('eMail')}
                    name="email"
                    value={values.email}
                    onChange={onChange}
                    autoComplete="off"
                    required
                  />
                  {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

                </Form.Group>

                <Form.Group className={styles.passwordContainer + " border-bottom mt-2"} >
                  <Form.Control
                    placeholder={t('password')}
                    type={changePassword ? "password" : "text"}
                    name="password"
                    value={values.password}
                    onChange={onChange}
                    autoComplete="off"
                    required
                  />
                  <showpassword className={styles.showPass} onClick={() => { setChangePassword(changeIcon); }}>
                    {changeIcon ? <IoIosEye size={30} style={{ color: "#A9A9A9" }} /> : <IoMdEyeOff size={30} style={{ color: "#A9A9A9" }} />}
                  </showpassword>

                  {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}

                </Form.Group>

                <Form.Group className={styles.passwordContainer + " border-bottom mt-2"} >
                  <Form.Control
                    type={changePassword ? "password" : "text"}
                    placeholder={t('confirmPassword')}
                    className='col '
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={onChange}
                    autoComplete="off"
                    required
                  />
                  <showpassword className={styles.showPass} onClick={() => { setChangePassword(changeIcon); }}>
                    {changeIcon ? <IoIosEye size={30} style={{ color: "#A9A9A9" }} /> : <IoMdEyeOff size={30} style={{ color: "#A9A9A9" }} />}
                  </showpassword>
                  {errors.confirmPassword && <p style={{ color: "red" }}>{errors.confirmPassword}</p>}

                </Form.Group>


                <div className="p-4 box  text-center">
                  <span>  {t('confirmOn')} </span>
                  <br />
                  <Link to="/TermsOfUse">{t('termsAndCond')}</Link> &nbsp;
                  <Link to="/PrivacyPolicy">{t('privayPolicy')}</Link>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-2 ">
                  {/*  <div >
                  <img src={images.appleForm} alt="apple" className={styles.socialIcons} />
                  <img src={images.facebookForm} alt="facebook" className={styles.socialIcons} />
                  <img src={images.googleForm} alt="google" className={styles.socialIcons} />
                </div>  */}

                  {/* <span>&rarr; أو التسجيل عن طريق  </span> */}
                  <Button type="submit" onClick={(event) => handleSubmit(event)} >
                    {t('next')}
                  </Button>
                </div>

              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}


export default Signup