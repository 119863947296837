import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './MyFavoriteRooms.module.scss';
import * as Icons from '../../Assets/icons';
import { useTranslation } from "react-i18next";
import {
  selectLang
} from '../../Reducer/LangSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  GET_LIKED_ROOMS,
} from '../../Graphql/UnitForm/Queries';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { useLazyQuery } from '@apollo/client';
import { LoadingSpinner } from '../../Pages';
import { RoomCard, MustLoginModal } from '../../components';
import { MainURL } from '../../ENV';

const MyFavoriteRooms = () => {
  const { t } = useTranslation();
  var user = useSelector(selectUser);
  var lang = useSelector(selectLang);
  let [getlikedRooms, likedRooms] = useLazyQuery(GET_LIKED_ROOMS,
    {
      variables: { userId: +user.user.userId },
    });
  const getRoomImageURL = (image) => {
    var fullURL = MainURL + image;
    return [fullURL];
  }
  const getRoomArea = (item) => {
    let area;
    if (item?.width) {
      area = item?.width * item?.length
    }
    if (item?.one) {
      let third = item?.two - item?.four
      let fourth = item?.one - item?.three
      area = (item?.one * item?.four) + (third * fourth)
    }
    if (!area){
      area = 0
    }
    area = area.toFixed(2);
    return lang.name === 'AR' ? area.toLocaleString('ar-EG') : area;
  }
  const refreshRooms = () => {
    likedRooms.refetch({ userId: +user.user.userId });
    getlikedRooms({ variables: { userId: +user.user.userId } })
  }
  useEffect(() => {
    refreshRooms();
  }, [])
  if (!user.user.userId) {
    return (
      <div className={styles.MyFavoriteRooms}>
        <MustLoginModal />
      </div>)
  }
  if (likedRooms?.loading) return (
    <div className={styles.MyFavoriteRooms}>
      <LoadingSpinner />
    </div>
  )
  if (likedRooms?.error) return `Submission error! of likedRooms ${likedRooms.error.message}`;
  return (
    <div className={styles.MyFavoriteRooms}>
      <div className="container">
        <div className={styles.header}>
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6">
              <div className={styles.navLink}>
                <Link to='/'>{lang.name === 'AR' ? <Icons.AiOutlineArrowRight /> : <Icons.AiOutlineArrowLeft />}</Link>
                <Link to='/MyFavoriteRooms'>{t('favoriteRooms')}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          {likedRooms?.data?.getLikedrooms.length != 0 ?
            <div className="row">
              {likedRooms?.data?.getLikedrooms?.map((room, index) => {
                return (
                  <div className={styles.gallaryItems + " col-12 col-md-6 col-lg-4"}>
                    <RoomCard
                      key={"room__" + room.roomID}
                      roomDetailsObject={room?.roomFk}
                      isLiked={true}
                      roomId={+room.roomID}
                      roomIndex={index}
                      afterLikeAction={() => refreshRooms()}
                      imagesList={getRoomImageURL(room?.roomFk?.image_url)}
                      roomName={lang.name === 'AR' ? room?.roomFk?.arabic_name : room?.roomFk?.english_name}
                      roomType={lang.name === 'AR' ? room?.roomFk?.room_names_Fk?.arabic_name : room?.roomFk?.room_names_Fk?.english_name}
                      roomDetails={<div className={styles.description}>
                        <span>
                          {lang.name === 'AR' ? room?.roomFk?.room_names_Fk?.arabic_name : room?.roomFk?.room_names_Fk?.english_name}
                        </span>
                        <span> </span>
                        <span>
                          {lang.name === 'AR' ? room?.roomFk?.finishing_style_Fk?.arabic_name : room?.roomFk?.finishing_style_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {lang.name === 'AR' ? room?.roomFk?.finishing_cost_Fk?.arabic_name : room?.roomFk?.finishing_cost_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {`${getRoomArea(room?.roomFk)}${t('area')}`}
                        </span>
                      </div>}
                      iscarousel={false}
                      viewDetails={false}
                      viewShowMoreLink={false} />
                  </div>
                )
              })}
            </div> :
            <div className={styles.noFavRooms}>
              <Icons.AiOutlineHeart />
              <p>{t('emptyPage')}</p>
              <p>{t('uCanAddFavDesign')} <span><Link to='/'>{t('HomePage')}</Link></span></p>
              <p>{t('uaeItAtUrUnit')}</p>
            </div>
          }
        </div>
        <div className={styles.footer}>
        </div>
      </div>
    </div >
  );
}

MyFavoriteRooms.propTypes = {};

MyFavoriteRooms.defaultProps = {};

export default MyFavoriteRooms;
