import gql from 'graphql-tag';

export const GET_GALLARY = gql`
query{
  getGalleryRooms{
    id
    image_url
    Gallery_photo_description
    Gallery_photo_description_english
    english_name
    arabic_name
    name
    liked_by{
        roomID
        userID
    }
    isAirConditioning
    width
    length
    height
    wall_color
    calculated_price
    room_names_Fk{
      id
      english_name
      arabic_name
    }
    finishing_cost_Fk{
      id
      english_name
      arabic_name
    }
    finishing_style_Fk{
      id
      english_name
      arabic_name
    }
    wallMaterial{
      id
      english_name
      arabic_name
      decoration_type
    }
    wallMaterialAddon1{
      id
      english_name
      arabic_name
      decoration_type
    }
    wallMaterialAddon2{
      id
      english_name
      arabic_name
      decoration_type
    }
    cielingMaterial{
      id
      english_name
      arabic_name
      decoration_type
    }
    cielingMaterialAddon1{
      id
      english_name
      arabic_name
      decoration_type
    }
    cielingMaterialAddon2{
      id
      english_name
      arabic_name
      decoration_type
    }
    floorMaterial{
      id
      english_name
      arabic_name
      decoration_type
    }
  }
}
`;

export const GET_SLIDER = gql`
query{
  GetSliderImages{
    id
    imgurl
  }
}
`;

export const GET_GALLARY_GROUPED_BY_FINISHING_STYLE = gql`
query{
  getGalleryRoomsGrouped{
      id
      english_name
      arabic_name
      rooms{
            id
            image_url
            english_name
            Gallery_photo_description
            arabic_name
            name
            liked_by{
                roomID
                userID
            }
            width
            isAirConditioning
            length
            height
            wall_color
            calculated_price
            room_names_Fk{
                id
                english_name
                arabic_name
            }
            finishing_cost_Fk{
                id
                english_name
                arabic_name
            }
            finishing_style_Fk{
            id
            english_name
            arabic_name
            }
            wallMaterial{
            id
            english_name
            arabic_name
            decoration_type
            }
            wallMaterialAddon1{
            id
            english_name
            arabic_name
            decoration_type
            }
            wallMaterialAddon2{
            id
            english_name
            arabic_name
            decoration_type
            }
            cielingMaterial{
            id
            english_name
            arabic_name
            decoration_type
            }
            cielingMaterialAddon1{
            id
            english_name
            arabic_name
            decoration_type
            }
            cielingMaterialAddon2{
            id
            english_name
            arabic_name
            decoration_type
            }
            floorMaterial{
            id
            english_name
            arabic_name
            decoration_type
            }
            doors{
                name
                height
                width
                doorMaterial{
                    id
                    arabic_name
                    english_name
                    decoration_type
                }
            }
            windows{
                name
                height
                width
                windowMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
                windowAddonMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
            }
        }
    
  }
}
`;

export const GET_GALLARY_GROUPED_BY_ROOM_NAME = gql`
query{
  getGalleryRoomsGroupedbyRoomNames{
      id
      english_name
      arabic_name
      rooms{
            id
            image_url
            Gallery_photo_description
            english_name
            arabic_name
            name
            liked_by{
                roomID
                userID
            }
            width
            isAirConditioning
            length
            height
            wall_color
            calculated_price
            room_names_Fk{
                id
                english_name
                arabic_name
            }
            finishing_cost_Fk{
                id
                english_name
                arabic_name
            }
            finishing_style_Fk{
                id
                english_name
                arabic_name
            }
            wallMaterial{
                id
                english_name
                arabic_name
                decoration_type
            }
            wallMaterialAddon1{
                id
                english_name
                arabic_name
                decoration_type
            }
            wallMaterialAddon2{
                id
                english_name
                arabic_name
                decoration_type
            }
            cielingMaterial{
                id
                english_name
                arabic_name
                decoration_type
            }
            cielingMaterialAddon1{
                id
                english_name
                arabic_name
                decoration_type
            }
            cielingMaterialAddon2{
                id
                english_name
                arabic_name
                decoration_type
            }
            floorMaterial{
                id
                english_name
                arabic_name
                decoration_type
            }
            doors{
                name
                height
                width
                doorMaterial{
                    id
                    arabic_name
                    english_name
                    decoration_type
                }
            }
            windows{
                name
                height
                width
                windowMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
                windowAddonMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
            }
        }
    
  }
}
`;

export const GET_GALLARY_GROUPED_BY_USER_ID = gql`
query($userID:ID!){
  getGalleryRoomsGroupedByUserID(userID:$userID){
      id
      english_name
      arabic_name
      rooms{
            id
            liked_by{
                roomID
                userID
            }
            image_url
            english_name
            Gallery_photo_description
            Gallery_photo_description_english
            arabic_name
            name
            width
            isAirConditioning
            length
            height
            wall_color
            calculated_price
            room_names_Fk{
                id
                english_name
                arabic_name
            }
            finishing_cost_Fk{
                id
                english_name
                arabic_name
            }
            finishing_style_Fk{
            id
            english_name
            arabic_name
            }
            wallMaterial{
            id
            english_name
            arabic_name
            decoration_type
            }
            wallMaterialAddon1{
            id
            english_name
            arabic_name
            decoration_type
            }
            wallMaterialAddon2{
            id
            english_name
            arabic_name
            decoration_type
            }
            cielingMaterial{
            id
            english_name
            arabic_name
            decoration_type
            }
            cielingMaterialAddon1{
            id
            english_name
            arabic_name
            decoration_type
            }
            cielingMaterialAddon2{
            id
            english_name
            arabic_name
            decoration_type
            }
            floorMaterial{
            id
            english_name
            arabic_name
            decoration_type
            }
            doors{
                name
                height
                width
                doorMaterial{
                    id
                    arabic_name
                    english_name
                    decoration_type
                }
            }
            windows{
                name
                height
                width
                windowMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
                windowAddonMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
            }
        }
    
  }
}
`;
export const GET_ROOM_NAMES = gql`
  query{
    getRoomNames{
      id
      english_name
      arabic_name
    }
  }
`;
export const GET_FINISHING_COSTS_AND_FINISHING_STYLES = gql`
  query{
    getFinishingcostandstyle{
      finishing_costs{
        id
        english_name
        arabic_name
      }
      finishing_styles{
        id
        english_name
        arabic_name
      }
    }
  }
`;

export const Notifications = gql`
  query($user:ID!){
    getNotifications( userID:$user){
      id
      title
      body
      createdAt
    }
  }
`;

