
import React from 'react'
import styles from './PrivacyPolicy.module.scss';
import { split, useQuery } from "@apollo/client";
import {GET_PRIVACY} from '../../../src/Graphql/Authentication/Queries';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function PrivacyPolicy() {
  
  const { loading, error, data } = useQuery(GET_PRIVACY);
  if (loading) return <LoadingSpinner />;
  if (error) return `Error! ${error}`;
  const result=data.Pages.arabic_privacy;
  const lines=result.split('\r\n');
//   console.log(result);

  return (
      <div>
          <content className={styles.privacyContent}>
              <div>
                  {lines.map((line,index)=> {
                    if (index===0){
                        return <h1 key={index}>{line}</h1>
                    }else{

                 return <p key={index}> {line}</p> 
                }
                  
            })}
                                       
             </div> 
      </content>
      </div>
  )
}

export default PrivacyPolicy