import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    user: {
        username: '',
        phone: '',
        email: '',
        userId: '',
        gender: '',
        date_of_birth: '',
    },
    otp: '',
    isLoggedIn: false,
    OTPMode: '' //Regitser | forgetPassword
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            if (action.payload.userId) {
                state.isLoggedIn = true;
                state.user = {
                    date_of_birth: action.payload.date_of_birth?.split('T')[0],
                    email: action.payload.email,
                    gender: action.payload.gender,
                    phone: action.payload.phone,
                    token: action.payload.token,
                    tokenExpiration: action.payload.tokenExpiration,
                    userId: action.payload.userId,
                    username: action.payload.username,
                }
                // document.cookie = `username=${state.user.username}; email=${state.user.email}`
            }
        },
        updateUser: (state, action) => {
            state.user = {
                date_of_birth: action.payload.date_of_birth.split('T')[0],
                gender: action.payload.gender,
                username: action.payload.name,
                email: action.payload.email,
                userId: state.user.userId,
                token: state.user.token,
                tokenExpiration: state.user.tokenExpiration,
                userId: state.user.userId,
                phone: state.user.phone,
            }
        },
        setUseOtp: (state, action) => {
            state.otp = action.payload
        },
        logInOut: (state) => {
            localStorage.clear();
            window.fcWidget.user.clear()
            return initialState;
        },
        setPhone: (state, action) => {
            state.user.phone = action.payload
        },
        setForgetMode: (state) => {
            state.OTPMode = 'forgetPassword'
        },
        setRegisterMode: (state) => {
            state.OTPMode = 'Regitser'
        },
        setGender: (state, action) => {
            state.user.gender = action.payload
        },
        setName: (state, action) => {
            state.user.username = action.payload
        },
        setdob: (state, action) => {
            state.user.date_of_birth = action.payload
        },
    }
})

export const {
    setUser,
    setUseOtp,
    logInOut,
    setPhone,
    setForgetMode,
    setRegisterMode,
    setName,
    setGender,
    setdob,
    updateUser,
} = userSlice.actions
export const selectUser = (state) => state.user;
export default userSlice.reducer