export const TRANSLATIONS_AR = {
    send: 'إرسال',
    subscribeMsg: 'قم بالتواصل معنا عبر البريد الالكتروني.',
    sendMsg:'إرسال رسالة',
    writeUrEmail: 'اكتب بريدك هنا',
    aboutOjee: 'عن أوجي',
    applyFinishingCompany: 'لتقديم شركات التشطيب',
    terms: 'شروط الإستخدام',
    urRate: 'تقييمك لخدماتنا',
    applycomplains: 'لتقديم الشكاوى',
    privacyPolicy: 'سياسة الخصوصية',
    FAQ: 'أسئلة شائعة',
    right: '@2023- جميع الحقوق محفوظة لدى أوجي ديكور',
    designedBy: 'Developed by ',
    recommendedForU: 'موصى به من اجلك',
    roomName: 'إسم الغرفة',
    roomDetails: 'مطبخ/اكونمي/مودرن/ ٤٠ م',
    viewMore: 'رؤية المزيد',
    viewMoreFrom: 'رؤية المزيد من ',
    roomGallary: 'معرض الصور',
    roomType: 'مطابخ',
    roomType2: 'غرف معيشة',
    roomType3: 'مكاتب',
    searchFor: 'اضغط هنا للبحث عبر التنقية',
    rooms: 'الغرف',
    all: 'الكل',
    bedrooms: 'غرف نوم',
    kidsRoom: 'غرف أطفال',
    livingRoom: 'غرف معيشة',
    receptionRoom: 'صالة الأستقبال',
    bathRooms: 'حمامات',
    wachingRoom: 'غرف غسيل',
    offices: 'مكاتب',
    road: 'طرقة أو ممر',
    headroad: 'تراس وبلكونة',
    gamingRoom: 'غرف الترفية والألعاب',
    rawMaterialAndProducts: 'الخامات و المنتجات',
    woodenGlade: 'تجالد خشبية',
    wallpaper: 'ورق حائط',
    stone: 'حجر',
    gypsumDecoration: 'ديكورات جبسية',
    HDF: 'خشب HDF',
    galde3d: 'تجاليد 3D',
    naturalWood: 'خشب طبيعي',
    marble: 'رخام',
    egyptionGranite: 'جرانيت مصري',
    ceramic: 'سيراميك',
    porcelain: 'بورسلين',
    designType: 'نوع التصميم',
    classic: 'كلاسيكي',
    newClassic: 'كلاسيكي حديث',
    modern: 'عصري',
    simple: 'بساطة',
    finishingCost: 'تكلفة التشطيب',
    economic: 'اقتصادي',
    medium: 'متوسط',
    dulex: 'فاخر',
    history: 'التاريخ',
    random: 'عشوائي',
    fromNewToOld: 'من الجديد الي القديم',//TODO:REMOVE
    fromOldToNew: 'من القديم الي الجديد',//TODO:REMOVE
    roomSize: 'حجم الغرفة',
    fromSmallToBig: 'من الصغير الي الكبير',//TODO:REMOVE
    formBigToSmall: 'من الكبير الي الصغير',//TODO:REMOVE
    coast: 'التكلفة',
    lowestToHightPrice: 'من الاقل سعراً الي الاعلي سعراً',//TODO:REMOVE
    highestToLowestPrice: 'من الاعلي سعراً الي الاقل سعراً',//TODO:REMOVE
    search: 'بحث',
    viewMoreOfRooms: 'اإستكشف اكثر من {{numberOfRooms}} انوع الغرف المختلفة',
    viewMoreOf: 'رؤية المزيد من {{roomName}}',
    neadMoreRoomsQuestion: 'هل ترغب في رؤية المزيد من الغرف؟',
    useSearch: 'إستخدم البحث عبر التنقية لتجد المئات من الغرف لالهامك.',
    readArtical: 'قرأة المقالة',
    articalsMayLiked: 'مقالات قد تعجبك',
    articalsMayLinked: 'مقالات ذات صلة',
    seeMore: 'رؤية اكثر',
    designType: 'نوع التصميم',
    DesignSeeMore_title: '	تصفح تصميمات مختلفة تقدر تستوحى منها تصميم وحدتك وتنفذه',
    DesignSeeMore_description: 'اوجي هو أول منصة بتوفرلك معرض كبير من التصميمات الداخلية المختلفة هتساعدك في انك تختار نوع التصميم اللى حابب تنفذه',
    Register: 'سجل الآن',
    header_title: '	تسعير التكلفة التقريبية لوحدتك  ',
    header_description: 'أوجي هو أول منصة بتوفرلك اختيار تكلفة التشطيب والتصميم الداخلى اللى حابب تنفذه بنفسك من اى مكان ',
    header_title_2: 'مع اوجي مش هتحتاج متخصص لتسعير تكلفة التشطيب',
    header_description_2: 'أوجي معاك فى كل خطوة فى تسعير تشطيب وحدتك بنفسك بكل سهولة بحرية اختيار التكلفة المناسبة لميزانيتك.',
    header_title_3: 'إختار من خدمات التشطيب المتاحة على المنصة',
    header_description_3: 'أوجى بيقدملك خدمات تشطيب مختلفة هتساعدك تشطب وحدتك بسهوله ومنها التصميم الداخلى والاشراف على التنفيذ وغيرها من الخدمات.',
    addUnit: 'إضافة وحدة',
    add: 'إضافة',
    cancelBackToHome: 'إلغاء و الرجوع للصفحة الرئيسية',
    home: 'الرجوع للصفحة الرئيسية',
    UnitForm: 'أضافة وحدة',
    rooms: 'الغرف',
    backToRooms: 'الرجوع للغرف',
    writeUnitName: 'اكتب اسم وحدتك',
    unitName: 'اسم الوحدة',
    unitType: 'نوع الوحدة حسب تسجيلها',
    residential: 'سكني',
    commercial: 'تجاري',
    managerial: 'إداري',
    next: 'التالي',
    soon: 'قريباً',
    whatTypeUnit: 'ما هو نوع وحدتك؟',
    selectlocation: 'اختر موقع الوحدة',
    selectRegion: 'اختر منطقة الوحدة',
    selectFloor: 'اختر الدور',
    addRoom: 'إضافة غرفة',
    edit: 'تعديل',
    update:'تحديث',
    saveToDraft: 'حفظ في المسودة',
    viewPrice: 'عرض السعر',
    sendToDesign: 'إرسال طلب تصميم',
    agreeToTermsAndConditions: 'عند ضغطك على إرسال فأنت توافق على',
    terms: 'شروط الإستخدام',
    and: ' و ',
    ploicy: 'سياسة الخصوصية',
    costCaution: '” تنبيه : قد تتغير التكلفة التقريبية للتشطيب كل 7 أيام“',
    approxCost: 'التكلفة التقريبية للتشطيب',
    negotiateCost: 'هل التكلفة التقريبية أكثر من ميزانيتك؟',
    pressHere: ' اضغط هنا',
    costCoationTitle: 'تنبيه :',
    costCoationline1: 'السعر المعروض هو سعر تقريبي لا يشمل ارباح لاي جهة. والسعر النهائي يقدم لك بعد التصميم',
    costCoationline2: 'تم تحديد هذا السعر بناءً على خامات جيدة الصنع لضمان الإستدامة والسعر المتكافيء مع الجودة.',
    costCoationline3: 'السعر التقريبي هو سعر استرشادي لمساعدة مستخدمى أوجي على تشطيب وحداتهم بتكلفة مناسبة لما هو مطلوب.',
    costCoationline4: 'السعر قد يحتمل الزيادة او النقصان عن الواقع بما لا يزيد عن 10% .',
    costCoationline5: 'التكلفة التقريبية للتشطيب تشمل أعمال التشطيبات فقط.  /n - التكلفة المعروضة لا تشمل التالى: الفرش للغرف ووحدات الإضاءة بأنواعها ووحدات المطبخ والحمام وغرف الملابس وفرش الحمام.',
    costCoationline6: 'احصل على 20% خصم وضمان عى التنفيذ عند طلبك للمجموعة الكاملة من خدمات أوجي الهندسية عند التعاقد.. وذلك عند الإلتزام بالميزانية المخصصة لك للحصول على كل ماتريد من جودة وإلتزام زمنى للتسليم.',
    selectyourPlan: 'اختر الباقات المناسبة',
    mostSelected: 'الاكثر إختياراً',
    selectDate: ' اختر ميعاد المعاينة',
    selectYourDate: 'اختر ميعاد المعاينة المناسب لك',
    cancelDate: 'إلغاء ميعاد المعاينة',
    sendRequest: 'إرسال طلب معاينة',
    to: 'الي',
    status: 'الحالة',
    bookedDate: 'نعتزر لك فلا يمكنك حجز هذا الموعد لعدم وجود مواعيد متاحة',
    avaDate: 'هذا الموعد متاح للحجز ، سيقوم المعاين بالحضور في الموعد المحدد، نرجوا الإلتزام بالميعاد',
    ava: 'متاح الحجز',
    booked: 'محجوز',
    confirmMsgBookUnit: 'هل أنت متأكد من إرسال الطلب؟',
    contactNumber: 'الرقم الذي سيتم التواصل من خلاله',
    comfirmRequest: 'تأكيد الإرسال',
    backToHomePage: 'الرجوع للصفحة الرئيسية',
    DoneSent: 'تم الإرسال بنجاح',
    thanks: 'شكراً لك',
    willContactU: 'لقد تم إرسال طلبك لفريق أوجى و سيتم التواصل خلال 48 ساعة على رقم  الهاتف {{mobileNumber}}',
    rateOhjee: 'قم بتقييم خدماتنا عبر',
    googlePlay: 'Google play',
    appStore: 'App store',
    saveInfo: 'حفظ المعلومات',
    roomName: 'إسم الغرفة',
    selectReceptionShap: 'اختر شكل الغرفة',
    lShap: 'علي شكل حرف L',
    squareShape: 'علي شكل مربع',
    roomSize: 'مساحة الغرفة',
    detailsSetting: 'إعدادات مفصلة',
    addFromFav: 'إضافة نوع التصميم من مفضلتك',
    designType: 'نوع التصميم',
    finishingCosy: 'تكلفة التشطيب ',
    detailsUnitMsg: 'لتتمكن من إدخال تفاصيل الوحدة يجب عليك تحديد نوع التصميم و تكلفة التشطيب. ',
    walls: 'الحوائط',
    dominantColo: 'اللون السائد',
    wallShap: 'شكل الحوائط',
    adds: 'إضافة',
    ciel: 'السقف',
    floor: 'الأرضية',
    floorMaterial: 'خامة الأرضية',
    doors: 'الأبواب',
    doorCount: 'عدد الأبواب',
    doorMatrial: 'خامة الأبواب',
    width: 'العرض',
    lenght: 'الطول',
    height: 'الارتفاع',
    windowMaterial: 'خامة النوافذ',
    windows: 'النوافذ',
    windowsCount: 'عدد النوافذ',
    addWindow: 'إضافة نافذة',
    roomAccessories: 'ملحقات الغرفة',
    roomAccessoriesNote: 'هذة الخاصية تتيح لك تجهيز وتركيب التكييف.',
    containAC: 'شامل وحدة التكييف',
    notContainAC: 'غير شامل وحدة التكييف ',
    accessories: 'ملحقات {{rommName}}',
    bathaccessories: 'ملحقات الحمام',
    bathaccessoriesNote: 'برجاء إختيار الملحقات لتأسيس السباكة والكهرباء فقط',
    bathBase: 'قاعدة',
    bathTub: 'حوض',
    bathShower: 'شاور',
    bathBathTub: 'بانيو',
    washingMachine: 'غسالة ملابس',
    bahJacuzzi: 'جاكوزي',
    electricHeater: 'سخان كهرباء',
    gazHeater: 'سخان غاز',
    kitchenaccessories: 'ملحقات المطبخ',
    dishWasher: 'غسالة اطباق',
    refrigertaor: 'ثلاجة',
    coojker: 'بوتجاز',
    deepFreezer: 'ديب فريزر',
    washingMachine: 'غسالة ملابس',
    microwave: 'ميكروويف',
    luandryRoomAccessories: 'ملحقات غرفة الغسيل',
    luandryRoomAccessoriesNote: 'برجاء إختيار الملحقات لتأسيس السباكة والكهرباء فقط',
    twoWashingMachine: 'غسالتين ملابس',
    height: 'الارتفاع',
    Warning: 'تنبيه',
    WarningMsg: 'عند إضافة تصمييم لغرفة من الإعجابات فلن تستطيع تعديل بعض الخانات, إذا اردت التعديل يمكنك الضغط على إعدادات مفصلة.',
    ok: 'حسناً',
    withoutAddons: 'بدون اضافه',
    color561A24: 'لون 1',
    colorDF970E: 'لون 2',
    colorDDAB4C: 'لون 3',
    colorF4CC8E: 'لون',
    color0062A9: 'لون',
    colorE5313E: 'لون',
    colorFFD90A: 'لون',
    colorFFFFFF: 'لون',
    color29272A: 'لون',
    colorA4B3B0: 'لون',
    color5B2620: 'لون',
    colorAA6941: 'لون',
    color102A1F: 'لون',
    colorB4D3D5: 'لون',
    color015939: 'لون',
    color546471: 'لون',
    pleaseSelectValue: 'برجاء الاختيار',
    deleteOK: 'تأكيد',
    deleteCancel: 'إلغاء',
    unFinished: 'لم تنتهي',
    finished: 'منتهية',
    inComplete: 'غير منتهية',
    roomName: 'اسم الغرفة',
    doorsErrMsg: 'يرجى استكمال المدخلات المتعلقة بالأبواب',
    windowsErrMsg: 'يرجى استكمال المدخلات المتعلقة بالنوافذ',
    area: 'م٢',
    selectColor: 'من فضلك اختر لون',
    studio: 'استوديو',
    roomsResult: '(نتائج البحث : {{roomResultLength}}غرفة)',
    Rooms: 'غرف',
    keyWords: 'كلمات مفتاحية',
    description: 'الوصف',
    searchResult: "نتائج البحث",
    next: "التالي",
    prev: "الرجوع",
    createAccount: 'إنشاء حساب',
    signIn: 'تسجيل الدخول',
    mustLoginSentence: 'قم بإنشاء حساب للاستمتاع بمزايا وخدمات أوجى المختلفة', //لمعرفة تكلفة تشطيب الوحدة
    emptyPage: 'الصفحة فارغة',
    uCanAddFavDesign: 'يمكنك إضافة تصميم من',
    uaeItAtUrUnit: 'واستخدامها فى تصميم وحدتك',
    HomePage: 'للصفحة الرئيسية',
    materialsAndProducts: 'لتقديم شركات الخامات و منتجات',
    favoriteRooms: 'أعجبني',
    notifications: 'الإشعارات',
    groundFloor: 'الدور الأرضي',
    firstFloor: 'الدور الأول',
    secondFloor: 'الدور الثاني',
    thirdFloor: 'الدور الثالث',
    fourthFloor: 'الدور الرابع',
    fifthFloor: 'الدور الخامس',
    moreThanFifthFloor: 'أكثر من الدور الخامس',
    MianPage: 'الصفحة الرئيسية',
    favPage: 'المفضلة',
    packagesPage: 'خدمات',
    draftPage: 'المسودة',
    logOut: 'خروج من الحساب',
    guest: 'تسجيل الدخول',
    guest_name:'زائر',
    createAccout: 'إنشاء حساب',
    more: 'المزيد',
    topNavMsg: 'قم بإنشاء حساب للاستمتاع بمزايا وخدمات أوجى المختلفة.  ',
    regNow: ' إنشاء حساب',
    residential: 'سكني',
    commercial: 'تجاري',
    managerial: 'إداري',
    house: 'شقة',
    studio: 'استوديو',
    villa: 'فيلا',
    duplex: 'دوبليكس',
    noFavRoomsOfThisType: 'لا يوجد حاليًا غرف أعجبت لهذا النوع من الغرف',
    FreqAskedQuestion: 'الأسئلة الشائعة:',
    emptyDraft: 'المسودة فارغة',
    noUnits: 'لا يوجد لديك وحدة فى الوقت الحالى, يمكنك البدئ فى إضافة تفاصيل وحدتك.',
    userName: 'اسم المستخدم',
    phoneNumber: 'رقم الهاتف',
    eMail: 'البريد الإلكتروني',
    password: 'كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    confirmOn: 'عند ضغطك على إستمرار فأنت توافق على ',
    termsAndCond: 'شروط الإستخدام',
    privayPolicy: 'و سياسة الخصوصية',
    veifyOTP: 'التحقق من رقم الهاتف',
    enterOTP: 'برجاء إدخال رمز التحقق المرسل فى رسالة نصية إلى رقمك',
    coundRecieveOTP: 'لم تصلك الرسالة ؟',
    resendOTP: ' إعادة إرسال رمز التحقق',
    loginHeader: 'تسجيل دخول',
    forgotPassword: 'هل نسيت كلمة المرور ؟',
    worngUserOrPassword: 'برجاء التاكد من اسم المستخدم او كلمة المرور',
    areUsure: '” هل انت متأكد“',
    areUsurefromDuplicate: 'هل انت متأكد من تكرار هذه الغرفة ؟',
    forgetPassword: 'هل نسيت كلمة المرور ؟',
    acceptOn: 'عند ضغطك على إستمرار فأنت توافق على ',
    userNotExist: 'المستخدم غير موجود!',
    PhoneRequired: 'رقم الهاتف مطلوب!',
    invalidOTP: 'رمز التحقق غير صحيح ، يرجى التحقق مرة أخرى',
    enterNewPassword: 'أدخل كلمة المرور الجديدة',
    requiredFileds: 'الرجاء ملئ الحقول المطلوبة ',
    RequiredField: 'هذه الخانة مطلوبه',
    passwordMustMatch: 'كلمات المرور غير متطابقة',
    passwordNotComplex: 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل ولا يقل طولها عن 6 أحرف',
    firstName: 'الاسم الاول',
    lastName: 'الاسم الأخير',
    userName: 'الاسم',
    gender: 'النوع',
    dob: 'تاريخ الميلاد',
    saveData: 'حفظ البيانات',
    pressHere: 'أضغط هنا',
    male: 'ذكر',
    female: 'انثى',
    savedSuccessfully: 'تم حفظ المعلومات بنجاح',
    companyName: 'اسم الشركة',
    companyAddress: 'عنوان الشركه',
    contactPerson: 'اسم الشخص المطلوب التواصل معه',
    comapnyPhone: 'رقم هاتف',
    personPosition: 'منصبه بالشركة',
    commercialRegister: 'رقم السجل التجاري',
    taxNumber: 'الرقم الضريبي',
    previuosWorks: 'هل هناك أعمال موجوده للمعاينة ؟',
    yes: 'نعم',
    no: 'لا',
    sendApplication: 'إرسال',
    message: 'رسالة',
    typeYourComplain: 'اكتب موضوع الشكوى',
    willRespondSoon: 'سيقوم فريق أوجي بدراسة شكوتك و بالرد عليك في اسرع وقت ممكن.',
    send: 'إرسال',
    complaintSentSuccessfully: 'تم إرسال الشكوى بنجاح',
    chooseArea: 'من فضلك اختر من المجالات الاتية',
    requestSendSuccessfuly: 'تم إرسال الطلب بنجاح',
    whoWeAre: 'من نكون؟',
    completeUnitData: 'الرجاء إكمال بيانات الغرف لمعرفة تكلفة تشطيب الوحدة',
    validData: 'الرجاء إدخال بيانات صحيحة',
    english:'English',
    arabic:'العربية',
    downloadTheApp:'حمل التطبيق الان !',
    viewMoreOf:'رؤية جميع المميزات',
    maxNoOfDoors_windows:'برجاء العلم بأن الحد الأقصي لعدد الأبواب و النوافذ هو 2',
    warn:'” تنبيه“',
    fine:'حسناً',
    filedRequried:'{{field}} مطلوب',
    passwordInvalid:"كلمة المرور غير متطابقة",
    otpInvalid:"رقم غير صحيح",
    password6Char:"يجب أن تتكون كلمة المرور من 6 أحرف أو أرقام على الأقل",
    invalidEmail:"البريد الإلكتروني غير صحيح",
    invalidPhone:"رقم الهاتف غير صالح",
    selectRoomType:'الرجاء تحديد نوع الغرفة!',
};  