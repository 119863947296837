import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScrollToTop.module.scss';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}


ScrollToTop.propTypes = {};

ScrollToTop.defaultProps = {};

export default ScrollToTop;
