import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CustomInput } from '../../../components';
import styles from './UnitDefine.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { t } from 'i18next';
import {
  setUnitIfnoParam,
  checkOnUnitTypesAsBook,
  checkOnUnitType,
  selectUnit,
  initUnitState
} from '../../../Reducer/UnitSlice';
import { Validators, validatorsInput } from '../../../services/validators';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_CITIES_AND_AREAS
} from '../../../Graphql/UnitForm/Queries';
import {
  ADD_UNIT
} from '../../../Graphql/UnitForm/mutations';
import {
  selectLang
} from '../../../Reducer/LangSlice'
import {
  selectUser
} from '../../../Reducer/UserSLice';
import { MustLoginModal } from '../../../components';
import { LoadingSpinner } from '../../../Pages';

const UnitDefine = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var unit = useSelector(selectUnit);
  var Lang = useSelector(selectLang);
  var user = useSelector(selectUser);
  const [currentTab, setCurrentTab] = useState('step1');
  const [areas, setAreas] = useState([]);
  let cities_and_areas = useQuery(GET_CITIES_AND_AREAS);
  const [addUnit, add_unit_data] = useMutation(ADD_UNIT);
  const onChangeCity = (value) => {
    cities_and_areas.data.getCitiesandAreas.find(city => {
      dispatch(setUnitIfnoParam({ name: 'cityID', value: +value }));
      if (city.id == value) {
        setAreas(city.areas)
      }
    });
  }
  const SubmitAndRedirect = () => {
    addUnit({
      variables: {
        name: unit.info.name,
        type: unit.info.type,
        floor: unit.info.floor,
        category: unit.info.category,
        areaID: unit.info.areaID,
        userID: +user.user.userId
      },
    }).then(results => {
      dispatch(setUnitIfnoParam({ name: 'id', value: +results.data.add_unit.id }))
      // console.log(results.data.add_unit.id);
    });
    if (add_unit_data.error) {
      // console.log(add_unit_data.error);
    } else {
      !add_unit_data.loading && navigate('/UnitForm/rooms');
    }

  }
  const goToNextStep = nextStep => {
    setCurrentTab(nextStep)
  }
  useEffect(() => {
    dispatch(initUnitState())
  }, [])
  if (!user.user.userId) {
    return (
      <div className={styles.UnitDefine}>
        <MustLoginModal />
      </div>);
  }
  if (cities_and_areas.loading) {
    return (
      <div className={styles.UnitDefine}>
        <LoadingSpinner />
      </div>
    );
  }
  if (cities_and_areas.error) return `Submission error! ${cities_and_areas.error.message}`;
  return (
    <div className={styles.UnitDefine}>
      <div className="container">
        <div className={styles.header}>
          <div className="row">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')} />
              <Link to='/'>{t('home')}</Link>
              <span> \ </span>
              <Link to='/UnitForm'>{t('UnitForm')}</Link>
            </div>
            {/* <div className={styles.mid + ' col-12 col-lg-4'}>

          </div>
          <div className={styles.end + ' col-12 col-lg-4'}>

          </div> */}
          </div>
        </div>
        <div className={styles.step}>
          {currentTab === 'step1' && <div className={styles.step1}>
            <div className={styles.body}>
              <div className={styles.inputForm}>
                <div className={styles.labelContainer}>
                  <Icons.RiEditLine /> <label htmlFor="">{t('writeUnitName')}</label>
                </div>
                <div className={styles.inputContainer}>
                  <CustomInput
                    placeholder={t('unitName')}
                    value={unit.info.name}
                    validators={[
                      { check: Validators.required, message: 'field is required' }
                    ]}
                    onChange={value => dispatch(setUnitIfnoParam({ name: 'name', value: value }))} />
                </div>
              </div>
              <div className={styles.inputForm}>
                <div className={styles.labelContainer}>
                  <label htmlFor="">{t('unitType')}</label>
                </div>
                <div className={styles.inputContainer}>
                  {unit.unitTypesAsBook.map((item, index) => {
                    return (
                      <div
                        key={'choice' + index}
                        id={'choice' + index}
                        onClick={() => dispatch(checkOnUnitTypesAsBook(index))}
                        className={(item.isChecked && item.isAva) ? styles.choice + ' ' + styles.active : styles.choice}>
                        <p>{t(item.id)}</p>
                        {(item.isChecked && item.isAva) && <Icons.FaCheckSquare />}
                        {!item.isAva && <p className={styles.soon}>{t('soon')}</p>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.progressBar}>
                <p>25%</p>
                <div className={styles.progress}></div>
              </div>
              <button className='btn' onClick={() => goToNextStep('step2')}>{t('next')}</button>
            </div>
          </div>}
          {currentTab === 'step2' && <div className={styles.step2}>
            <div className={styles.body}>
              <div className={styles.subHeader}>
                <Icons.RiHome2Line /> <label htmlFor=""> {t('whatTypeUnit')}</label>
              </div>
              <div className={styles.typesContainer}>
                {unit.unitTypes.map((item, index) => {
                  return (
                    <div key={'unitType' + index} className={(item.isChecked && item.isAva) ? styles.type + ' ' + styles.active : styles.type} onClick={() => dispatch(checkOnUnitType(index))}>
                      <div className={styles.title}>
                        <p>{t(item.id)}</p>
                        {(item.isChecked && item.isAva) && <Icons.FaCheckSquare />}
                        {!(item.isChecked && item.isAva) && <Icons.FaCheckSquare className={styles.unChecked} />}
                      </div>
                      <img className={!item.isAva && styles.notActive} src={Images.unitType} alt="" />
                      {!item.isAva && <div className={styles.soon}>{t('soon')}</div>}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.progressBar}>
                <p>50%</p>
                <div className={styles.progress}></div>
              </div>
              <button className='btn' onClick={() => setCurrentTab('step3')}>{t('next')}</button>
            </div>
          </div>}
          {currentTab === 'step3' && <div className={styles.step3}>
            <div className={styles.body}>
              <div className={styles.inputForm}>
                <div className={styles.labelContainer}>
                  <label htmlFor="">{t('selectlocation')}</label>
                </div>
                <div className={styles.inputContainer}>
                  <select className='form-select' defaultValue={unit.info.cityID} type="text" onChange={(event) => onChangeCity(event.target.value)} >
                    <option hidden value={''}>{t('selectlocation')}</option>
                    {cities_and_areas.data.getCitiesandAreas.map(city => {
                      return (
                        <option key={city.id} value={city.id}>{Lang.name.toLowerCase() === 'ar' ? city.arabic_name : city.english_name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className={styles.inputForm}>
                <div className={styles.labelContainer}>
                  <label htmlFor="">{t('selectRegion')}</label>
                </div>
                <div className={styles.inputContainer}>
                  <select className='form-select' defaultValue={unit.info.areaID} type="text" onChange={(e) => dispatch(setUnitIfnoParam({ name: 'areaID', value: +e.target.value }))}>
                    <option hidden value={''}>{t('selectRegion')}</option>
                    {areas?.map(area => {
                      return (
                        <option key={area?.id} value={area?.id}>{Lang.name.toLowerCase() === 'ar' ? area?.arabic_name : area?.english_name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className={styles.inputForm}>
                <div className={styles.labelContainer}>
                  <label htmlFor="">{t('selectFloor')}</label>
                </div>
                <div className={styles.inputContainer}>
                  <select className='form-select' defaultValue={unit.info.floor} type="text" onChange={(e) => dispatch(setUnitIfnoParam({ name: 'floor', value: +e.target.value }))}>
                    <option hidden value={''}>{t('selectFloor')}</option>
                    <option value="0">{t('groundFloor')}</option>
                    <option value="1">{t('firstFloor')}</option>
                    <option value="2">{t('secondFloor')}</option>
                    <option value="3">{t('thirdFloor')}</option>
                    <option value="4">{t('fourthFloor')}</option>
                    <option value="5">{t('fifthFloor')}</option>
                    <option value="6">{t('moreThanFifthFloor')}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.progressBar}>
                <p>75%</p>
                <div className={styles.progress}></div>
              </div>
              <button className='btn' onClick={() => SubmitAndRedirect()}>{t('next')}</button>
            </div>
          </div>}
        </div>
        {/* <div className={styles.Footer}>
        <div className="row justify-content-end">
          <div className={styles.start + ' col-4'}>

          </div>
          <div className={styles.mid + ' col-4'}>

          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

UnitDefine.propTypes = {};

UnitDefine.defaultProps = {};

export default UnitDefine;
