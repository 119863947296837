import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FilterBar.module.scss';
import { t } from 'i18next';
import * as icons from '../../Assets/icons';
import {
  selectHome,
  toggleFilterItem
} from '../../Reducer/HomeSlice';
import { selectLang } from '../../Reducer/LangSlice';
import { useSelector, useDispatch } from 'react-redux';
  import { useNavigate } from 'react-router-dom';

const FilterBar = ({ isHasSort, isShowList, onClick_AppluFilter }) => {
  const [filtering, setFiltering] = useState(false);
  const [sorting, setSorting] = useState(false);
  // const [AllStatus, setAllStatus] = useState({ roomsNameList: true, costList: true, styleList: true });
  var home = useSelector(selectHome);
  var lang = useSelector(selectLang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFound = ({ listName, index }) => {
    // var isAllFlag = true;
    // home[listName].map(item => {
    //   if (item.isActive) {
    //     isAllFlag = false;
    //   }
    // });
    // setAllStatus(prevState => ({
    //   ...prevState,
    //   [listName]: isAllFlag
    // })
    // );
    return home[listName][index]?.isActive;
  }
  const onClickApplyFiltering = () => {
    setFiltering(!filtering);
    onClick_AppluFilter();
    navigate('/searchResult');
  }
  const toggleItemFromList = ({ listName, index }) => {
    dispatch(toggleFilterItem({ name: listName, value: index }));
  }
  const onClick_removeFromList = (keyWord) => {
    dispatch(toggleFilterItem({ name: keyWord.keyList, value: keyWord.keyWord.id }));
    onClick_AppluFilter();
    navigate('/searchResult');
  }
  //TODO: functionality of sorting and filtering to get it from parent compnent.
  return (
    <div className={styles.FilterBar}>
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <div className={styles.searchBodyContainer}>
              <div className={styles.bodyContainer}>
                <div className={styles.searchbody}>
                  {/* && isShowList */}
                  {(home.filterBarKeyList.length > 0) ?
                    <div className={styles.searchItemsContainer}>
                      {home.filterBarKeyList.map(item => {
                        return (
                          <div className={styles.searchItems}>
                            <span>{lang.name === 'AR' ? item?.keyWord?.arabic_name : item?.keyWord?.english_name}</span>
                            <img src={icons.closeIcon} alt="sort" onClick={() => onClick_removeFromList(item)} />
                          </div>
                        );
                      })}
                    </div> :
                    <div className={styles.placeholder}><img src={icons.arrowRightIcon} alt="sort" /> <span>{t('searchFor')}</span></div>}

                </div>
                <div className={styles.spliter}></div>
                {/* <button onClick={() => { setSorting(!sorting); setFiltering(false) }} className={styles.sortBtn + (isHasSort ? '' : ' disbaled')} ><img src={icons.sortIcon} alt="sort" /></button> */}
                <button onClick={() => { setFiltering(!filtering); setSorting(false) }} className={styles.filterBtn} ><img src={icons.filterIcon} alt="filter" /></button>
              </div>
            </div>
          </div>
        </div>
        {filtering && <div className={styles.filterOptions + " container"}>
          <div className="row">
            <div className="col-12 p-0">
              <div className={styles.body}>
                <div className="row">
                  <div className={styles.category + " col-12 col-lg-4 p-0"}>
                    <h5>{t('rooms')}</h5>
                    <div className="categoryBody">
                      {/* <button className={AllStatus["roomsNameList"] ? styles.active : ''} type='button'>{t('all')}</button> */}
                      {home.roomsNameList.map((roomName, index) => {
                        return (
                          <button
                            key={"roomName_" + roomName.id}
                            className={isFound({ index: index, listName: "roomsNameList" }) ? styles.active : ''}
                            onClick={() => toggleItemFromList({ index: roomName.id, listName: "roomsNameList" })}
                            type='button'>
                            {lang.name === 'AR' ? roomName?.arabic_name : roomName?.english_name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles.category + " col-12 col-lg-4 p-0"}>
                    <h5>{t('designType')}</h5>
                    <div className="categoryBody">
                      {/* <button className={isFound(12) ? styles.active : ''} type='button'>{t('all')}</button> */}
                      {home.styleList.map((roomName, index) => {
                        return (
                          <button
                            key={"style_" + roomName.id}
                            className={isFound({ index: index, listName: "styleList" }) ? styles.active : ''}
                            onClick={() => toggleItemFromList({ index: roomName.id, listName: "styleList" })}
                            type='button'>
                            {lang.name === 'AR' ? roomName?.arabic_name : roomName?.english_name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles.category + " col-12 col-lg-4 p-0"}>
                    <div className="row">
                      {/* <div className={styles.designTypeBody + " col-12 p-0"}>
                        <h5>{t('rawMaterialAndProducts')}</h5>
                        <div className="categoryBody">
                          <button className={isFound(25) ? styles.active : ''} onClick={() => toggleItemFromList({ id: 25, name: t('all') })} type='button'>{t('all')}</button>
                          <button className={isFound(26) ? styles.active : ''} onClick={() => toggleItemFromList({ id: 26, name: t('classic') })} type='button'>{t('classic')}</button>
                          <button className={isFound(27) ? styles.active : ''} onClick={() => toggleItemFromList({ id: 27, name: t('newClassic') })} type='button'>{t('newClassic')}</button>
                          <button className={isFound(28) ? styles.active : ''} onClick={() => toggleItemFromList({ id: 28, name: t('modern') })} type='button'>{t('modern')}</button>
                          <button className={isFound(29) ? styles.active : ''} onClick={() => toggleItemFromList({ id: 29, name: t('simple') })} type='button'>{t('simple')}</button>
                        </div>
                      </div> */}
                      <div className="col-12 p-0">
                        <h5>{t('finishingCost')}</h5>
                        <div className="categoryBody">
                          {/* <button className={isFound(30) ? styles.active : ''} onClick={() => toggleItemFromList({ id: 30, name: t('all') })} type='button'>{t('all')}</button> */}
                          {home.costList.map((roomName, index) => {
                            return (
                              <button
                                key={"cost_" + roomName.id}
                                className={isFound({ index: index, listName: "costList" }) ? styles.active : ''}
                                onClick={() => toggleItemFromList({ index: roomName.id, listName: "costList" })}
                                type='button'>
                                {lang.name === 'AR' ? roomName?.arabic_name : roomName?.english_name}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <button className={'btn ' + styles.searchBtn} onClick={() => onClickApplyFiltering()} type='button'><img src={icons.searchIcon} alt="searchIcon" /><span>{t('search')}</span></button>
              </div>
            </div>
          </div>
        </div>}
        {sorting && <div className={styles.sortOptions + " container"}>
          <div className="row">
            <div className="col-12 p-0">
              <div className={styles.body}>
                <div className="row">
                  <div className={styles.category + " col-12 col-lg-4"}>
                    <h5><img src={icons.boxIcon} alt="" />{t('history')}</h5>
                    <div className={styles.categoryBody}>
                      <button className={styles.active} type='button'>{t('random')}</button>
                      <button type='button'>{t('fromNewToOld')}</button>
                      <button type='button'>{t('fromOldToNew')}</button>
                    </div>
                  </div>
                  <div className={styles.category + " col-12 col-lg-4"}>
                    <h5><img src={icons.dolarSignIcon} alt="" />{t('roomSize')}</h5>
                    <div className={styles.categoryBody}>
                      <button className={styles.active} type='button'>{t('random')}</button>
                      <button type='button'>{t('fromSmallToBig')}</button>
                      <button type='button'>{t('formBigToSmall')}</button>
                    </div>
                  </div>
                  <div className={styles.category + " col-12 col-lg-4"}>
                    <h5><img src={icons.clockIcon} alt="" />{t('coast')}</h5>
                    <div className={styles.categoryBody}>
                      <button className={styles.active} type='button'>{t('random')}</button>
                      <button type='button'>{t('lowestToHightPrice')}</button>
                      <button type='button'>{t('highestToLowestPrice')}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <button className={'btn ' + styles.searchBtn} onClick={() => setSorting(!sorting)} type='button'><img src={icons.searchIcon} alt="searchIcon" /><span>{t('search')}</span></button>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  isHasSort: PropTypes.bool,
  isShowList: PropTypes.bool,
  onClick_AppluFilter:PropTypes.func
};

FilterBar.defaultProps = {
  isHasSort: true,
  isShowList: false,
  onClick_AppluFilter:()=>{}
};

export default FilterBar;
