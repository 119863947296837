import React from 'react'
import styles from './Spinner.module.scss'
import {
  Spinner
}
from '../../components';

function LoadingSpinner() {
  return (
    <div className={styles.spinnerContainer}>
      <Spinner />
    </div>
  )
}

export default LoadingSpinner