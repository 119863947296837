import gql from 'graphql-tag';

export const APPLY_COMPANIES = gql`       
mutation applyCompanies(
        $userID:ID!,
        $name: String!,
        $commercial_record: String!,
        $tax_id: String!,
        $is_work_for_review: Boolean!,
        $contact_person: String!,
        $cp_phone: String!,
        $cp_title: String!,
        $email: String!,
    ){
    FinishingCompany(
        CompanyInput:{
            name: $name
            contact_person: $contact_person
            cp_phone: $cp_phone
            cp_title:  $cp_title
            email: $email
            commercial_record: $commercial_record
            tax_id:  $tax_id
            is_work_for_review: $is_work_for_review 
        },
        userID: $userID
    ){
        done
    }
}`
export const APPLY_SERVICES = gql`       
mutation applyServices(
        $userID:ID!,
        $name: String!,
        $contact_person: String!,
        $cp_phone:String!,
        $cp_title: String!,
        $email: String!,
        $commercial_record: String!,
        $tax_id: String!,
        $is_work_for_review: Boolean!,
        $fields_of_work: [String]
    ){
    FinishingSupplier(
        SupplierInput:{
            name:$name
            contact_person: $contact_person
            cp_phone: $cp_phone
            cp_title:  $cp_title
            email: $email
            commercial_record: $commercial_record
            tax_id:  $tax_id
            is_work_for_review: $is_work_for_review
            fields_of_work: $fields_of_work
        },
        userID:$userID
        ){
            done
        }
}`
export const SEND_COMPLAINS = gql`
mutation sendComplaint(
  $message:String!
  $userID:ID!
) {
  sendComplaint(
    message:$message,
    userID: $userID
  ) {
    done
  }
}`
export const SEND_PAKAGES_REQUEST = gql`
mutation sendPakagesRequest(
  $unitID:ID!
  $userID:ID!
  $packageIDs:[ID]
){
  sendRequest(
    userID:$userID
    unitID: $unitID
    packageIDs: $packageIDs
  ){
      done
  }
}`
export const DELETE_UNITS = gql`
mutation deleteUnitRequest
(
  $unitsIDs:[ID]
  $userID:ID!
){
    delete_units(unitsIDs:$unitsIDs,userID:$userID){
            done
    }
}
`