import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './UserProfile.module.scss';
import { t } from 'i18next';
import { selectUser, updateUser } from '../../Reducer/UserSLice';
import { useSelector, useDispatch } from 'react-redux';
import * as Icons from '../../Assets/icons';
import { Link } from 'react-router-dom';
import { UPDATE_USER_PROFILE } from '../../Graphql/Authentication/mutations'
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const UserProfile = () => {
  var user = useSelector(selectUser);
  const [userName, setUsername] = useState(user.user.username)
  const [gender, setGender] = useState(user.user.gender)
  const [dob, setDob] = useState(user.user.date_of_birth)
  const [email, setEmail] = useState(user.user.email);
  const dispatch = useDispatch();
  const [updateUserProfile, { data, error, loading }] = useMutation(UPDATE_USER_PROFILE);
  const handleChange_Gender = (value) => {
    setGender(value)
  }
  const handleChange_Name = (e) => {
    setUsername(e.target.value)
  }
  const handleChange_date = (e) => {
    setDob(e.target.value)
  }
  const handleChange_email = (e) => {
    setEmail(e.target.value)
  }
  const onSubmit = () => {
    updateUserProfile({
      variables: {
        userId: user.user.userId,
        name: userName,
        email: email,
        dob: dob,
        gender: gender
      }
    })
  }
  useEffect(() => {
    if (data?.Update_Profile?.id) {
      dispatch(updateUser(data.Update_Profile))
      toast.success(t('savedSuccessfully'), { autoClose: 1000 });
    }

    // console.log(data);
  }, [data]);
  useEffect(() => {
    toast.error(error?.message, { autoClose: 1000 });
  }, [error]);
  return (
    <div className={styles.UserProfile}>
      <div className={styles.header}>
        <div className="row">
          <div className={styles.start + ' col-12 col-lg-4'}>
            <Icons.AiOutlineArrowLeft />
            <Link to='/'>{t('home')}</Link>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.formContainer}>
          <div className={styles.changeAbleContainer}>
            <div className={styles.fieldContainer}>
              <label htmlFor="">{t('userName')}</label>
              <div className={styles.field}>
                <input type="text" value={userName} onChange={(e) => handleChange_Name(e)} />
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <label htmlFor="">{t('gender')}</label>
              <div className={styles.field}>
                <button type='button' className={gender == 'male' ? styles.active : ''} onClick={() => handleChange_Gender('male')}>{t('male')}</button>
                <button type='button' className={gender == 'female' ? styles.active : ''} onClick={() => handleChange_Gender('female')}>{t('female')}</button>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <label htmlFor="">{t('dob')}</label>
              <div className={styles.field}>
                <input type="date" value={dob} onChange={(e) => handleChange_date(e)} />
              </div>
            </div>
          </div>
          <div className={styles.ConstDataContainer}>
            <div className={styles.fieldContainer}>
              <label htmlFor="">{t('eMail')}</label>
              <div className={styles.field}>
                <input type="text" value={email} onChange={(e) => handleChange_email(e)} />
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <label htmlFor="">{t('phoneNumber')}</label>
              <div className={styles.field}>
                <input type="text" disabled readOnly value={user.user.phone} />
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <button className='btn' type='button' onClick={() => onSubmit()}>{t('saveData')}</button>
          </div>
        </div>

      </div>
    </div>
  )
};

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;
