import React from 'react';
import PropTypes from 'prop-types';
import styles from './UnitThanks.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { t } from 'i18next';
import { APPSTOREURL, GOOGLEPLAYURL } from '../../../ENV';
import {
  selectUser
} from '../../../Reducer/UserSLice';
import { useDispatch, useSelector } from 'react-redux';
const UnitThanks = () => {
  const navigate = useNavigate();
  var user = useSelector(selectUser);
  const saveToAndRedirectToDraft = () => {
    //TODO save
    navigate(`/Home`);
  }
  return (
    <div className={styles.UnitThanks}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-3 col-lg-4'}>
              <Icons.AiOutlineArrowLeft />
              <Link to='/'>{t('backToHomePage')}</Link>
            </div>
            <div className={styles.mid + ' col-6 col-lg-4'}>
              <h3>{t('DoneSent')}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className={styles.thanks}>
            <img src={Images.UnitDonethanks} alt="" />
            <p>{t('thanks')}</p>
            <p>{t('willContactU', { mobileNumber: user.user.phone })}</p>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              {/* <p><span>{t('rateOhjee')}</span> <Link to={GOOGLEPLAYURL} target="_blank">{t('googlePlay')}</Link>{t('and')}<Link to={APPSTOREURL} target="_blank">{t('appStore')}</Link></p> */}
            </div>
            <div className={styles.mid + ' col-12 col-lg-4'}>
              <button className='btn' onClick={() => saveToAndRedirectToDraft()} ><span>{t('backToHomePage')}</span> <Icons.AiOutlineArrowRight /> </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
UnitThanks.propTypes = {};

UnitThanks.defaultProps = {};

export default UnitThanks;
