import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './UnitDesignPlans.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { t } from 'i18next';
import ReactTooltip from 'react-tooltip';
import {
  GET_UNIT_PACKAGES
} from '../../../Graphql/UnitForm/Queries';
import {
  SEND_REQUEST_WITH_PACKAGES
} from '../../../Graphql/UnitForm/mutations';
import { selectLang } from '../../../Reducer/LangSlice'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { CustomInput } from '../../../components';
import { Validators, validatorsInput } from '../../../services/validators';
import { toast } from 'react-toastify';
import {
  selectUnit,
  setUnitIfnoParam
} from '../../../Reducer/UnitSlice';
import {
  selectUser
} from '../../../Reducer/UserSLice';
import { MustLoginModal } from '../../../components';
import { LoadingSpinner } from '../../../Pages';

const UnitDesignPlans = () => {
  let { unitId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let lang = useSelector(selectLang);
  var unit = useSelector(selectUnit);
  var user = useSelector(selectUser);
  const [show, setShow] = useState(false);
  const [userMobile, setUserMobile] = useState(user.user.phone);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data, loading, error } = useQuery(GET_UNIT_PACKAGES);
  const [packagesId, setPackagesId] = useState([]);
  const [sendRequestWithPackages, requestResponse] = useMutation(SEND_REQUEST_WITH_PACKAGES);
  const [viewAll, setViewAll] = useState(null);
  const toggleViewAll = (id) => {
    if (id == viewAll)
      setViewAll(null)
    else
      setViewAll(id)
  }
  const checkChoice = (packageId) => {
    if (!packagesId.includes(packageId)) {
      setPackagesId([...packagesId, packageId]);
    }
    else {
      let newPackageIDs = packagesId.filter(item => {
        return item != packageId
      })
      setPackagesId(newPackageIDs);
    }
    // console.log(packagesId);
  }
  const saveToAndRedirectToDraft = () => {
    navigate(`/UnitDraft`);
  }
  const submitPackagesAndConfirmRequest = () => {
    handleShow();
  }
  const onChange_mobileNo = (mobile) => {
    setUserMobile(mobile);
  }
  const onClick_backToRoomsUnit = () => {
    navigate(-2);
  }
  const saveAndGoToThanksPage = () => {
    // console.log(userMobile.length);
    if (userMobile.length != 11) {
      toast.warn("mobile number is required", { autoClose: 1000 });
    } else {
      sendRequestWithPackages({
        variables: {
          userID: +user.user.userId,
          unitID: +unitId,
          packageIDs: packagesId
        }
      }).then(result => {
        if (result.data.sendRequest.done) {
          navigate(`/UnitForm/thanks`);
          //navigate(`/UnitForm/inspectionDate`);
        } else {
          console.error(result);
        }
      })
    }

  }
  if (!user.user.userId) {
    return (
      <div className={styles.UnitDesignPlans}>
        <MustLoginModal />
      </div>)
  }
  if (loading) return (
    <div className={styles.UnitDesignPlans}>
      <LoadingSpinner />
    </div>
  );
  if (error) return `Submission error! of finishingCostAndFinishingStyles ${error.message}`;
  return (
    <div className={styles.UnitDesignPlans}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-3 col-lg-4'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')}/>
              <Link to='/'>{t('cancelBackToHome')}</Link>
            </div>
            <div className={styles.mid + ' col-6 col-lg-4'}>
              <h3>{t('selectyourPlan')}</h3>
            </div>
            <div className={styles.end + ' col-3 col-lg-4'}>
              <button onClick={() => onClick_backToRoomsUnit()}><span>{t('edit')}</span><Icons.BiEdit /></button>
              {/* <button><Icons.FiUpload /></button> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className="row justify-content-center">
            {data?.Packages?.map((item, index) => {
              return (
                <div className="col-12 col-md-6 col-lg-3">
                  <div
                    className={packagesId.includes(item.id) ? styles.designPlan + ' ' + styles.active : styles.designPlan}>
                    {item.mostSelected && <div className={styles.mostSelected}>
                      <p>{t('mostSelected')}</p>
                    </div>}
                    <div className={styles.planBody}>
                      <div className={styles.offerDescription} onClick={() => checkChoice(item.id)}>
                        <h4 className={styles.name}>{lang.name == 'AR' ? item.arabic_title : item.english_title}</h4>
                        <p>{lang.name == 'AR' ? item.arabic_subtitle : item.english_subtitle}</p>
                        <div className={styles.cost}>
                          <p dir='ltr'>/LE</p>
                          <p>{item.price}</p>
                          <p>m2</p>
                        </div>
                        <p className={styles.bookDesc}>{lang.name == 'AR' ? item.arabic_short_description : item.english_short_description}</p>
                      </div>
                      <div className={`${styles.offerList} ${viewAll == item.id ? styles.viewAll : ''}`}>
                        <p className={styles.offerDesc} data-tip data-for={`offer${index}`}>{lang.name == 'AR' ? item.arabic_description : item.english_description}</p>
                        <div className={styles.tooltip}>
                          <ReactTooltip id={`offer${index}`} place="top" type="dark" effect="solid">
                            <p>{lang.name == 'AR' ? item.arabic_description : item.english_description}</p>
                          </ReactTooltip>
                        </div>
                        {/* <ul>
                          {item.offerList.map((offer, index) => {
                            return (
                              <>
                                <li data-tip data-for={`offer${index}`}><Icons.FcCheckmark /> {offer}</li>
                                <ReactTooltip id={`offer${index}`} place="top" type="dark" effect="solid">
                                  <span>{offer}</span>
                                </ReactTooltip>
                              </>
                            );
                          })}
                        </ul> */}
                        <div className={styles.mobile_viewMore} onClick={() => toggleViewAll(item.id)}>
                          <span>
                            {t('viewMoreOf')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <p>{t('agreeToTermsAndConditions')} <Link to='/TermsOfUse'>{t('terms')}</Link> {t('and')} <Link to='/PrivacyPolicy'>{t('ploicy')}</Link> </p>
            </div>
            <div className={styles.mid + ' col-12 col-lg-5'}>
              <button className='btn' onClick={() => saveToAndRedirectToDraft()} ><Icons.AiOutlineArrowRight /> <span>{t('saveToDraft')}</span></button>
              <button className='btn' onClick={() => submitPackagesAndConfirmRequest()} ><span>{t('next')}</span><Icons.AiOutlineArrowLeft /></button>
            </div>
          </div>
        </div>
      </div>
      <Modal id={"unitConfirmation"} show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <p>{t('confirmMsgBookUnit')}</p>
          <p>{t('contactNumber')}</p>
          <div className="inputContainer">
            <Icons.RiEditLine />
            <CustomInput
              className='input-group-text'
              type="text"
              acceptOnly='number'
              value={userMobile}
              validators={[
                { check: Validators.required, message: 'field is required' },
                { check: Validators.mobile, message: 'please enter valid mobile' }
              ]}
              onChange={event => onChange_mobileNo(event)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='modalButton' onClick={() => saveAndGoToThanksPage()} >
            <span>{t('comfirmRequest')} </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UnitDesignPlans.propTypes = {};

UnitDesignPlans.defaultProps = {};

export default UnitDesignPlans;
