import gql from 'graphql-tag';

export const LIKE_ROOM = gql`
mutation($userID:ID!,$roomId:ID!){
  LikeRoom(userID:$userID,roomID:$roomId){
    done
  }
}
`;

export const UN_LIKE_ROOM = gql`
mutation($userID:ID!,$roomId:ID!){
    UnLikeRoom(userID:$userID,roomID:$roomId){
    done
  }
}
`;
export const DelAllNotif = gql`
  mutation($Uid:ID!){
    DeleteAllNotifications(userID:$Uid){
      done
    }
  }
`;

export const DelNotif = gql`
  mutation(
    $Nid:ID!
    $Uid:ID!
    ){
    DeleteSingleNotification(notifcationId: $Nid,userID:$Uid){
        done
    }
}
`;