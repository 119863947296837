import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UnitsDraft.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../Assets/icons';
import * as Images from '../../Assets/images';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import {
  DELETE_UNITS,
} from '../../Graphql/UnitForm/mutations';
import {
  GET_MY_UNITS,
  GET_UNIT_PRRICE
} from '../../Graphql/UnitForm/Queries';
import {
  setUnitIfnoParam
} from '../../Reducer/UnitSlice';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { MustLoginModal } from '../../components';
import { LoadingSpinner } from '../../Pages';
import {
  selectLang
} from '../../Reducer/LangSlice';


const UnitsDraft = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  var user = useSelector(selectUser);
  var lang = useSelector(selectLang);
  const [deleteUnits, deleteUnitsResult] = useMutation(DELETE_UNITS);
  const [deleteAble, setDeleteAble] = useState(false);
  const [deletedUnits, setDeleteUnits] = useState([]);
  const [getMyUnits, myUnits] = useLazyQuery(GET_MY_UNITS);
  const [getUnitPrice, unitPrice] = useLazyQuery(GET_UNIT_PRRICE);
  const toggleDeletedRoom = (value) => {
    if (deletedUnits.includes(value)) {
      setDeleteUnits((current) => current.filter((roomId) => roomId !== value));
    } else {
      setDeleteUnits(current => [...current, value])
    }
    // console.log(deletedUnits);
  }
  const onClick_deleteSelectedUnits = () => {
    // console.log(deletedUnits);
    deleteUnits({
      variables: {
        unitsIDs: deletedUnits,
        userID: +user.user.userId
      }
    }).then(result => {
      if (result.data.delete_units.done) {
        setDeleteAble(false)
        setDeleteUnits([])
        myUnits.refetch({ userId: +user.user.userId });
        getMyUnits({ variables: { userId: +user.user.userId } })
      }
    })
  }
  const viewDate = (date) => {
    if (date) {
      var dateArray = date?.split('T')[0].split('-');
      // console.log(dateArray[2].toLocaleString('ar-EG'));
      var day = lang.name === 'AR' ? dateArray[2].toLocaleString('ar-EG') : dateArray[2];
      var month = lang.name === 'AR' ? dateArray[1].toLocaleString('ar-EG') : dateArray[1];
      var year = lang.name === 'AR' ? dateArray[0].toLocaleString('ar-EG') : dateArray[0];
      var Finaldate = `${day}/${month}/${year}`
      return lang.name === 'AR' ? Finaldate.toLocaleString('ar-EG') : Finaldate
    }

  }
  const onClick_editUnit = (unitId) => {
    let myUnit = myUnits?.data?.getMyUnits.filter(unit => unit.id == unitId)[0];
    // console.log(myUnit);
    dispatch(setUnitIfnoParam({ name: 'id', value: unitId }));
    dispatch(setUnitIfnoParam({ name: 'name', value: myUnit.name }));
    dispatch(setUnitIfnoParam({ name: 'category', value: myUnit.category }));
    dispatch(setUnitIfnoParam({ name: 'type', value: myUnit.type }));
    navigate(`/UnitForm/rooms/${unitId}`);
  }
  const onClick_updatePriceUnit = (unitId) => {
    getUnitPrice({
      variables: { userId: 12, unitId: unitId },
    })
  }
  useEffect(() => {
    myUnits.refetch({ userId: +user.user.userId });
    getMyUnits({ variables: { userId: +user.user.userId } });
  }, [unitPrice.data]);

  useEffect(() => {
    myUnits.refetch({ userId: +user.user.userId });
    getMyUnits({ variables: { userId: +user.user.userId } })
  }, []);

  if (!user.user.userId) {
    return (
      <div className={styles.UnitsDraft}>
        <MustLoginModal />
      </div>)
  }
  if (myUnits.loading) return (
    <div className={styles.UnitsDraft}>
      <LoadingSpinner />
    </div>
  );
  if (myUnits.error) return `Submission error! of GET_MY_UNITS ${myUnits.error.message}`;
  return (
    <div className={styles.UnitsDraft}>
      <div className={styles.header}>
        <div className="container">
          <div className="row justify-content-between">
            <div className={styles.start + ' col-3 col-md-4'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')} />
              <Link to='/'>{t('home')}</Link>
            </div>
            <div className={styles.end + ' col-9 col-md-4'}>
              {!deleteAble &&
                <div className={styles.deleteActionContainer + ' flex-col'}>
                  {/* <button><span>{t('edit')}</span><Icons.BiEdit /></button> */}
                  <button><Icons.RiDeleteBin6Line onClick={() => setDeleteAble(!deleteAble)} /></button>
                </div>
              }
              {deleteAble &&
                <div className={styles.deleteActionContainer + ' flex-col justify-content-center'}>
                  <div>
                    <button className={` ${styles.deleteAction_ok}`} onClick={() => onClick_deleteSelectedUnits()}><span>{t('deleteOK')}</span> <Icons.RiDeleteBin6Line /></button>
                    <button className={` ${styles.deleteAction_cancel}`} onClick={() => setDeleteAble(!deleteAble)} ><span>{t('deleteCancel')}</span> <span>X</span></button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className="row justify-content-center">
            <div className={styles.draftContainer + " col-12 col-md-6 col-lg-4"}>
              <div className={styles.draft_step}>
                <div className={`${styles.step_head} ${styles.un_finished}`}>
                  <h5>{t('unFinished')}</h5>
                  <p>{myUnits?.data?.getMyUnits?.filter(unit => { return !unit.isCalculatable }).length}</p>
                </div>
                <div className={styles.step_body}>
                  {myUnits?.data?.getMyUnits?.filter(unit => { return !unit.isCalculatable }).map(unit => {
                    return (
                      <div className={`${styles.unit_card} ${styles.un_finished}`}>
                        <div className={styles.card_body}>
                          <div className="row">
                            <div className={styles.details + " col-9"}>
                              <p>{unit.name}</p>
                              <p><span>{unit.name}</span>/<span>{t(unit.category)}</span></p>
                            </div>
                            <div className={styles.date + " col-3 p-0"}>
                              <p><span>{t(unit.type)}</span></p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.btn_container}>
                          {!deleteAble && <button className={styles.card_edit_btn} onClick={() => onClick_editUnit(unit.id)}><span>{t('edit')}</span><Icons.BiEdit /></button>}
                          {deleteAble && <input className={styles.delete_selector} onClick={() => { toggleDeletedRoom(unit.id) }} type="checkbox" name="delete_selector" />}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.doneContainer + " col-12 col-md-6 col-lg-4"}>
              <div className={styles.draft_step}>
                <div className={`${styles.step_head} ${styles.finished}`}>
                  <h5>{t('finished')}</h5>
                  <p>{myUnits?.data?.getMyUnits?.filter(unit => { return unit.isCalculatable }).length}</p>
                </div>
                <div className={styles.step_body}>
                  {myUnits?.data?.getMyUnits?.filter(unit => { return unit.isCalculatable }).map(unit => {
                    return (
                      <div className={`${styles.unit_card} ${styles.finished}`}>
                        <div className={styles.card_body}>
                          <div className="row">
                            <div className={styles.details + " col-9"}>
                              <p>{unit.name}</p>
                              <p><span>{unit.name}</span>/<span>{t(unit.type)}</span>/<span>{t(unit.category)}</span></p>
                              <p>{unit.Price}</p>
                            </div>
                            <div className={styles.date + " col-3 p-0"}>
                              <p>{viewDate(unit.PriceUpdatedAt)}</p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.btn_container}>
                          {!deleteAble &&
                            <>
                              <button className={styles.card_update_btn} onClick={() => onClick_updatePriceUnit(unit.id)}><span>{t('update')}</span></button>
                              <button className={styles.card_edit_btn} onClick={() => onClick_editUnit(unit.id)}><Icons.BiEdit /></button>
                            </>
                          }
                          {deleteAble && <input className={styles.delete_selector} onClick={() => { toggleDeletedRoom(unit.id) }} type="checkbox" name="delete_selector" />}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {myUnits?.data?.getMyUnits.length == 0 &&
              <div className={`${styles.emptyDraft} col-12`}>
                <img src={Images.draftEmpty} alt="" />
                <p>{t('emptyDraft')}</p>
                <p>{t('noUnits')}</p>
              </div>
            }

            {/* {RoomList?.data?.getUnitsWithinUnits?.map((item, index) => {
              return (
                <div className='col-12 col-md-6 col-lg-4'>

                </div>
              );
            })} */}
          </div>
        </div>
      </div>
      {/* <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <p>{t('roomsFooterQoute')}</p>
            </div>
            <div className={styles.mid + ' col-12 col-lg-5'}>

            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
};

UnitsDraft.propTypes = {};

UnitsDraft.defaultProps = {};

export default UnitsDraft;
