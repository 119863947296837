import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import styles from './UnitRooms.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { Modal, Button } from 'react-bootstrap';
import { t } from 'i18next';
import { selectLang } from '../../../Reducer/LangSlice'
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_ROOM_NAMES,
  GET_ROOMS_OF_THIS_UNIT,
  GET_UNIT_PRRICE
} from '../../../Graphql/UnitForm/Queries';
import {
  DELETE_ROOM,
  ADD_ROOM
} from '../../../Graphql/UnitForm/mutations';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  selectUnit,
  addList,
  markChoiceFromSelect,
  addNewRoom,
  setUnitRoomData,
} from '../../../Reducer/UnitSlice';
import { CustomInput } from '../../../components';
import { toast } from 'react-toastify';
import {
  selectUser
} from '../../../Reducer/UserSLice';
import { MustLoginModal } from '../../../components';
import { LoadingSpinner } from '../../../Pages';
import { OgeeButton } from '../../../components';

const UnitRooms = () => {
  let { unitId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var unit = useSelector(selectUnit);
  let lang = useSelector(selectLang);
  var user = useSelector(selectUser);
  let [getRoomNames, RoomNames] = useLazyQuery(GET_ROOM_NAMES);
  let [getRoomList, RoomList] = useLazyQuery(GET_ROOMS_OF_THIS_UNIT);
  const [deleteRoom, deleteRoomResult] = useMutation(DELETE_ROOM);
  const [deleteAble, setDeleteAble] = useState(false);
  const [deleteRooms, setDeleteRooms] = useState([]);
  const [getUnitPrice, unitPrice] = useLazyQuery(GET_UNIT_PRRICE);
  const [addRoom, roomInfo] = useMutation(ADD_ROOM);
  const [show, setShow] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [indexDuplicateRoom, setIndexDuplicateRoom] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [newRoomName, setNewRoomName] = useState();
  const onClickRoomType = (index) => {
    dispatch(markChoiceFromSelect({ name: 'roomNameList', choiceIndex: index }));
    setNewRoomName(unit.roomNameList[index]);
  }
  const selectColors = (id, index) => {
    var roomColor;
    switch (+id) {
      case 1:
        roomColor = '#7FB2D8';
        break;
      case 9:
        roomColor = '#BBAB7A';
        break;
      case 6:
        roomColor = '#88ACD8';
        break;
      case 5:
        roomColor = '#848CD8';
        break;
      case 4:
        roomColor = '#048971';
        break;
      case 3:
        roomColor = '#AAC699';
        break;
      case 2:
        roomColor = '#93A09E';
        break;
      case 8:
        roomColor = '#E17A77';
        break;
      case 1:
        roomColor = '#D8A33F';
        break;
      case 7:
        roomColor = '#99B9C0';
        break;
      case 1:
        roomColor = '#5A5955';
        break;
      default:
        roomColor = '#048971';
        break;
    }
    // if (unit.rooms) {
    //   if (unit.rooms[index]) {
    //     if (!unit?.rooms[index]?.roomFk?.wall_color) {
    //       dispatch(setUnitRoomData({ param: { name: 'wall_color', value: roomColor }, index: index }));
    //     }
    //   }
    // }
    return roomColor;
  };
  const toggleDeletedRoom = (value) => {
    if (deleteRooms.includes(value)) {
      setDeleteRooms((current) => current.filter((roomId) => roomId !== value));
    } else {
      setDeleteRooms(current => [...current, value])
    }
    // console.log(deleteRooms);
  }
  const isRoomChecked = () => {
    var result = false;
    unit.roomNameList.map(roomName => {
      if (roomName.checked)
        result = true;
    })
    return result;
  }
  const onClickAddNewRoom = () => {
    let newRoomIndex = unit?.rooms?.length;
    if(!isRoomChecked()){
      toast.warn(t('selectRoomType'))
      return;
    }
    if (!newRoomName) {
      unit.roomNameList.filter(item => {
        if (item.checked)
          setNewRoomName(item);
      }).then(() => {
        dispatch(addNewRoom({ roomName: newRoomName }));
        navigate(`/UnitForm/roomForm/${newRoomIndex}`);
      })
    } else {
      dispatch(addNewRoom({ roomName: newRoomName }));
      navigate(`/UnitForm/roomForm/${newRoomIndex}`);
    }
  }
  const navigateToRoomEdit = (roomId) => {
    navigate(`/UnitForm/roomForm/${roomId}/update`);
  }
  const saveToAndRedirectToDraft = () => {
    //TODO save
    navigate(`/UnitDraft`);
  }
  const onClick_deleteSelectedRooms = () => {
    deleteRoom({
      variables: {
        roomIds: deleteRooms,
        userID: +user.user.userId
      }
    }).then(result => {
      if (result.data.Delete_Rooms.done) {
        RoomList.refetch({ unitID: unit.info.id, userID: 12 });
        setDeleteAble(!deleteAble)
      }
    })
  }
  const saveAndGoToPricePage = () => {
    if (unitId) {
      // getUnitPrice.refetch({ userId: 12, unitId: unitId });
      getUnitPrice({
        variables: { userId: 12, unitId: unitId },
      }).then(result => {
        if (result.data.show_price.calculatable) {
          navigate(`/UnitForm/cost/${unitId}`);
        } else {
          toast.info(t('completeUnitData'), { autoClose: 1000 });
        }
      });
    } else {
      // getUnitPrice.refetch({ userId: 12, unitId: unit.info.id });
      getUnitPrice({
        variables: { userId: 12, unitId: unit.info.id },
      }).then(result => {
        if (result.data.show_price.calculatable) {
          navigate(`/UnitForm/cost/${unit.info.id}`);
        } else {
          toast.info("unit not ready yet!!", { autoClose: 1000 });
        }
      });
    }
  }
  const defualtRoomName = (room) => {
    if (room.name == null) {
      if (lang.name == 'AR') {
        return room.room_names_Fk.arabic_name;
      }
      else {
        return room.room_names_Fk.english_name;
      }
    } else {
      return room.name;
    }
  }
  const getRoomArea = (item) => {
    // console.log(item);
    let area;
    if (item?.roomFk?.width) {
      area = item?.roomFk?.width * item?.roomFk?.length
    }
    if (item?.roomFk?.one) {
      let third = item?.roomFk?.two - item?.roomFk?.four
      let fourth = item?.roomFk?.one - item?.roomFk?.three
      area = (item?.roomFk?.one * item?.roomFk?.four) + (third * fourth)
    }
    if (!area){
      area = 0
    }
    area = area.toFixed(2);
    return lang.name === 'AR' ? area.toLocaleString('ar-EG') : area;
  }
  const DuplicateRoomAskConfirmation = (index) => {
    setIndexDuplicateRoom(index);
    setShowDuplicateModal(true);
  }
  const duplicate_room = () => {
    // console.log(indexDuplicateRoom);
    // console.log(unit.rooms);

    let doors = [];
    let windows = [];
    let room = unit.rooms[indexDuplicateRoom].roomFk;
    if (room?._count.doors >= 1) {
      if (!room.doors[0]?.height || !room.doors[0]?.width || !room.doors[0]?.doorMaterial) {
      } else {
        doors = {
          decorationID: +room.doors[0].doorMaterial.id,
          width: +room.doors[0].width,
          height: +room.doors[0].height,
          doors_count: +room._count.doors,
        }
      }
    }
    if (room?._count.windows >= 1) {
      if (!room.windows[0]?.height || !room.windows[0]?.width || !room.windows[0]?.windowMaterial) {
      } else {
        windows = {
          decorationID: +room.windows[0].windowMaterial.id,
          decorationAddonID: room?.windows[0]?.windowAddonMaterial?.id ? +room.windows[0].windowAddonMaterial.id : -1,
          width: +room.windows[0].width,
          height: +room.windows[0].height,
          windows_count: +room._count.windows,
        }
      }
    }
    addRoom({
      variables: {
        userId: +user.user.userId,
        unitId: +unit.info.id,
        utilities: !!room.UtilityFactors ? room.UtilityFactors : [],
        name: '',
        aircon: !!room.isAirConditioning,
        width: room.width === '' ? null : +room.width,
        length: room.length === '' ? null : +room.length,
        height: room.height === '' ? null : +room.height,
        one: room.one === '' ? null : +room.one,
        two: room.two === '' ? null : +room.two,
        three: room.three === '' ? null : +room.three,
        four: room.four === '' ? null : +room.four,
        wallcolor: room.wall_color,
        roomId: room.room_names_Fk.id,
        costId: !room.finishing_cost_Fk?.id ? -1 : +room.finishing_cost_Fk?.id,
        styleId: !room.finishing_style_Fk?.id ? -1 : +room.finishing_style_Fk?.id,
        wallMatID: !room.wallMaterial?.id ? -1 : +room.wallMaterial?.id,
        wallAddonId: !room.wallMaterialAddon1?.id ? -1 : +room.wallMaterialAddon1?.id,
        wallAddon2: !room.wallMaterialAddon2?.id ? -1 : +room.wallMaterialAddon2?.id,
        cielingMatID: !room.cielingMaterial?.id ? -1 : + room.cielingMaterial?.id,
        cielingAddon: !room.cielingMaterialAddon1?.id ? -1 : +room.cielingMaterialAddon1?.id,
        cielingAddon2: !room.cielingMaterialAddon2?.id ? -1 : +room.cielingMaterialAddon2?.id,
        floorMatID: !room.floorMaterial?.id ? -1 : +room.floorMaterial?.id,
        doors: doors,
        windows: windows
      },
    }).then(results => {
      if (results.data.add_room) {
        RoomList.refetch({ unitID: unitId, userID: +user.user.userId });
        if (unitId) {
          RoomList.refetch({ unitID: unitId, userID: +user.user.userId });
          getRoomList({ variables: { unitID: unitId, userID: +user.user.userId } })
        } else {
          RoomList.refetch({ unitID: unit.info.id, userID: +user.user.userId });
          getRoomList({ variables: { unitID: unit.info.id, userID: +user.user.userId } })
        }
        setShowDuplicateModal(false);
      } else {
        console.error(results.error);
      }
    });
  }
  useEffect(() => {
    if (unitId) {
      RoomList.refetch({ unitID: unitId, userID: +user.user.userId });
      getRoomList({ variables: { unitID: unitId, userID: +user.user.userId } })
    } else {
      RoomList.refetch({ unitID: unit.info.id, userID: +user.user.userId });
      getRoomList({ variables: { unitID: unit.info.id, userID: +user.user.userId } })
    }
  }, [])
  useEffect(() => {
    dispatch(addList({ name: 'rooms', value: RoomList?.data?.getRoomsWithinUnits }));
  }, [RoomList.data])
  useEffect(() => {
    if (unitId) {
      getRoomList({ variables: { unitID: unitId, userID: +user.user.userId } }).then(result => {
        dispatch(addList({ name: 'rooms', value: result?.data?.getRoomsWithinUnits }));
      })
    } else {
      getRoomList({ variables: { unitID: unit.info.id, userID: +user.user.userId } }).then(result => {
        dispatch(addList({ name: 'rooms', value: result?.data?.getRoomsWithinUnits }));
      })
    }
    getRoomNames().then(result => {
      dispatch(addList({ name: 'roomNameList', value: result?.data?.getRoomNames }));
    })
  }, [unit.info.id]);

  useEffect(() => { }, [deleteRooms])
  if (!user.user.userId) {
    return (
      <div className={styles.UnitRooms}>
        <MustLoginModal />
      </div>)
  }
  if (RoomList.loading) return (
    <div className={styles.UnitRooms}>
      <LoadingSpinner />
    </div>
  );
  if (RoomList.error) return `Submission error! of roomList ${RoomList.error.message}`;
  if (RoomNames.loading) return (
    <div className={styles.UnitRooms}>
      <LoadingSpinner />
    </div>
  );
  if (RoomNames.error) return `Submission error! of roomList ${RoomNames.error.message}`;
  return (
    <div className={styles.UnitRooms}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-3'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')} />
              <Link to='/UnitForm'>{t('UnitForm')}</Link>
              <span> \ </span>
              <Link to='/UnitForm/rooms'>{t('rooms')}</Link>
            </div>
            <div className={styles.mid + ' col-6'}>
              <Link className='btn' onClick={handleShow} ><Icons.MdAddBox /><span>{t('addRoom')}</span></Link>
            </div>
            <div className={styles.end + ' col-3'}>
              {!deleteAble &&
                <div>
                  {/* <button><span>{t('edit')}</span><Icons.BiEdit /></button> */}
                  <button><Icons.RiDeleteBin6Line onClick={() => setDeleteAble(!deleteAble)} /></button>
                </div>
              }
              {deleteAble &&
                <div className={styles.deleteActionContainer}>
                  <button className={` ${styles.deleteAction_ok}`} onClick={() => onClick_deleteSelectedRooms()}><span>{t('deleteOK')}</span> <Icons.RiDeleteBin6Line /></button>
                  <button className={` ${styles.deleteAction_cancel}`} onClick={() => setDeleteAble(!deleteAble)} ><span>{t('deleteCancel')}</span> <span>X</span></button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className="row">
            {RoomList?.data?.getRoomsWithinUnits?.map((item, index) => {
              return (
                <div className='col-12 col-md-6 col-lg-4'>
                  <div className={styles.roomCard_container}>
                    <div
                      className={styles.roomCard}
                      key={item?.roomFk?.id}
                      style={{
                        backgroundImage: `url(${Images.roomImage})`,
                        'background-color': `${selectColors(item?.roomFk?.room_names_Fk?.id, index)}`,
                        backgroundRepeat: 'no-repeat',
                        // 'background-attachment': 'fixed',
                        backgroundPosition: 'inherit',
                        backgroundSize: '50% 100%',
                      }}
                    >
                      {/* <img src={item?.roomFk.roomImage} alt="" /> */}
                      <div className={styles.cardBody} onClick={() => navigateToRoomEdit(index)} style={{
                        'background': `linear-gradient(90deg, ${selectColors(item?.roomFk?.room_names_Fk?.id, index)}00 0%,${selectColors(item?.roomFk?.room_names_Fk?.id, index)} 50%, ${selectColors(item?.roomFk?.room_names_Fk?.id, index)} 100%)`,
                        'color': `${item?.roomFk?.wall_color?.toLocaleLowerCase() == '#ffffff' ? '#000' : '#fff'}`,
                      }}>
                        <div className={styles.title_container + " d-flex"}>
                          <div className={styles.title + ' d-flex'}>
                            <div className={styles.title_field}>
                              {/* <CustomInput
                                value={item?.roomFk?.name}
                                readOnly={true}
                                inlineStyle={{ 'width': `${item?.roomFk?.name?.length * 10}px` }}
                                onChange={(e) => onChangeRoomName(e.target.value)}
                              /> */}
                              <p>{defualtRoomName(item?.roomFk)}</p>
                            </div>
                            <Icons.RiEditLine />
                          </div>
                        </div>
                        {(item?.roomFk?.finishing_style_Fk?.id && item?.roomFk?.finishing_cost_Fk?.id) &&
                          <div className={styles.description}>
                            <span>
                              {lang.name === 'AR' ? item?.roomFk?.room_names_Fk?.arabic_name : item?.roomFk?.room_names_Fk?.english_name}
                            </span>
                            <span> </span>
                            <span>
                              {lang.name === 'AR' ? item?.roomFk?.finishing_style_Fk?.arabic_name : item?.roomFk?.finishing_style_Fk?.english_name}
                            </span>
                            <span> / </span>
                            <span>
                              {lang.name === 'AR' ? item?.roomFk?.finishing_cost_Fk?.arabic_name : item?.roomFk?.finishing_cost_Fk?.english_name}
                            </span>
                            <span> / </span>
                            <span>
                              {`${getRoomArea(item)}${t('area')}`}
                            </span>
                          </div>}
                      </div>
                      {(!item?.roomFk?.isCalculatable && !deleteAble) &&
                        <div className={styles.inComplete}>
                          {t('inComplete')}
                        </div>
                      }
                      <div className={styles.actions}>
                        {/* <Icons.IoExpand /> */}
                        <Icons.MdAddToPhotos onClick={() => DuplicateRoomAskConfirmation(index)} style={{
                          'color': `${item?.roomFk?.wall_color?.toLocaleLowerCase() == '#ffffff' ? '#000' : '#fff'}`
                        }} />
                      </div>
                      {(deleteAble && item?.roomFk?.isDeleteable) &&
                        <div className={styles.deleteRoomContainer} onClick={() => toggleDeletedRoom(item?.roomFk?.id)}>
                          <input type="checkbox" checked={deleteRooms.includes(item?.roomFk?.id)} onClick={() => toggleDeletedRoom(item?.roomFk?.id)} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Modal id={"addRoomModal"} show={show} onHide={handleClose} centered lang={lang.name.toLocaleLowerCase()}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {unit?.roomNameList?.map((roomType, index) => {
              return (
                <div
                  key={'roomName' + index}
                  className={`roomName ${roomType.checked ? 'activeRoomName' : ''} pointer`}
                  onClick={() => onClickRoomType(index)}>
                  <div >
                    <Icons.FiCheck />
                    <p>{lang.name === 'AR' ? roomType.arabic_name : roomType.english_name}</p>
                  </div>
                </div>
              )
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button className='modalButton' onClick={onClickAddNewRoom}>
              <span>{t('add')}</span>
              <Icons.MdAddBox />
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal id={"duplicateConfimationModal"} show={showDuplicateModal} onHide={() => setShowDuplicateModal(false)} centered lang={lang.name.toLocaleLowerCase()}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Icons.MdAddToPhotos />
            <p>{t('areUsure')}</p>
            <p>{t('areUsurefromDuplicate')}</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="row w-100 mt-2 mb-2">
              <div className="col-6">
                <OgeeButton className='modalButton' onClick={() => duplicate_room()}>
                  <span>{t('deleteOK')}</span>
                </OgeeButton>
              </div>
              <div className="col-6">
                <Button className='modalButton ' onClick={() => setShowDuplicateModal(false)}>
                  <span>{t('deleteCancel')}</span>
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              {/* <p>{t('roomsFooterQoute')}</p> */}
            </div>
            <div className={styles.mid + ' col-12 col-lg-5'}>
              <button className='btn' onClick={() => saveToAndRedirectToDraft()} ><Icons.AiOutlineArrowRight /> <span>{t('saveToDraft')}</span></button>
              <button className='btn' onClick={() => saveAndGoToPricePage()} ><span>{t('viewPrice')}</span><Icons.AiOutlineArrowLeft /></button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
UnitRooms.propTypes = {};

UnitRooms.defaultProps = {};

export default UnitRooms;
