import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './NavBar.module.scss';
import { Link } from 'react-router-dom'
import * as images from '../../Assets/images';
import * as Icons from '../../Assets/icons';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { TbBell } from 'react-icons/tb'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUser,
  logInOut
} from '../../Reducer/UserSLice';
import {
  selectLang,
  toggleLang
} from '../../Reducer/LangSlice';
import { useNavigate } from 'react-router-dom';
import {
  Notifications
} from '../../Graphql/Home/queries';
import {
  DelAllNotif,
  DelNotif
} from '../../Graphql/Home/mutations';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { socialUrls } from '../../ENV';

const NavBar = (props) => {
  var user = useSelector(selectUser);
  var lang = useSelector(selectLang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showUserList, setShowUserList] = useState(false);
  const [sideMenuToggle, setSideMenuToggle] = useState(false);
  const [sideSubMenuToggle, setSideSubMenuToggle] = useState(false);
  const [showNoftification, toggleNoftification] = useState(false);
  const toggleShowUserList = () => setShowUserList(!showUserList);
  const [getNotification, notificationResp] = useLazyQuery(Notifications, {
    variables: {
      user: +user.user.userId
    }
  });
  const [deleteAllNotification, deleteAllNotificationResp] = useMutation(DelAllNotif);
  const [deleteNotification, deleteNotificationResp] = useMutation(DelNotif);

  const onClick_logOut = () => {
    dispatch(logInOut());
    navigateTo('');
  }
  const navigateTo = (url) => {
    navigate(`/${url}`);
    toggleShowUserList();
  }
  const onClick_toggleLang = () => {
    dispatch(toggleLang());
  }
  const deleteNotifcation = (id) => {
    deleteNotification({
      variables: {
        Uid: +user.user.userId,
        Nid: +id
      }
    })
  }
  const deleteAllNotifcation = () => {
    deleteAllNotification({
      variables: {
        Uid: +user.user.userId
      }
    })
  }
  const onClick_setSideMenuToggle = () => {
    var nextValueFlag = !sideMenuToggle;
    setSideMenuToggle(nextValueFlag)
    if (nextValueFlag) {
      // document.getElementById("ojee-web").style.overflow = "hidden";
    } else {
      // document.getElementById("ojee-web").style.overflow = "scroll";
    }
  }
  const toogleNotif = () => {
    if (!showNoftification) {
      getNotification({
        variables: {
          user: +user.user.userId
        }
      });
      notificationResp.refetch({
        variables: {
          user: +user.user.userId
        }
      });
      
    }
    toggleNoftification(!showNoftification);
  }
  return (
    <div className={styles.containerFluid + " container w-100"} style={{ backgroundColor: props.color }}>
      <div className="row w-100">
        <nav className={styles.navRight + " col-6 col-md-3 col-lg-5 col-xl-5"}>
          <ul className={styles.ulRight}>
            <li>  <Link to='/'>{t('MianPage')}</Link> </li>
            <li>  <Link to='/MyFavoriteRooms'>{t('favPage')}</Link>       </li>
            <li>  <Link to='/Packages'>{t('packagesPage')}</Link>    </li>
            <li>  <Link to='/UnitsDraft'>{t('draftPage')}</Link>     </li>
            {/* <li>  <Link to='/blog'>المدونة</Link>      </li> */}
            <NavDropdown title={t('more')} className='customDr'>
              <span>
                <NavDropdown.Item><Link to='/About'>{t('aboutOjee')}</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/Questions'>{t('FAQ')}</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/ApplyingFinishingCompanies'>{t('applyFinishingCompany')}</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/ApplyServices'>{t('materialsAndProducts')}</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/Complain'>{t('applycomplains')}</Link></NavDropdown.Item>
                {/* <NavDropdown.Item><Link to='/RatingModel'>{t('urRate')}</Link></NavDropdown.Item> */}
                {!user.isLoggedIn &&
                  <NavDropdown.Item><Link to='/Login'>{t('signIn')}</Link></NavDropdown.Item>
                }
              </span>
            </NavDropdown>
          </ul>
        </nav>
        <div className={`${styles.iPad_menu} ${styles.mobile_menu} col-2 col-md-3 col-lg-3 ${sideMenuToggle ? styles.active : ''}`}>
          <div className={styles.hamIcon} onClick={() => onClick_setSideMenuToggle()}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={styles.menu}>
            <div className={styles.hamIcon} onClick={() => onClick_setSideMenuToggle()}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={styles.userMobile_dropdownContainer}>
              <div className={styles.header} >
                {/* <Icons.FaChevronDown /> */}
                {!user.isLoggedIn ?
                  <h2 onClick={() => navigateTo('Login')}>{t('guest_name')}</h2>
                  :
                  <h2 onClick={() => navigateTo('UserProfile')} >{user.user.username}</h2>
                }
              </div>
            </div>
            <div className={styles.Links_container}>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.BiHomeAlt2 />
                <Link to='/'>{t('MianPage')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.AiOutlineHeart />
                <Link to='/MyFavoriteRooms'>{t('favPage')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.TfiPackage />
                <Link to='/Packages'>{t('packagesPage')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.RiDraftLine />
                <Link to='/UnitsDraft'>{t('draftPage')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.BsInfoCircle />
                <Link to='/About'>{t('aboutOjee')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.BsQuestionCircle />
                <Link to='/Questions'>{t('FAQ')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.RiFilePaperLine />
                <Link to='/ApplyingFinishingCompanies'>{t('applyFinishingCompany')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.RiFilePaperLine />
                <Link to='/ApplyServices'>{t('materialsAndProducts')}</Link>
              </div>
              <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                <Icons.VscReport />
                <Link to='/Complain'>{t('applycomplains')}</Link>
              </div>
              {!user.isLoggedIn &&
                <div className={styles.Link} onClick={() => setSideMenuToggle(!sideMenuToggle)}>
                  <Icons.CiLogin />
                  <Link to='/Login'>{t('signIn')}</Link>
                </div>
              }
              {user.isLoggedIn &&
                <div className={`${styles.Link}`} onClick={() => onClick_logOut()}>
                  <Icons.RxExit />
                  <span>{t('logOut')}</span>
                </div>
              }
            </div>
            <div className={styles.menu_footer}>
              <div className={styles.lang}>
                <button className={`btn ${lang.name === 'AR' ? styles.active_lang_btn : ''}`} onClick={onClick_toggleLang}><Icons.MdLanguage /> <span>{t('arabic')}</span></button>
                <button className={`btn ${lang.name === 'EN' ? styles.active_lang_btn : ''}`} onClick={onClick_toggleLang}><Icons.MdLanguage /> <span>{t('english')}</span></button>
              </div>
              <div className={styles.icons + ' row'}>
                <div className='col-auto p-0'><Link to={socialUrls.youtube} target="_blank"><img src={Icons.youtubeIcon} alt="youtube" /></Link></div>
                <div className='col-auto p-0'><Link to={socialUrls.instagram} target="_blank"><img src={Icons.instgramIcon} alt="instgram" /></Link></div>
                <div className='col-auto p-0'><Link to={socialUrls.linkedIn} target="_blank"><img src={Icons.linkedInIcon} alt="linkedin" /></Link></div>
                <div className='col-auto p-0'><Link to={socialUrls.tiktok} target="_blank"><img src={Icons.tiktokIcon} alt="tiktok" /></Link></div>
                <div className='col-auto p-0'><Link to={socialUrls.facebook} target="_blank"><img src={Icons.facebookIcon} alt="facebook" /></Link></div>
              </div>
              <div className={styles.copy_right}>
                <p>{t('right')}</p>
              </div>
            </div>
            {/* <div className={`${styles.subMenu} ${sideSubMenuToggle ? styles.active : ''}`}>
              <div className={styles.headMenu} onClick={() => setSideSubMenuToggle(!sideSubMenuToggle)}>
                <span>{t('more')}</span>
              </div>
              <div className={`${styles.bodyMenu}`}>
                
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.NavLogo + " col-8 col-md-3 col-lg-3 col-xl-2"}><Link to='/'>
          <img src={images.Navlogo} alt='' /> </Link>
        </div>
        <div className={styles.navLeft + " col-2 col-md-6 col-lg-4 col-xl-5"}>
          <ul className={styles.ulLeft}>
            <li onClick={() => onClick_toggleLang()} className={styles.lang_desktop + ' pointer'}><span>{lang.name === 'AR' ? 'EN' : 'AR'}</span> <Icons.MdLanguage /> </li>
            <li>
              <div className={styles.notification_container}>
                <TbBell className='pointer' size={21} onClick={() => toogleNotif()} />
                {showNoftification &&
                  <div className={styles.notification_body}>
                    <div className={styles.header}>
                      <div className={styles.up_arrow_ext + " arrow up"}></div>
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <Icons.BiEdit />
                        </div>
                        <div className="col-auto">
                          <p>{t('notifications')}</p>
                        </div>
                        <div className="col-auto">
                          <Icons.RiDeleteBin6Line className='pointer' onClick={deleteAllNotifcation} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.body}>
                      {notificationResp?.data?.getNotifications?.length == 0 ?
                        <img src={lang.name === 'AR' ? images.emptyNotifications : images.emptyNotifications_en} alt="empty Notifications" /> :
                        <div className={styles.notificationList_container}>
                          {
                            notificationResp?.data?.getNotifications.map(notification => {
                              return (
                                <div className={styles.notification}>
                                  <div>
                                    <p>{notification.title}</p>
                                    <p>{notification.body}</p>
                                  </div>
                                  <Icons.RiDeleteBin6Line className='pointer' onClick={() => deleteNotifcation(notification.id)} />
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            </li>
            <li>
              <div className={styles.userDropDownContainer}>
                <div className={styles.header} onClick={() => toggleShowUserList()}>
                  <Icons.FaChevronDown />
                  {!user.isLoggedIn ?
                    <h2>{t('guest')}</h2>
                    :
                    <h2>{user.user.username}</h2>
                  }
                </div>
                {showUserList &&
                  <div className={styles.body}>
                    {!user.isLoggedIn &&
                      <>
                        <div className={styles.Item} onClick={() => navigateTo('Login')}>
                          <span>{t('guest')}</span>
                        </div>
                        <div className={`${styles.Item} ${styles.reverse}`} onClick={() => navigateTo('Signup')}>
                          <Icons.IoMdAddCircleOutline />
                          <span>{t('createAccout')}</span>
                        </div>
                      </>
                    }
                    {user.isLoggedIn &&
                      <>
                        <div className={styles.Item} onClick={() => navigateTo('UserProfile')}>
                          <div className="flex-between">
                            <Icons.FiEdit />
                            <span>{user.user.username}</span></div>
                        </div>
                        <div className={`${styles.Item} ${styles.reverse}`} onClick={() => onClick_logOut()}>
                          <Icons.RxExit />
                          <span>{t('logOut')}</span>
                        </div>
                      </>
                    }
                  </div>
                }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  );
};

NavBar.propTypes = {};
NavBar.defaultProps = {};
export default NavBar;
