import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Rooms.module.scss';
import Pagination from 'react-bootstrap/Pagination';
import 'react-multi-carousel/lib/styles.css';
import { RoomCard, FilterBar } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/client';
import {
  GET_SLIDER,
  GET_GALLARY_GROUPED_BY_USER_ID,
  GET_GALLARY
} from '../../Graphql/Home/queries';
import { MainURL } from '../../ENV';
import { selectLang } from '../../Reducer/LangSlice';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from "react-i18next";
import {
  selectHome,
  addList
} from '../../Reducer/HomeSlice';
import { MustLoginModal } from '../../components';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { LoadingSpinner } from '../../Pages';
import * as Images from '../../Assets/images';

const Rooms = () => {
  const [getgallrayRooms, gallrayRooms] = useLazyQuery(GET_GALLARY_GROUPED_BY_USER_ID);
  const [getgallrayRooms_guest, gallrayRooms_guest] = useLazyQuery(GET_GALLARY);
  // const gallrayRooms = useQuery(GET_GALLARY);
  const sliderResp = useQuery(GET_SLIDER);
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [myRooms, setMyRooms] = useState([]);
  const [resultedRoomsLenght, setResultedRoomsLenght] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  var lang = useSelector(selectLang);
  var user = useSelector(selectUser);
  var home = useSelector(selectHome);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const getRoomImageURL = (image) => {
    var fullURL = MainURL + image;
    return [fullURL];
  }
  const getRoomArea = (item) => {
    let area;
    if (item?.width) {
      area = item?.width * item?.length
    }
    if (item?.one) {
      let third = item?.two - item?.four
      let fourth = item?.one - item?.three
      area = (item?.one * item?.four) + (third * fourth)
    }
    if (!area){
      area = 0
    }
    area = area.toFixed(2);
    return lang.name === 'AR' ? area.toLocaleString('ar-EG') : area;
  }
  const roomWithinRange = (i) => {
    return i >= (page * 9) && i < ((page * 9) + 9);
  }
  const setRoomAvalPages = (lenght) => {
    var pageLength = Math.ceil(lenght / 9);
    var pageList = [];
    for (let index = 0; index < pageLength; index++) {
      pageList.push(index + 1);
    }
    setPages(pageList);
  }
  const filter_byFinishingCost = (room) => {
    var roomFinishingCost = room.finishing_cost_Fk.id;
    var viewRoomFlag = false;
    home.costList.forEach(FC => {
      if (FC.id == roomFinishingCost && FC.isActive) {
        viewRoomFlag = true;
        return;
      }
    });
    return viewRoomFlag;
  }
  const filter_byStyle = (room) => {
    var roomstyle = room.finishing_style_Fk.id;
    var viewRoomFlag = false;
    home.styleList.forEach(FS => {
      if (FS.id == roomstyle && FS.isActive) {
        viewRoomFlag = true;
        return;
      }
    });
    return viewRoomFlag;
  }
  const filter_byRoomName = (room) => {
    var roomName = room.room_names_Fk.id;
    var viewRoomFlag = false;
    home.roomsNameList.forEach(RN => {
      if (RN.id == roomName && RN.isActive) {
        viewRoomFlag = true;
        return;
      }
    });
    return viewRoomFlag;
  }
  const getRoomsFiltered = () => {
    var Rooms = home?.rooms?.filter((ele, i) => {
      return (filter_byRoomName(ele) ||
        filter_byStyle(ele) ||
        filter_byFinishingCost(ele))
    });
    setMyRooms(Rooms?.filter((ele, i) => {
      return (roomWithinRange(i))
    }));
    setRoomAvalPages(Rooms?.length)
    setResultedRoomsLenght(Rooms?.length);
    if (resultedRoomsLenght != Rooms?.length) {
      setPage(0);
    }
  }
  const onClick_setCurrentPage = (pageItem) => {
    setPage(pageItem - 1);
  }
  const onClick_nextPagination = () => {
    var currentPage = page;
    var nextPage = currentPage + 1;
    if (pages.includes(nextPage + 1)) {
      setPage(nextPage);
    }

  }
  const onClick_prevPagination = () => {
    var currentPage = page;
    var nextPage = currentPage - 1;
    if (pages.includes(nextPage + 1)) {
      setPage(nextPage);
    }
  }
  const isLiked = (isLiked) => {
    if (user.user.userId) {
      // console.log(isLiked);
      return isLiked.length === 0 ? false : true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    var arrayRooms = []
    gallrayRooms?.data?.getGalleryRoomsGroupedByUserID.forEach(roomArray => {
      Array.prototype.push.apply(arrayRooms, roomArray.rooms);
    });
    dispatch(addList({ name: "rooms", value: arrayRooms }));

  }, [gallrayRooms.data]);
  useEffect(() => {
    dispatch(addList({ name: "rooms", value: gallrayRooms_guest?.data?.getGalleryRooms }));
  }, [gallrayRooms_guest.data]);
  useEffect(() => {
    getRoomsFiltered();
  }, [home.rooms])
  useEffect(() => {
    getRoomsFiltered();
  }, [page])
  useEffect(() => {
    if (user.user.userId) {
      gallrayRooms.refetch({ userId: +user.user.userId });
      getgallrayRooms({ variables: { userID: +user.user.userId } })
    } else {
      gallrayRooms_guest.refetch({ userId: +user.user.userId });
      getgallrayRooms_guest({ variables: { userID: +user.user.userId } })
    }
  }, [user.user.userId])
  if (sliderResp?.loading) return (
    <div className={styles.Rooms}>
      <LoadingSpinner />
    </div>
  );
  if (sliderResp?.error) return `Submission error! of sliderResp ${sliderResp.error.message}`;
  // if (!user.user.userId) {
  //   return (
  //     <div className={styles.Rooms}>
  //       <MustLoginModal />
  //     </div>);
  // }
  return (
    <div className={styles.Rooms}>
      <section className={styles.carousal}>
        <Carousel activeIndex={index} onSelect={handleSelect} fade>
          {/* {
            sliderResp?.data?.GetSliderImages.map(element => {
              return (
                <Carousel.Item data-roomId={element.id}>
                  <div className={styles.image} style={{
                    backgroundImage: `url(${getRoomImageURL(element.imgurl)})`
                  }}></div>
                </Carousel.Item>
              );
            })
          } */}
          <Carousel.Item data-roomId={1}>
            <div className={styles.image} style={{
              backgroundImage: `url(${Images.slider_gallary_1})`
            }}></div>
          </Carousel.Item>
          <Carousel.Item data-roomId={2}>
            <div className={styles.image} style={{
              backgroundImage: `url(${Images.slider_gallary_2})`
            }}></div>
          </Carousel.Item>
        </Carousel>
      </section>
      <div className={styles.body}>
        <div className="container">
          <section className={styles.head_data}>
            <div className="d-flex justify-content-between">
              <h1>{t('Rooms')}</h1>
              <span>{t('roomsResult', { roomResultLength: resultedRoomsLenght })}</span>
            </div>
          </section>
          <section className={styles.filterContainer} id='filterContainer'>
            <FilterBar isHasSort={false} onClick_AppluFilter={() => getRoomsFiltered()} />
          </section>
          <section className="rooms_container">
            <div className="row">
              {myRooms?.map((room, i) => {
                return (
                  <div className={styles.gallaryItems + " col-12 col-md-6 col-lg-4"}>
                    <RoomCard
                      key={"room__" + room.id}
                      roomDetailsObject={room}
                      roomId={+room.id}
                      roomIndex={i}
                      isLiked={isLiked(room.liked_by)}
                      imagesList={getRoomImageURL(room?.image_url)}
                      roomName={lang.name === 'AR' ? room?.arabic_name : room?.english_name}
                      roomType={lang.name === 'AR' ? room?.room_names_Fk?.arabic_name : room?.room_names_Fk?.english_name}
                      roomDetails={<div className={styles.description}>
                        <span>
                          {lang.name === 'AR' ? room?.room_names_Fk?.arabic_name : room?.room_names_Fk?.english_name}
                        </span>
                        <span> </span>
                        <span>
                          {lang.name === 'AR' ? room?.finishing_style_Fk?.arabic_name : room?.finishing_style_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {lang.name === 'AR' ? room?.finishing_cost_Fk?.arabic_name : room?.finishing_cost_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {`${getRoomArea(room)}${t('area')}`}
                        </span>
                      </div>}
                      iscarousel={false}
                      viewDetails={false}
                      viewShowMoreLink={false} />
                  </div>
                )
              })}
            </div>
          </section>
          <section className={styles.pagination + " text-center"} dir={"ltr"}>
            <div className={styles.inner_container}>
              <Pagination>
                <Pagination.Prev className={styles.active} onClick={() => onClick_prevPagination()} >&#10094; <span className={styles.d_mobile_none}>{t('prev')}</span></Pagination.Prev>
                {
                  pages.map(pageItem => {
                    return (<Pagination.Item active={pageItem - 1 == page} onClick={() => onClick_setCurrentPage(pageItem)}>{pageItem}</Pagination.Item>);
                  })
                }
                <Pagination.Next className={styles.active} onClick={() => onClick_nextPagination()}><span className={styles.d_mobile_none}>{t('next')}</span>  &#10095;</Pagination.Next>
              </Pagination></div>
          </section>
        </div>
      </div>
    </div>
  )
};

Rooms.propTypes = {};

Rooms.defaultProps = {};

export default Rooms;
