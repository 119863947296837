import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Home.module.scss';
import { Header, FilterBar, GallaryCard, RoomCard, ArticalGroup } from '../../components';
import { useTranslation } from "react-i18next";
import "../../Translation/i18n";
import * as images from '../../Assets/images';
import * as icons from '../../Assets/icons';
import { Link } from 'react-router-dom';
import { resetUnitState, selectUnit } from '../../Reducer/UnitSlice';
import { selectLang } from '../../Reducer/LangSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import {
  GET_GALLARY_GROUPED_BY_FINISHING_STYLE,
  GET_GALLARY_GROUPED_BY_ROOM_NAME,
  GET_ROOM_NAMES,
  GET_FINISHING_COSTS_AND_FINISHING_STYLES
} from '../../Graphql/Home/queries';
import {
  selectHome,
  addList,
  resetHomeStatus,
  toggleFilterItem
} from '../../Reducer/HomeSlice';
import {
  MainURL
} from '../../ENV';
import { LoadingSpinner } from '../../Pages';
const Home = () => {
  const { t } = useTranslation();
  var unit = useSelector(selectUnit);
  var lang = useSelector(selectLang);
  var home = useSelector(selectHome);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showGallary, setShowGallary] = useState(false);
  // const { loading, error, data } = useQuery(GET_DATA, {
  //   variables: { language: 'english' },
  // });
  const [viewMorefRooms, setviewMorefRooms] = useState(false);
  // const [filterBarlist, setFilterBarlis] = useState(false);
  const gallaryByFinishingStyleResp = useQuery(GET_GALLARY_GROUPED_BY_FINISHING_STYLE);
  const gallaryByRoomNameResp = useQuery(GET_GALLARY_GROUPED_BY_ROOM_NAME);
  const roomsNameList = useQuery(GET_ROOM_NAMES);
  const cost_styleList = useQuery(GET_FINISHING_COSTS_AND_FINISHING_STYLES);
  const jumpToReleventDiv = (id) => {
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    releventDiv.scrollIntoView({ behavior: "smooth" });
  }
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const getRoomArea = (item) => {
    let area;
    if (item?.width) {
      area = item?.width * item?.length
    }
    if (item?.one) {
      let third = item?.two - item?.four
      let fourth = item?.one - item?.three
      area = (item?.one * item?.four) + (third * fourth)
    }
    if (!area){
      area = 0
    }
    area = area.toFixed(2);
    return lang.name === 'AR' ? area.toLocaleString('ar-EG') : area;
  }
  const getRoomImageURL = (image) => {
    var fullURL = MainURL + image;
    return [fullURL];
  }
  const redirectToRoomsSearchResult = (id, listName) => {
    dispatch(toggleFilterItem({ name: listName, value: id }));
    navigate("/searchResult");
  }
  useEffect(() => {
    dispatch(resetUnitState());
    dispatch(resetHomeStatus());
  }, []);
  useEffect(() => {
    dispatch(addList({ name: "roomsFilteredByType", value: gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames }));
  }, [gallaryByRoomNameResp.data]);
  useEffect(() => {
    dispatch(addList({ name: "roomsFilteredByStyle", value: gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped }));
  }, [gallaryByFinishingStyleResp.data]);
  useEffect(() => {
    dispatch(addList({ name: "roomsNameList", value: roomsNameList?.data?.getRoomNames }));
  }, [roomsNameList.data]);
  useEffect(() => {
    dispatch(addList({ name: "costList", value: cost_styleList?.data?.getFinishingcostandstyle?.finishing_costs }));
    dispatch(addList({ name: "styleList", value: cost_styleList?.data?.getFinishingcostandstyle?.finishing_styles }));
  }, [cost_styleList.data]);
  if (gallaryByFinishingStyleResp?.loading) return (
    <div className={styles.Home}>
      <LoadingSpinner />
    </div>
  );
  if (gallaryByFinishingStyleResp?.error) return `Submission error! of gallaryByFinishingStyleResp ${gallaryByFinishingStyleResp.error.message}`;
  if (gallaryByRoomNameResp?.loading) return (
    <div className={styles.Home}>
      <LoadingSpinner />
    </div>
  );
  if (gallaryByRoomNameResp?.error) return `Submission error! of gallaryByRoomNameResp ${gallaryByRoomNameResp.error.message}`;
  return (
    <div className={styles.Home}>
      <section className={styles.headerContainer}>
        <Header />
      </section>
      {/* section for rooms */}
      <section className={styles.roomsContainer}>
        <div className=" container">
          <section className={styles.filterContainer} id='filterContainer'>
            <FilterBar isHasSort={false} isShowList={false} />
          </section>
          {/* <div className= {styles.roomCards_inner_container + " row"}>
            <div className={styles.innerItem + " col-6 col-lg-4"}>
              <RoomCard
                images={[]}
                roomId={1}
                roomName={t('roomName')}
                roomDetails={t('roomDetails')}
                roomRate={4.5} />
            </div>
            <div className={styles.innerItem + " col-6 col-lg-4"}>
              <RoomCard
                images={[]}
                roomId={2}
                roomName={t('roomName')}
                roomDetails={t('roomDetails')}
                roomRate={4.5} />
            </div>
            <div className={styles.innerItem + " col-6 col-lg-4"}>
              <RoomCard
                images={[]}
                roomId={3}
                roomName={t('roomName')}
                roomDetails={t('roomDetails')}
                roomRate={4.5} />
            </div>
          </div> */}
        </div>
      </section>
      <section className={styles.mobile_slider}>
        <div className="container">
          <div className={styles.carousal}>
            <Carousel activeIndex={index} fade id="mobile_slider" onSelect={handleSelect}>
              <Carousel.Item data-roomId={1}>
                <div className={styles.image} style={{
                  backgroundImage: `url(${images.slider_gallary_1})`
                }}></div>
              </Carousel.Item>
              <Carousel.Item data-roomId={2}>
                <div className={styles.image} style={{
                  backgroundImage: `url(${images.slider_gallary_2})`
                }}></div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </section>
      {/* section for Gallary */}
      <section className={styles.gallaryContainer}>
        <div className="container">
          <h2>{t('roomGallary')}</h2>
          <div className="row">
            <div className={styles.mainImage + ' col-12 col-lg-6'}>
              <GallaryCard
                directToAction={() => redirectToRoomsSearchResult(+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[0].id, "roomsNameList")}
                roomId={+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0].id}
                imagesList={getRoomImageURL(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0].image_url)}
                roomName={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.english_name}
                roomType={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.room_names_Fk?.english_name}
                roomDetails={<div className={styles.description}>
                  <span>
                    {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.room_names_Fk?.english_name}
                  </span>
                  <span> </span>
                  <span>
                    {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.finishing_style_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.finishing_style_Fk?.english_name}
                  </span>
                  <span> / </span>
                  <span>
                    {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.finishing_cost_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0]?.finishing_cost_Fk?.english_name}
                  </span>
                  <span> / </span>
                  <span>
                    {`${getRoomArea(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[0]?.rooms[0])}${t('area')}`}
                  </span>
                </div>}
                iscarousel={false}
                viewDetails={true}
                viewShowMoreLink={true} />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row p-0">
                <div className={styles.additionalCard_container + " col-6 col-lg-6"}>
                  <GallaryCard
                    directToAction={() => redirectToRoomsSearchResult(+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[1].id, "roomsNameList")}
                    roomId={+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.id}
                    imagesList={getRoomImageURL(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.image_url)}
                    roomName={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.english_name}
                    roomType={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.room_names_Fk?.english_name}
                    roomDetails={<div className={styles.description}>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.room_names_Fk?.english_name}
                      </span>
                      <span> </span>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.finishing_style_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.finishing_style_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.finishing_cost_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0]?.finishing_cost_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {`${getRoomArea(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[1]?.rooms[0])}${t('area')}`}
                      </span>
                    </div>}
                    iscarousel={false}
                    viewDetails={false}
                    viewShowMoreLink={false} />
                </div>
                <div className="col-6 col-lg-6 ">
                  <GallaryCard
                    directToAction={() => redirectToRoomsSearchResult(+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[2].id, "roomsNameList")}
                    roomId={+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.id}
                    imagesList={getRoomImageURL(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.image_url)}
                    roomName={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.english_name}
                    roomType={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.room_names_Fk?.english_name}
                    roomDetails={<div className={styles.description}>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.room_names_Fk?.english_name}
                      </span>
                      <span> </span>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.finishing_style_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.finishing_style_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.finishing_cost_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0]?.finishing_cost_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {`${getRoomArea(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[2]?.rooms[0])}${t('area')}`}
                      </span>
                    </div>}
                    iscarousel={false}
                    viewDetails={false}
                    viewShowMoreLink={false} />
                </div>
              </div>
              <div className="row mt-20 p-0">
                <div className="col-6 col-lg-6">
                  <GallaryCard
                    directToAction={() => redirectToRoomsSearchResult(+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[3].id, "roomsNameList")}
                    roomId={+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.id}
                    imagesList={getRoomImageURL(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.image_url)}
                    roomName={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.english_name}
                    roomType={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.room_names_Fk?.english_name}
                    roomDetails={<div className={styles.description}>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.room_names_Fk?.english_name}
                      </span>
                      <span> </span>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.finishing_style_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.finishing_style_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.finishing_cost_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0]?.finishing_cost_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {`${getRoomArea(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[3]?.rooms[0])}${t('area')}`}
                      </span>
                    </div>}
                    iscarousel={false}
                    viewDetails={false}
                    viewShowMoreLink={false} />
                </div>
                <div className="col-6 col-lg-6">
                  {!viewMorefRooms && <div className={styles.moreOfGallaryContainer} onClick={() => setviewMorefRooms(!viewMorefRooms)}>
                    <img src={images.moreOfGallary} alt="more of rooms" />
                    <div className={styles.details}>
                      {/* TODO: to get number of rooms from data from grapQL query */}
                      <p>
                        <span>{gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames.length - 4}</span>
                        <span>+</span>
                      </p>
                      <p>{t('viewMoreOfRooms', { numberOfRooms: gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames.length - 5 })}</p>
                    </div>
                  </div>}
                  {viewMorefRooms &&
                    <GallaryCard
                      directToAction={() => redirectToRoomsSearchResult(+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[4].id, "roomsNameList")}
                      roomId={+gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0].id}
                      imagesList={getRoomImageURL(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0].image_url)}
                      roomName={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.english_name}
                      roomType={lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.room_names_Fk?.english_name}
                      roomDetails={<div className={styles.description}>
                        <span>
                          {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.room_names_Fk?.english_name}
                        </span>
                        <span> </span>
                        <span>
                          {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.finishing_style_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.finishing_style_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {lang.name === 'AR' ? gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.finishing_cost_Fk?.arabic_name : gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0]?.finishing_cost_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {`${getRoomArea(gallaryByRoomNameResp?.data?.getGalleryRoomsGroupedbyRoomNames[4]?.rooms[0])}${t('area')}`}
                        </span>
                      </div>}
                      iscarousel={false}
                      viewDetails={false}
                      viewShowMoreLink={false} />}
                </div>
              </div>
              {/* <div className={styles.searchForMore_media + " row"}>
                <div className="col-12">
                  <div className={styles.searchForMoreContainer}>
                    <p>{t('neadMoreRoomsQuestion')}</p>
                    <p>{t('useSearch')}</p>
                    <div className="filterLinkAction" onClick={() => jumpToReleventDiv('filterContainer')}>
                      <div className="noAction">
                        <FilterBar isHasSort={false} isShowList={false} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {viewMorefRooms && <div className={styles.moreOfRoomsContainer}>
            <div className="row">
              {gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames.filter((ele, i) => i > 4).map((room, index) => {
                return (
                  <div className={styles.moreGallaryItems + " col-12 col-md-6 col-lg-3"}>
                    <GallaryCard
                      directToAction={() => redirectToRoomsSearchResult(+room?.id, "roomsNameList")}
                      roomId={+room.id}
                      imagesList={getRoomImageURL(room?.rooms[0].image_url)}
                      roomName={lang.name === 'AR' ? room?.rooms[0]?.arabic_name : room?.rooms[0]?.english_name}
                      roomType={lang.name === 'AR' ? room?.rooms[0]?.room_names_Fk?.arabic_name : room?.rooms[0]?.room_names_Fk?.english_name}
                      roomDetails={<div className={styles.description}>
                        <span>
                          {lang.name === 'AR' ? room?.rooms[0]?.room_names_Fk?.arabic_name : room?.rooms[0]?.room_names_Fk?.english_name}
                        </span>
                        <span> </span>
                        <span>
                          {lang.name === 'AR' ? room?.rooms[0]?.finishing_style_Fk?.arabic_name : room?.rooms[0]?.finishing_style_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {lang.name === 'AR' ? room?.rooms[0]?.finishing_cost_Fk?.arabic_name : room?.rooms[0]?.finishing_cost_Fk?.english_name}
                        </span>
                        <span> / </span>
                        <span>
                          {`${getRoomArea(room?.rooms[0])}${t('area')}`}
                        </span>
                      </div>}
                      iscarousel={false}
                      viewDetails={false}
                      viewShowMoreLink={false} />
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
      </section>
      <section className={styles.mobile_gallaryContainer}>
        <div className="container">
          <h2>{t('roomGallary')}</h2>
          <div className={styles.gallrySlider}>
            {gallaryByRoomNameResp.data.getGalleryRoomsGroupedbyRoomNames.map((room, index) => {
              return (
                <div className={styles.gallaryCard} onClick={() => redirectToRoomsSearchResult(+room?.id, "roomsNameList")}>
                  <div className={styles.img}
                    style={{
                      backgroundImage: `url(${getRoomImageURL(room?.rooms[0].image_url)})`,
                      backgroundRepeat: 'no-repeat',
                      // 'background-attachment': 'fixed',
                      backgroundPosition: 'center',
                      backgroundSize: '100% 100%',
                    }}></div>
                  <p>{lang.name === 'AR' ? room?.rooms[0]?.room_names_Fk?.arabic_name : room?.rooms[0]?.room_names_Fk?.english_name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      {/* section for Design */}
      <section className={styles.designTypesContainer}>
        <div className="container">
          <h2>{t('designType')}</h2>
          <div className="row">
            <div className={styles.MainType + " col-12 col-lg-6 p-0"}>
              <GallaryCard
                directToAction={() => redirectToRoomsSearchResult(+gallaryByFinishingStyleResp.data.getGalleryRoomsGrouped[0].id, "styleList")}
                roomId={+gallaryByFinishingStyleResp.data.getGalleryRoomsGrouped[0]?.rooms[0].id}
                imagesList={getRoomImageURL(gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0].image_url)}
                roomName={lang.name === 'AR' ? gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.arabic_name : gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.english_name}
                roomType={lang.name === 'AR' ? gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.arabic_name : gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.english_name}
                roomDetails={<div className={styles.description}>
                  <span>
                    {lang.name === 'AR' ? gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.room_names_Fk?.arabic_name : gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.room_names_Fk?.english_name}
                  </span>
                  <span> </span>
                  <span>
                    {lang.name === 'AR' ? gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.finishing_style_Fk?.arabic_name : gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.finishing_style_Fk?.english_name}
                  </span>
                  <span> / </span>
                  <span>
                    {lang.name === 'AR' ? gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.finishing_cost_Fk?.arabic_name : gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0]?.finishing_cost_Fk?.english_name}
                  </span>
                  <span> / </span>
                  <span>
                    {`${getRoomArea(gallaryByFinishingStyleResp?.data?.getGalleryRoomsGrouped[0]?.rooms[0])}${t('area')}`}
                  </span>
                </div>}
                iscarousel={false}
                viewDetails={false}
                viewShowMoreLink={false} />
            </div>
            <div className={styles.MainType_description + " col-12 col-lg-6 "}>
              <div className={styles.seeMoreTypesContainer}>
                <p>{t('DesignSeeMore_title')}</p>
                <p>{t('DesignSeeMore_description')}</p>
                {/* <Link className='btn' to={'/searchResult'} >{t('seeMore')}</Link> */}
              </div>
            </div>
          </div>
          <div className={styles.moreTypesScroller}>
            {gallaryByFinishingStyleResp.data.getGalleryRoomsGrouped.filter((ele, i) => i != 0).map((room, index) => {
              return (
                <div className={styles.designItem}>
                  <GallaryCard
                    directToAction={() => redirectToRoomsSearchResult(+room.id, "styleList")}
                    roomId={+room.id}
                    imagesList={getRoomImageURL(room.rooms[0].image_url)}
                    roomName={lang.name === 'AR' ? room.rooms[0]?.arabic_name : room.rooms[0]?.english_name}
                    roomType={lang.name === 'AR' ? room?.arabic_name : room?.english_name}
                    roomDetails={<div className={styles.description}>
                      <span>
                        {lang.name === 'AR' ? room.rooms[0]?.room_names_Fk?.arabic_name : room.rooms[0]?.room_names_Fk?.english_name}
                      </span>
                      <span> </span>
                      <span>
                        {lang.name === 'AR' ? room.rooms[0]?.finishing_style_Fk?.arabic_name : room.rooms[0]?.finishing_style_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {lang.name === 'AR' ? room.rooms[0]?.finishing_cost_Fk?.arabic_name : room.rooms[0]?.finishing_cost_Fk?.english_name}
                      </span>
                      <span> / </span>
                      <span>
                        {`${getRoomArea(room.rooms[0])}${t('area')}`}
                      </span>
                    </div>}
                    iscarousel={false}
                    viewDetails={false}
                    viewShowMoreLink={false} />
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section className={styles.mobile_designTypesContainer}>
        <div className="container">
          <h2>{t('designType')}</h2>
          <div className={styles.gallrySlider}>
            {gallaryByFinishingStyleResp.data.getGalleryRoomsGrouped.map((room, index) => {
              return (
                <div className={styles.gallaryCard} onClick={() => redirectToRoomsSearchResult(+room.id, "styleList")}>
                  <div className={styles.img}
                    style={{
                      backgroundImage: `url(${getRoomImageURL(room?.rooms[0].image_url)})`,
                      backgroundRepeat: 'no-repeat',
                      // 'background-attachment': 'fixed',
                      backgroundPosition: 'center',
                      backgroundSize: '100% 100%',
                    }}></div>
                  <p>{lang.name === 'AR' ? room?.arabic_name : room?.english_name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      {/* section for Articals */}
      {/* <section className={styles.articalsContainer}>
        <ArticalGroup articalItemList={[
          {
            id: 1,
            title: 'كيف يمكنك اختيار الإضائة المناسبة لكل غرفة ؟',
            description: 'هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع',
            date: '٢٠٢٢/٥/٢٥',
            image: ''
          },
          {
            id: 2,
            title: 'كيف يمكنك اختيار الإضائة المناسبة لكل غرفة ؟',
            description: 'هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع',
            date: '٢٠٢٢/٥/٢٥',
            image: ''
          },
          {
            id: 3,
            title: 'كيف يمكنك اختيار الإضائة المناسبة لكل غرفة ؟',
            description: 'هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع',
            date: '٢٠٢٢/٥/٢٥',
            image: ''
          },
          {
            id: 4,
            title: 'كيف يمكنك اختيار الإضائة المناسبة لكل غرفة ؟',
            description: 'هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع',
            date: '٢٠٢٢/٥/٢٥',
            image: ''
          },
          {
            id: 5,
            title: 'كيف يمكنك اختيار الإضائة المناسبة لكل غرفة ؟',
            description: 'هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع هنا يكتب جزء بسيط من محتوي المقالة يمكن ان يكون الجزء من المقدمة ولكن يفضل ان يكون الجزء الاكثر جاذبية في المقالة لتحميس المستخدم للقراءة اكثر عن هذا الموضوع',
            date: '٢٠٢٢/٥/٢٥',
            image: ''
          }
        ]} />
      </section> */}
    </div>
  )
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
