import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';
import { useTranslation } from "react-i18next";
import * as icons from '../../Assets/icons';
import * as images from '../../Assets/images';
import { Link, useNavigate } from 'react-router-dom';
import { APPSTOREURL, GOOGLEPLAYURL, socialUrls } from '../../ENV';
const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.Footer}>
      <div className={styles.subscribe}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="flex-col col-flex-mid">
                <p className={styles.subscribeMsg}>{t("subscribeMsg")}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={styles.subscribeAction}>
                <div className="row justify-content-end">
                  {/* <div className="col-auto p-0">
                    <div className={styles.inputContainer}>
                      <input className='input-group-text' type="text" placeholder={t('writeUrEmail')} />
                    </div>
                  </div> */}
                  {/* <div className="col-auto p-0"> */}
                    <button className={styles.sendBtn + ' btn'} onClick={()=>navigate('/Complain')} ><span>{t("sendMsg")}</span> <icons.AiOutlineMail/></button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body + ' container'}>
        <div className="row">
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className={styles.links + " row"}>
                  <div className="col-6 col-md-4">
                    <ul>
                      <li><Link to='/About' >{t('aboutOjee')}</Link></li>
                      <li><Link to='/ApplyingFinishingCompanies' >{t('applyFinishingCompany')}</Link></li>
                      <li><Link to='/TermsOfUse' >{t('terms')}</Link></li>
                      <li className={styles.link_mobile}><Link to='/Questions' >{t('FAQ')}</Link></li>
                    </ul>
                  </div>
                  <div className="col-6 col-md-4">
                    <ul>
                      <li><Link to='/PrivacyPolicy' >{t('privacyPolicy')}</Link></li>
                      <li><Link to='/Complain'>{t('applycomplains')}</Link></li>
                      <li><Link to='/ApplyServices' >{t('materialsAndProducts')}</Link></li>
                      {/* <li><Link to='/RatingModel' >{t('urRate')}</Link></li> */}
                    </ul>
                  </div>
                  <div className={"col-4 " + styles.link_desktop}>
                    <ul>
                      <li><Link to='/Questions' >{t('FAQ')}</Link></li>
                      <li><a className='d-flex' href="mailto: hello@ogeedecor.com"><span>hello@ogeedecor.com</span> <img src={icons.mailIcon} alt="mail" /></a></li>
                      {/* <li><a className='d-flex' href="tel: +20 101 155 9302" ><span>+20 101 155 9302 </span><img src={icons.teleIcon} alt="tel" /></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className={styles.storeImgs + " row justify-content-center"}>
                  <div className="col-5 col-md-3 col-lg-12 p-0 text-center">
                    <Link to={GOOGLEPLAYURL} target="_blank"><img src={images.googlePlay} alt="googlePlay" /></Link>
                  </div>
                  <div className="col-5 col-md-3 col-lg-12 p-0 text-center">
                    <Link to={APPSTOREURL} target="_blank"><img src={images.appStore} alt="appStore" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.lowerBody + " row"}>
          <div className={styles.rights + " col-12 col-lg-4"}>
            <p>{t('right')}</p>
          </div>
          <div className={styles.desinedBy + " col-12 col-lg-4"}>
            <p>{t('designedBy')}<a href="" target={'_blank'}>Bytesway</a></p>
          </div>
          <div className="col-12 col-lg-4 p-0">
            <div className={styles.icons + ' row'}>
              <div className='col-auto p-0'><Link to={socialUrls.youtube} target="_blank"><img src={icons.youtubeIcon} alt="youtube" /></Link></div>
              <div className='col-auto p-0'><Link to={socialUrls.instagram} target="_blank"><img src={icons.instgramIcon} alt="instgram" /></Link></div>
              <div className='col-auto p-0'><Link to={socialUrls.linkedIn} target="_blank"><img src={icons.linkedInIcon} alt="linkedin" /></Link></div>
              <div className='col-auto p-0'><Link to={socialUrls.tiktok} target="_blank"><img src={icons.tiktokIcon} alt="tiktok" /></Link></div>
              <div className='col-auto p-0'><Link to={socialUrls.facebook} target="_blank"><img src={icons.facebookIcon} alt="facebook" /></Link></div>
            </div>
            <div className={styles.mobile_rights + " col-12 col-lg-4"}>
              <p>{t('right')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
