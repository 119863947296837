import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticalDetails.module.scss';

const ArticalDetails = () => {
  return (
    <div className={styles.ArticalDetails}>
      ArticalDetails Component
    </div>
  );
};

ArticalDetails.propTypes = {};

ArticalDetails.defaultProps = {};

export default ArticalDetails;
