import gql from 'graphql-tag';

export const GET_CITIES_AND_AREAS = gql`
  query{
    getCitiesandAreas{
      id
      english_name
      arabic_name
      areas{
        id
        english_name
        arabic_name
      }
    }
  }
`;
export const GET_ROOM_NAMES = gql`
  query{
    getRoomNames{
      id
      english_name
      arabic_name
      utility_factors{
          english_name
          arabic_name
          id
      }
    }
  }
`;
export const GET_ROOMS_OF_THIS_UNIT = gql`
  query($unitID:ID!, $userID:ID!){
    getRoomsWithinUnits(unitID:$unitID, userID:$userID){
        roomFk{
              id
              isDeleteable
              english_name
              arabic_name
              isAirConditioning
              name
              width
              length
              height
              one
              two
              three
              four
              isCalculatable
              calculated_price
              wall_color
              isGallery
              image_url
              room_names_Fk{
                  id
                  english_name
                  arabic_name
              }
              finishing_cost_Fk{
                  id
                  english_name
                  arabic_name
              }
              finishing_style_Fk{
                  id
                  english_name
                  arabic_name
              }
              wallMaterial{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              wallMaterialAddon1{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              wallMaterialAddon2{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              cielingMaterial{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              cielingMaterialAddon1{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              cielingMaterialAddon2{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              floorMaterial{
                  id
                  english_name
                  arabic_name
                  decoration_type
              }
              doors{
                  height
                  width
                  doorMaterial{
                      id
                      english_name
                      arabic_name
                      decoration_type
                  }
              }
              windows{
                  height
                  width
                  windowMaterial{
                      id
                      english_name
                      arabic_name
                      decoration_type
                  }
                  windowAddonMaterial{
                      id
                      english_name
                      arabic_name
                      decoration_type
                  }
              }
              utility_factors{
                utility_factor_Fk{
                    id
                    english_name
                    arabic_name
                  }
              }
              _count{
                doors
                windows
              }
        }  
    }
}
`;
export const GET_FINISHING_COSTS_AND_FINISHING_STYLES = gql`
  query{
    getFinishingcostandstyle{
      finishing_costs{
        id
        english_name
        arabic_name
      }
      finishing_styles{
        id
        english_name
        arabic_name
      }
    }
  }
`;
export const GET_DECORATIONS = gql`
  query($finish_cost_id:ID!, $finish_style_id:ID!,$RoomTypeID:ID!){
    getdecorations(finish_cost_id:$finish_cost_id,finish_style_id:$finish_style_id,RoomTypeID:$RoomTypeID){
      WallDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      WallAddonDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      CeilingDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      CeilingAddonDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      FloorDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      DoorDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      WindowDecorations{
          id
          english_name
          arabic_name
          decoration_type
      },
      WindowAddonDecorations{
          id
          english_name
          arabic_name
          decoration_type
      }
    }
  }
`;
export const GET_LIKED_ROOMS = gql`
  query($userId:ID!){
    getLikedrooms(userID:$userId){
      id
      roomID
      userID
      roomFk{  
        id
        english_name
        arabic_name
        image_url
        name
        Gallery_photo_description
        Gallery_photo_description_english
        liked_by{
            roomID
            userID
        }
        one
        two
        three
        four
        width
        length
        height
        wall_color
        calculated_price
        room_names_Fk{
          id
          english_name
          arabic_name
          # utility_factors{
          #   english_name
          #   arabic_name
          #   id
          # }
        }
        finishing_cost_Fk{
          id
          english_name
          arabic_name
        }
        finishing_style_Fk{
          id
          english_name
          arabic_name
        }
        wallMaterial{
          id
          english_name
          arabic_name
          decoration_type
        }
        wallMaterialAddon1{
          id
          english_name
          arabic_name
          decoration_type
        }
        wallMaterialAddon2{
          id
          english_name
          arabic_name
          decoration_type
        }
        cielingMaterial{
          id
          english_name
          arabic_name
          decoration_type
        }
        cielingMaterialAddon1{
          id
          english_name
          arabic_name
          decoration_type
        }
        cielingMaterialAddon2{
          id
          english_name
          arabic_name
          decoration_type
        }
        floorMaterial{
          id
          english_name
          arabic_name
          decoration_type
        }
        doors{
          name
          height
          width
          doorMaterial{
            id
            english_name
            arabic_name
            decoration_type
          }
        }
        windows{
          name
          height
          width
          windowMaterial{
            id
            english_name
            arabic_name
            decoration_type
          }
          windowAddonMaterial{
            id
            english_name
            arabic_name
            decoration_type
          }
        }
      }
    }
  }
`;
export const GET_UNIT_PRRICE = gql`
  query($unitId:ID!,$userId:ID!){
    show_price(unitID:$unitId,userID:$userId){
        price
        calculatable
    }
  }
`;
export const GET_UNIT_PACKAGES = gql`
  query{
  Packages{
    id
    english_subtitle
    arabic_subtitle
    english_title
    arabic_title
    english_short_description
    arabic_short_description
    english_description
    arabic_description
    price_per
    price_per_arabic
    price
    most_used
  }
}
`;
export const GET_MY_UNITS = gql`
query($userId:ID!){
  getMyUnits(userID:$userId){
      id
      name
      type
      Price
      PriceUpdatedAt
      category
      isCalculatable
      rooms{
          roomFk{
            id
            english_name
            arabic_name
            isAirConditioning
            name
            width
            length
            height
            isCalculatable
            calculated_price
            wall_color
            isGallery
            image_url
            _count{
                doors
                windows
            }
            room_names_Fk{
                id
                english_name
                arabic_name
            }
            finishing_cost_Fk{
                id
                english_name
                arabic_name
            }
            finishing_style_Fk{
                id
                english_name
                arabic_name
            }
            wallMaterial{
                id
                english_name
                arabic_name
                decoration_type
            }
            wallMaterialAddon1{
                id
                english_name
                arabic_name
                decoration_type
            }
            wallMaterialAddon2{
                id
                english_name
                arabic_name
                decoration_type
            }
            cielingMaterial{
                id
                english_name
                arabic_name
                decoration_type
            }
            cielingMaterialAddon1{
                id
                english_name
                arabic_name
                decoration_type
            }
            cielingMaterialAddon2{
                id
                english_name
                arabic_name
                decoration_type
            }
            floorMaterial{
                id
                english_name
                arabic_name
                decoration_type
            }
            doors{
                name
                height
                width
                doorMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
            }
            windows{
                name
                height
                width
                windowMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
                windowAddonMaterial{
                    id
                    english_name
                    arabic_name
                    decoration_type
                }
            }
          }
      }
      areaFk{
          id
          english_name
          arabic_name
          cityFk{
              id
              english_name
              arabic_name
          }
      }
  }
}
`;