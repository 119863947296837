import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './GallaryCard.module.scss';
import { t } from 'i18next';
import * as images from '../../Assets/images';
import { Link } from 'react-router-dom';

const GallaryCard = ({ roomId, imagesList, roomName, roomType, roomDetails, iscarousel, viewDetails, viewShowMoreLink, directToAction }) => {
  const [onHover, setonHover] = useState(false);
  return (
    <div className={styles.GallaryCard}>
      {/* <Link to={`/searchResult`}> */}
      <div className={styles.linkGallaryCardBody} onClick={() => directToAction()}>
        {(viewDetails && !onHover) && <div className={styles.roomDetails}>
          <p>{roomName}</p>
          <small>{roomDetails}</small>
        </div>}
        <div className={styles.roomGallary}>
          {iscarousel ?
            <div id={"carouselExampleIndicators" + roomId} className="carousel slide" data-bs-ride="carousel">
              {/* <div className={styles.carouselIndicators + ' carousel-indicators'}>
          <button type="button" data-bs-target={"#carouselExampleIndicators" + roomId} data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target={"#carouselExampleIndicators" + roomId} data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target={"#carouselExampleIndicators" + roomId} data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> */}
              <div className="carousel-inner">
                {imagesList.forEach(image => {
                  return (
                    <div className={styles.carouselItem + " carousel-item active"}>
                      <img src={image} className="d-block w-100" alt="..." />
                    </div>
                  )
                })}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target={"#carouselExampleIndicators" + roomId} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                {/* <span className="visually-hidden">Previous</span> */}
              </button>
              <button className="carousel-control-next" type="button" data-bs-target={"#carouselExampleIndicators" + roomId} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                {/* <span className="visually-hidden">Next</span> */}
              </button>
            </div> :
            <div className={styles.carouselItem} onMouseEnter={() => setonHover(!onHover)} onMouseLeave={() => setonHover(!onHover)}>
              {/* <img src={} className="d-block w-100" alt="..." /> */}
              <div className={styles.image} style={{
                backgroundImage: `url(${imagesList[0]})`
              }}></div>
              {onHover && <div className={styles.linkGallaryCardHover + ' flex-col'}>
                <p>{t('viewMoreOf', { roomName: roomType })}</p>
              </div>}
            </div>
          }
        </div>
        {!onHover && <div className={styles.roomActionType + ' d-flex justify-content-between'}>
          <button className={'btn ' + styles.gallaryBtn} type='button'>{roomType}</button>
          {viewShowMoreLink && <p>{t('viewMoreFrom') + ' ' + roomType}</p>}
        </div>}
      </div>

      {/* </Link> */}

    </div >
  );
};

GallaryCard.propTypes = {
  roomId: PropTypes.number.isRequired,
  imagesList: PropTypes.array.isRequired,
  roomName: PropTypes.string.isRequired,
  roomType: PropTypes.string.isRequired,
  roomDetails: PropTypes.any.isRequired,
  iscarousel: PropTypes.bool,
  viewDetails: PropTypes.bool,
  viewShowMoreLink: PropTypes.bool,
  directToAction: PropTypes.func,
};

GallaryCard.defaultProps = {
  iscarousel: true,
  viewDetails: true,
  viewShowMoreLink: true,
  directToAction: () => { }
};

export default GallaryCard;
