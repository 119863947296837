import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MustLoginModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as icons from '../../Assets/icons';

const MustLoginModal = () => {
  const navigate = useNavigate();
  const goToCreateAccount = () => {
    navigate("/Signup");
  };
  const goToLogin = () => {
    navigate("/Login");
  };
  return (
    <Modal id={"mustLoginModal"} show={true} onHide={() => goToCreateAccount()} centered>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body >
        <div className="toLoginIcon">
          <icons.MdExitToApp />
        </div>
        <p>{t('mustLoginSentence')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn_createAcc' onClick={() => goToCreateAccount()}>
          {t('createAccount')}
        </Button>
        <Button className='btn_login' onClick={() => goToLogin()}>
          {t('signIn')}
        </Button>
      </Modal.Footer>
    </Modal >
  );
};

MustLoginModal.propTypes = {};

MustLoginModal.defaultProps = {};

export default MustLoginModal;
