import React, { useState, useEffect } from 'react'
import styles from './Packages.module.scss'
import {
  selectUser
} from '../../Reducer/UserSLice';
import { t } from 'i18next';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_UNIT_PACKAGES
} from '../../Graphql/UnitForm/Queries';
import {
  SEND_REQUESTED_PACKAGES
} from '../../Graphql/Authentication/mutations';
import * as Icons from '../../Assets/icons';
import { selectLang } from '../../Reducer/LangSlice';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MustLoginModal } from '../../components';
import { Modal, Button } from 'react-bootstrap';
import { CustomInput } from '../../components';
import { Validators, validatorsInput } from '../../services/validators';

const Packages = () => {
  var user = useSelector(selectUser);
  let lang = useSelector(selectLang);
  const navigate = useNavigate();
  const [packagesId, setPackagesId] = useState([]);
  const { data, loading, error } = useQuery(GET_UNIT_PACKAGES);
  const [sendRequestedPackages, requestResponse] = useMutation(SEND_REQUESTED_PACKAGES);
  const [userMobile, setUserMobile] = useState(user.user.phone);
  const [show, setShow] = useState(false);
  const [viewAll, setViewAll] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleViewAll = (id) => {
    if (id == viewAll)
      setViewAll(null)
    else
      setViewAll(id)
  }
  const [showThanks, setShowThanks] = useState(false);
  const handleCloseThanks = () => {
    setShowThanks(false);
    navigate('/');
  }
  const handleShowThanks = () => setShowThanks(true);
  const onChange_mobileNo = (mobile) => {
    setUserMobile(mobile);
  }
  const checkChoice = (packageId) => {
    if (!packagesId.includes(packageId)) {
      setPackagesId([...packagesId, packageId]);
    }
    else {
      let newPackageIDs = packagesId.filter(item => {
        return item != packageId
      })
      setPackagesId(newPackageIDs);
    }
    // console.log(packagesId);
  }
  const onClick_submitPakages = () => {
    sendRequestedPackages({
      variables: {
        userId: +user.user.userId,
        unitID: 0,
        packageIDs: packagesId
      }
    })
  }
  useEffect(() => {
    if (requestResponse?.data?.sendRequest?.done) {
      // toast.success(t('requestSendSuccessfuly'), { autoClose: 1000 });
      // navigate('/');
      handleClose();
      handleShowThanks();
    }
  }, [requestResponse.data]);
  if (!user.user.userId) {
    return (
      <div className={styles.Packages}>
        <MustLoginModal />
      </div>)
  }
  if (loading) return (
    <div className={styles.Packages}>
      <LoadingSpinner />
    </div>
  );
  if (error) return `Submission error! of finishingCostAndFinishingStyles ${error.message}`;
  return (
    <div className={styles.Packages}>
      <div className={styles.body}>
        <div className="container">
          <div className="row justify-content-center">
            {data?.Packages?.map((item, index) => {
              return (
                <div className="col-12 col-md-6 col-lg-3">
                  <div
                    className={packagesId.includes(item.id) ? styles.designPlan + ' ' + styles.active : styles.designPlan}>
                    {item.mostSelected && <div className={styles.mostSelected}>
                      <p>{t('mostSelected')}</p>
                    </div>}
                    <div className={styles.planBody}>
                      <div className={styles.offerDescription} onClick={() => checkChoice(item.id)}>
                        <div className={styles.head_container}>
                          <h4 className={styles.name}>{lang.name == 'AR' ? item.arabic_title : item.english_title}</h4>
                          <p>{lang.name == 'AR' ? item.arabic_subtitle : item.english_subtitle}</p>
                        </div>
                        <div className={styles.desc_container}>
                          <div className={styles.cost}>
                            <p dir='ltr'>/LE</p>
                            <p>{item.price}</p>
                            <p>m2</p>
                          </div>
                          <p className={styles.bookDesc}>{lang.name == 'AR' ? item.arabic_short_description : item.english_short_description}</p>
                        </div>
                      </div>
                      <div className={`${styles.offerList} ${viewAll == item.id ? styles.viewAll : ''}`}>
                        <p className={`${styles.offerDesc}`} data-tip data-for={`offer${index}`}>{lang.name == 'AR' ? item.arabic_description : item.english_description}</p>
                        <div className={styles.tooltip}>
                          <ReactTooltip id={`offer${index}`} place="top" type="dark" effect="solid">
                            <p>{lang.name == 'AR' ? item.arabic_description : item.english_description}</p>
                          </ReactTooltip>
                        </div>
                        {/* <ul>
                          {item.offerList.map((offer, index) => {
                            return (
                              <>
                                <li data-tip data-for={`offer${index}`}><Icons.FcCheckmark /> {offer}</li>
                                <ReactTooltip id={`offer${index}`} place="top" type="dark" effect="solid">
                                  <span>{offer}</span>
                                </ReactTooltip>
                              </>
                            );
                          })}
                        </ul> */}
                      </div>
                      <div className={styles.mobile_viewMore} onClick={() => toggleViewAll(item.id)}>
                        <span>
                          {t('viewMoreOf')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal id={"unitConfirmation"} show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <p>{t('confirmMsgBookUnit')}</p>
          <p>{t('contactNumber')}</p>
          <div className="inputContainer">
            <Icons.RiEditLine />
            <CustomInput
              className='input-group-text'
              type="text"
              acceptOnly='number'
              value={userMobile}
              validators={[
                { check: Validators.required, message: 'field is required' },
                { check: Validators.mobile, message: 'please enter valid mobile' }
              ]}
              onChange={event => onChange_mobileNo(event)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='modalButton' onClick={() => onClick_submitPakages()} >
            <span>{t('comfirmRequest')} </span>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal id={"thanksModal"} show={showThanks} onHide={handleCloseThanks} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div className="doneFlag">
            <Icons.MdDone />
          </div>
          <p>{t('thanks')}</p>
          <p>{t('willContactU', { mobileNumber: user.user.phone })}</p>
        </Modal.Body>
      </Modal>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-center">
            <div className={styles.mid + ' col-12 col-lg-5 text-center'}>
              <button className='btn' onClick={() => handleShow()} ><span>{t('send')}</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Packages
