export const URL = 'https://main.ogeedecor.com/graphql';
export const MainURL = 'https://main.ogeedecor.com/';
export const APPSTOREURL = 'https://apps.apple.com/eg/app/ogee-decor/id6443860535';
export const GOOGLEPLAYURL = 'https://play.google.com/store/apps/details?id=com.ogee';
export const socialUrls = {
    facebook:'https://www.facebook.com/ogeedecor.eg',
    tiktok:'https://www.tiktok.com/@ogee.decor',
    linkedIn:'https://www.linkedin.com/in/ogee-decor-ba4bb2252/',
    instagram:'https://www.instagram.com/ogeedecor/',
    youtube:'https://www.youtube.com/@ogeedecor'
}
export var deviceIDToken = null;