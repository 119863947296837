import React from 'react'
import styles from './About.module.scss';
import * as images from '../../Assets/images';
import { FaFacebookF, FaTiktok, FaLinkedinIn, FaInstagram, FaYoutube } from 'react-icons/fa'
import * as Icons from '../../Assets/icons';
import { useQuery } from "@apollo/client";
import { GET_ABOUT } from "../../../src/Graphql/Authentication/Queries"
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Link } from 'react-router-dom';
import {
  selectLang
} from '../../Reducer/LangSlice';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { socialUrls } from '../../ENV';
const About = () => {
  const { loading, error, data } = useQuery(GET_ABOUT);
  let lang = useSelector(selectLang);
  if (loading) return <LoadingSpinner />;
  if (error) return `Error! ${error}`;
  return (
    <div className={styles.About}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-12 col-lg-8'}>
              <Icons.AiOutlineArrowLeft />
              <Link to='/'>{t('home')}</Link>
              <span> \ </span>
              <Link to='/About'>{t('aboutOjee')}</Link>
            </div>
          </div>
        </div>
      </div>
      <main className={styles.Content + " container"}>
        <div className="row">
          <div className={styles.description + " col-12 col-lg-7 "}>
            <h1>{t('whoWeAre')}</h1>
            <p>{lang.name === 'AR' ? data.Pages.arabic_about : data.Pages.english_about}</p>
          </div>
          <div className={styles.image_contaier + " col-12 col-lg-5"}>
            <div className={styles.container}>
              <div className={styles.logo}><img src={images.logo} alt='' /></div>
            </div>
            <div className={styles.socialIcons}>
              <Link to={socialUrls.facebook} target="_blank"><FaFacebookF size={17.5} /></Link>
              <Link to={socialUrls.tiktok} target="_blank"><FaTiktok size={17.5} /></Link>
              <Link to={socialUrls.linkedIn} target="_blank"><FaLinkedinIn size={17.5} /></Link>
              <Link to={socialUrls.instagram} target="_blank"> <FaInstagram size={17.5} /></Link>
              <Link to={socialUrls.youtube} target="_blank"> <FaYoutube size={17.5} /></Link>
            </div>
            <div className={styles.Line}> <hr /></div>
          </div>
        </div>
      </main>
    </div >
  )
}

export default About