import React from 'react';
import PropTypes from 'prop-types';
import styles from './Articals.module.scss';

const Articals = () => {
  return (
    <div className={styles.Articals}>
      Articals Component
    </div>
  );
};

Articals.propTypes = {};

Articals.defaultProps = {};

export default Articals;
