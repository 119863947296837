import gql from 'graphql-tag';

export const ADD_UNIT = gql`
  mutation add_unit(
    $name: String!,
    $type: String!,
    $category: String!,
    $floor:Int!,
    $areaID: ID!,
    $userID:ID!){
    add_unit(
      name: $name,
      type: $type,
      category: $category,
      floor:$floor,
      areaID: $areaID,
      userID: $userID){
      id
      name
      type
      category
    }
  }
`;
export const ADD_ROOM = gql`
  mutation addroom(
    $userId: ID!, 
    $unitId: ID!,
    $utilities: [ID!], 
    $name: String!, 
    $aircon: Boolean, 
    $width: Float, 
    $height: Float, 
    $length: Float, 
    $one: Float, 
    $two: Float, 
    $three: Float, 
    $four: Float,
    $wallcolor: String, 
    $roomId: ID!, 
    $costId: Int!, 
    $styleId: Int!,
    $wallMatID: Int!, 
    $wallAddonId: Int!, 
    $wallAddon2: Int!,
    $cielingMatID: Int!, 
    $cielingAddon: Int!, 
    $cielingAddon2: Int!, 
    $floorMatID: Int!, 
    $doors: doorinput, 
    $windows: windowinput)
    {
    add_room(
      userID: $userId,
      unitID: $unitId,
      UtilityFactors: $utilities
      room: {
        name: $name,
        isAirConditioning: $aircon,
        room_width: $width,
        room_length: $length,
        room_height: $height,
        one: $one,
        two: $two,
        three: $three,
        four: $four,
        wall_color: $wallcolor,
        room_names_Id: $roomId,
        finishing_cost_Id: $costId,
        finishing_style_Id: $styleId,
        wallMaterialID: $wallMatID,
        wallMaterialAddon1ID: $wallAddonId,
        wallMaterialAddon2ID: $wallAddon2,
        cielingMaterialID: $cielingMatID,
        cielingMaterialAddon1ID: $cielingAddon,
        cielingMaterialAddon2ID: $cielingAddon2,
        floorMaterialID: $floorMatID,
      },
      doors: $doors,
      windows: $windows,
    ){
      done
    }
  }
`;
export const UPDATE_ROOM = gql`
  mutation addroom(
    $userId: ID!, 
    $roomID: ID!,
    $roomNameId:ID!,
    $utilities: [ID!], 
    $name: String!, 
    $aircon: Boolean, 
    $width: Float, 
    $height: Float, 
    $length: Float, 
    $one: Float, 
    $two: Float, 
    $three: Float, 
    $four: Float,
    $wallcolor: String, 
    $costId: Int!, 
    $styleId: Int!,
    $wallMatID: Int!, 
    $wallAddonId: Int!, 
    $wallAddon2: Int!,
    $cielingMatID: Int!, 
    $cielingAddon: Int!, 
    $cielingAddon2: Int!, 
    $floorMatID: Int!, 
    $doors: doorinput, 
    $windows: windowinput)
    {
    update_room(
      userID: $userId,
      roomID: $roomID,
      UtilityFactors: $utilities
      room: {
        name: $name,
        isAirConditioning: $aircon,
        room_width: $width,
        room_length: $length,
        room_height: $height,
        one: $one,
        two: $two,
        three: $three,
        four: $four,
        wall_color: $wallcolor,
        room_names_Id: $roomNameId,
        finishing_cost_Id: $costId,
        finishing_style_Id: $styleId,
        wallMaterialID: $wallMatID,
        wallMaterialAddon1ID: $wallAddonId,
        wallMaterialAddon2ID: $wallAddon2,
        cielingMaterialID: $cielingMatID,
        cielingMaterialAddon1ID: $cielingAddon,
        cielingMaterialAddon2ID: $cielingAddon2,
        floorMaterialID: $floorMatID,
      },
      doors: $doors,
      windows: $windows,
    ){
      done
    }
  }
`;
export const DELETE_ROOM = gql`
mutation($roomIds:[ID]!,$userID:ID!){
    Delete_Rooms(RoomIDs:$roomIds,userID:$userID){
      done
    }
}
`;
export const DELETE_UNITS = gql`
mutation($unitsIDs:[ID]!,$userID:ID!){
    delete_units(unitsIDs:$unitsIDs,userID:$userID){
            done
    }
}
`;
export const SEND_REQUEST_WITH_PACKAGES = gql`
mutation($packageIDs:[ID]!,$userID:ID!,$unitID:Int!){
    sendRequest(
        userID:$userID
        unitID: $unitID
        packageIDs: $packageIDs
    ){
        done
    }
}
`;