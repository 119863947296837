import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";



// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const config = {
  apiKey: "AIzaSyDfRCnRt0JD_FCZjaEzMRApOV6D-pCkNj4",
  authDomain: "ogee-mobile.firebaseapp.com",
  projectId: "ogee-mobile",
  storageBucket: "ogee-mobile.appspot.com",
  messagingSenderId: "987560390075",
  appId: "1:987560390075:web:be44192a371fe17233a89e",
  measurementId: "G-CGPG7H0PCN"
};
const firebaseApp = initializeApp(config);
const messaging = getMessaging(firebaseApp);
const vapidKey = "BDgpfh2Hvb1avBoGTqo4DZzseEBrDQwUXEQIOqcoQl5KC-f7T1vJ8n_n2Q1pCgd6uO39JmIzRPVoaw3DJ730L7s";
export const getCurrentToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload);
      resolve(payload);
    });
});