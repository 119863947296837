import React from 'react'
import styles from './Questions.module.scss';
import { useQuery } from "@apollo/client";
import gql from 'graphql-tag';
import { GET_FAQ } from "../../../src/Graphql/Authentication/Queries"
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Question } from '../../components';
import { t } from 'i18next';

function Questions() {

  
  const { loading, error, data } = useQuery(GET_FAQ);
  if (loading) return (
    <div className={styles.questions} >
      <LoadingSpinner />
    </div>);
  if (error) return `Error! ${error}`;

  return (
    // data.ReturnFAQs.map((index)=> {

    <div className={styles.questions} >
      <div>
        <h1>{t('FreqAskedQuestion')}</h1>
        {data.ReturnFAQs.map((ele, index) => {
          return (
            <Question item={ele} key={"question_" + index}/>
          )
        })}
      </div>
    </div>
  )
}
export default Questions
