import { gql } from "@apollo/client"

export const GET_OTP = gql`
query ($phoneNumber:String!){
  OTP( phone:$phoneNumber){
    OTP
  }
}`

export const LOGIN = gql`
query($phoneNumber:String!,$password:String!,$device_id:String){
  login( phone:$phoneNumber, password:$password,device_id:$device_id){
    userId,
    token,
    tokenExpiration,
    phone,
    username,
    email,
    gender,
    date_of_birth
    # password
  }
}`
export const FORGET_PASSWORD = gql`       
    query( $phone:String!) {
      ForgetPassword( phone:$phone){
        OTP
    }
 }`
export const GET_FAQ = gql`
query{
  ReturnFAQs{
    english_title
    arabic_title
    english_content
    arabic_content
  }
}`
export const GET_ABOUT = gql`
query {
  Pages{
    english_terms
    arabic_terms
    english_privacy
    arabic_privacy
    arabic_about
    english_about
  }
}`

export const GET_TERMS = gql`
query {
  Pages{
    english_terms
    arabic_terms
    english_privacy
    arabic_privacy
    arabic_about
    english_about
  }
}`

export const GET_PRIVACY = gql`
query {
  Pages{
    english_terms
    arabic_terms
    english_privacy
    arabic_privacy
    arabic_about
    english_about
  }
}`
