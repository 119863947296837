import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticalGroup.module.scss';
import { t } from 'i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ArticalCard } from '../index';
import { Link } from 'react-router-dom';

const ArticalGroup = ({ articalItemList }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div className={styles.ArticalGroup}>
      <div className="container text-center">
        <h2>{t('articalsMayLiked')}</h2>
        <div className="row">
          <div className="col-12">
            <Carousel responsive={responsive}>
              {articalItemList.map(item => {
                return (
                  <ArticalCard
                    key={item.id}
                    id={item.id}
                    image={item.image}
                    date={item.date}
                    title={item.title}
                    description={item.description} />
                );
              })}
            </Carousel>
          </div>
          <div className="col-12">
            <Link className={styles.readMoreBtn + ' btn'} to={'/Articals'}>{t('seeMore')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ArticalGroup.propTypes = {
  articalItemList: PropTypes.array.isRequired,
};

ArticalGroup.defaultProps = {};

export default ArticalGroup;
