import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './UnitCost.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from '../../../Assets/icons';
import * as Images from '../../../Assets/images';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import {
  selectUnit,
} from '../../../Reducer/UnitSlice';
import { selectLang } from '../../../Reducer/LangSlice';
import {
  GET_UNIT_PRRICE
} from '../../../Graphql/UnitForm/Queries';
import {
  selectUser
} from '../../../Reducer/UserSLice';
import { MustLoginModal } from '../../../components';
import { LoadingSpinner } from '../../../Pages';

const UnitCost = () => {
  let { unitId } = useParams();
  var unit = useSelector(selectUnit);
  let lang = useSelector(selectLang);
  var user = useSelector(selectUser);
  const navigate = useNavigate();
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const { data, loading, error } = useQuery(GET_UNIT_PRRICE, {
    variables: {
      unitId: unitId,
      userId: +user.user.userId
    }
  });
  const saveToAndRedirectToDraft = () => {
    //TODO save
    navigate(`/UnitDraft`);
  }
  const saveAndGoToDesignPage = () => {
    navigate(`/UnitForm/designPlans/${unitId}`);
  }
  const onClick_backToRoomsUnit = () => {
    navigate(-1);
  }
  const thousands_separators = (num) => {
    num = Number(num).toFixed(2);
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  if (!user.user.userId) {
    return (
      <div className={styles.UnitCost}>
        <MustLoginModal />
      </div>)
  }
  //  const rooms = ["غرفة المعيشة", "غرفة النوم", "مطبخ", "حمام"];
  if (loading) return (
    <div className={styles.UnitCost}>
      <LoadingSpinner />
    </div>
  );
  if (error) return `Submission error! of finishingCostAndFinishingStyles ${error.message}`;
  return (
    <div className={styles.UnitCost}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-3 col-lg-4'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate(-1)}/>
              <Link to='/'>{t('cancelBackToHome')}</Link>
            </div>
            <div className={styles.mid + ' col-6 col-lg-4'}>
              <h3>{`${unit.info.name}`} <span>{`(${unit.info.type})`}</span></h3>
            </div>
            <div className={styles.end + ' col-3 col-lg-4'}>
              <button onClick={() => onClick_backToRoomsUnit()}><span>{t('edit')}</span><Icons.BiEdit /></button>
              {/* <button><Icons.FiUpload /></button> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className={styles.costRecipt}>
                <div className={styles.title}>
                  <span>{t('rooms')}</span><span>{date}</span>
                </div>
                <div className={styles.rooms}>
                  <div className={styles.roomList}>
                    {unit.rooms.map(item => {
                      return (
                        // <p>{lang.name === 'AR' ? item.roomFk.arabic_name : item.roomFk.english_name}</p>
                        <p>{item.roomFk.name}</p>
                      );
                    })}
                  </div>
                  <div className={styles.ojeeStamp}>
                    <img src={Images.OjeeStamp} alt="Ojee Stamp" />
                  </div>
                </div>
                <div className={styles.costCaution}>
                  <p>{t('costCaution')}</p>
                </div>
                <div className={styles.costEstimate}>
                  <div>
                    <p className={styles.approxCostQuote}>{t('approxCost')}</p>
                  </div>
                  <div className={styles.approxCost}>
                    <p>{thousands_separators(data.show_price.price)} LE</p>
                    {/* <p>{thousands_separators(unit.info.beforeCost)} LE</p> */}
                  </div>
                </div>
                {/* <div className={styles.question}>
                  <span>{t('negotiateCost')}</span>  <Link to=''>{t('pressHere')}</Link>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-lg-7 p-0 ">
              <div className={styles.caution}>
                <h5>{t('costCoationTitle')}</h5>
                <ul>
                  <li>{t('costCoationline1')}</li>
                  <li>{t('costCoationline2')}</li>
                  <li>{t('costCoationline3')}</li>
                  <li>{t('costCoationline4')}</li>
                  <li>{t('costCoationline5')}</li>
                  <li>{t('costCoationline6')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <p>{t('agreeToTermsAndConditions')} <Link to='/TermsOfUse'>{t('terms')}</Link> {t('and')} <Link to='/PrivacyPolicy'>{t('ploicy')}</Link></p>
            </div>
            <div className={styles.mid + ' col-12 col-lg-5'}>
              <button className='btn' onClick={() => saveToAndRedirectToDraft()} ><Icons.AiOutlineArrowRight /> <span>{t('saveToDraft')}</span></button>
              <button className='btn' onClick={() => saveAndGoToDesignPage()} ><span>{t('sendToDesign')}</span><Icons.AiOutlineArrowLeft /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UnitCost.propTypes = {};

UnitCost.defaultProps = {};

export default UnitCost;
