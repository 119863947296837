import React, { useState } from 'react'
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import * as images from '../../Assets/images';
import { useQuery } from '@apollo/client';
import Carousel from 'react-bootstrap/Carousel';
import { LoadingSpinner } from '../../Pages';
import {
  GET_SLIDER
} from '../../Graphql/Home/queries';
const Header = () => {
  const [index, setIndex] = useState(0);
  const sliderResp = useQuery(GET_SLIDER);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  if (sliderResp?.loading) return (
    <LoadingSpinner />
  );
  if (sliderResp?.error) return `Submission error! of sliderResp ${sliderResp.error.message}`;
  return (
    <div className={styles.Header} id="header_carousel" >
      <Carousel fade activeIndex={index} className={styles.carouselItemContainer + " headerCarousel"} onSelect={handleSelect}>
        <Carousel.Item className={styles.carouselItem}>
          <div className={styles.adContainer} >
            <div className={styles.ad_image_background} style={{
              backgroundImage: `url(${images.slider_web_1})`,
              backgroundRepeat: 'no-repeat',
              // 'background-attachment': 'fixed',
              backgroundPosition: 'center',
              backgroundSize: '100% 100%',
            }}></div>
            <div className={styles.body_container + " container"}>
              <div className="col-12 col-lg-6">
                <div className={styles.body}>
                  <h2>{t('header_title')}</h2>
                  <p>{t('header_description')}</p>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className={styles.carouselItem}>
          <div className={styles.adContainer} >
            <div className={styles.ad_image_background} style={{
              backgroundImage: `url(${images.slider_web_2})`,
              backgroundRepeat: 'no-repeat',
              // 'background-attachment': 'fixed',
              backgroundPosition: 'center',
              backgroundSize: '100% 100%',
            }}></div>
            <div className={styles.body_container + " container"}>
              <div className="col-12 col-lg-6">
                <div className={styles.body}>
                  <h2>{t('header_title_2')}</h2>
                  <p>{t('header_description_2')}</p>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className={styles.carouselItem}>
          <div className={styles.adContainer}>
            <div className={styles.ad_image_background} style={{
              backgroundImage: `url(${images.slider_web_3})`,
              backgroundRepeat: 'no-repeat',
              // 'background-attachment': 'fixed',
              backgroundPosition: 'center',
              backgroundSize: '100% 100%',
            }}></div>
            <div className={styles.body_container + " container"}>
              <div className="col-12 col-lg-6">
                <div className={styles.body}>
                  <h2>{t('header_title_3')}</h2>
                  <p>{t('header_description_3')}</p>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      {/* <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className={styles.carouselIndicators + ' carousel-indicators'}>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-label="Slide 1" className="active" aria-current="true" ></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target={"#carouselExampleIndicators"} data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className={styles.carouselItemContainer + " carousel-inner"}>
          <div className="container">
            <div className={styles.carouselItem + " carousel-item active"} >
              <div className={styles.adContainer} style={{
                backgroundImage: `url(${images.slider_1})`,
                backgroundRepeat: 'no-repeat',
                // 'background-attachment': 'fixed',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%',
              }}>
                <div className={styles.body_container}>
                  <div className="col-12 col-lg-6">
                    <div className={styles.body}>
                      <h2>{t('header_title')}</h2>
                      <p>{t('header_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.carouselItem + " carousel-item"} >
              <div className={styles.adContainer} style={{
                backgroundImage: `url(${images.slider_2})`,
                backgroundRepeat: 'no-repeat',
                // 'background-attachment': 'fixed',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%',
              }}>
                <div className={styles.body_container}>
                  <div className="col-12 col-lg-6">
                    <div className={styles.body}>
                      <h2>{t('header_title_2')}</h2>
                      <p>{t('header_description_2')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.carouselItem + " carousel-item"} >
              <div className={styles.adContainer} style={{
                backgroundImage: `url(${images.slider_3})`,
                backgroundRepeat: 'no-repeat',
                // 'background-attachment': 'fixed',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%',
              }}>
                <div className={styles.body_container}>
                  <div className="col-12 col-lg-6">
                    <div className={styles.body}>
                      <h2>{t('header_title_3')}</h2>
                      <p>{t('header_description_3')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className={styles.prevBtn + " carousel-control-prev"} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button className={styles.nextBtn + " carousel-control-next"} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div> */}
    </div>
  )
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
