import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: {
    id: '',
    name: '',
    type: '',
    category: '',
    cityID: '',
    areaID: '',
    floor: '',
    cost: '',
    beforeCost: ''
  },
  unitTypes: [
    {
      id: 'house',
      name: 'شقة',
      isChecked: false,
      isAva: true,
      img: ''
    },
    {
      id: 'studio',
      name: 'استوديو',
      isChecked: false,
      isAva: true,
      img: ''
    },
    {
      id: 'villa',
      name: 'فيلا',
      isChecked: false,
      isAva: false,
      img: ''
    },
    {
      id: 'duplex',
      name: 'دوبليكس',
      isChecked: false,
      isAva: false,
      img: ''
    },
  ],
  unitTypesAsBook: [
    {
      id: 'residential',
      name: 'سكني',
      isChecked: false,
      isAva: true,

    },
    {
      id: 'commercial',
      name: 'تجاري',
      isChecked: false,
      isAva: false,
    },
    {
      id: 'managerial',
      name: 'إداري',
      isChecked: false,
      isAva: false,
    }
  ],
  colorPallet: [
    '#561A24',
    '#DF970E',
    '#DDAB4C',
    '#F4CC8E',
    '#0062A9',
    '#E5313E',
    '#FFD90A',
    // '#FFFFFF',
    '#29272A',
    '#A4B3B0',
    '#5B2620',
    '#AA6941',
    '#102A1F',
    '#B4D3D5',
    '#015939',
    '#546471',
    //new rooms colors
    // '#7FB2D8',
    // '#BBAB7A',
    // '#88ACD8',
    // '#848CD8',
    // '#048971',
    // '#AAC699',
    // '#93A09E',
    // '#E17A77',
    // '#D8A33F',
    // '#99B9C0',
  ]
};

export const UnitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    setUnitIfnoParam: (state, action) => {
      state.info[action.payload.name] = action.payload.value
    },
    checkOnUnitTypesAsBook: (state, action) => {
      let newValue = !state.unitTypesAsBook[action.payload].isChecked;
      let isOneChecked = false
      state.unitTypesAsBook.filter(item => {
        if (item.isChecked === true)
          isOneChecked = true
      });
      // console.log(isOneChecked)
      if (!isOneChecked || !newValue) {
        state.unitTypesAsBook[action.payload].isChecked = newValue
      }
      newValue ? state.info.category = state.unitTypesAsBook[action.payload].id : state.info.category = ''
    },
    checkOnUnitType: (state, action) => {
      let newValue = !state.unitTypes[action.payload].isChecked;
      let isOneChecked = false
      state.unitTypes.filter(item => {
        if (item.isChecked === true)
          isOneChecked = true
      });
      // console.log(isOneChecked)
      if (!isOneChecked || !newValue) {
        state.unitTypes[action.payload].isChecked = newValue
      }
      newValue ? state.info.type = state.unitTypes[action.payload].id : state.info.type = ''
    },
    addList: (state, action) => {
      state[action.payload.name] = []
      state[action.payload.name] = action.payload.value;
    },
    markChoiceFromSelect: (state, action) => {
      var list = state[action.payload.name];
      var choice = list[action.payload.choiceIndex];
      // var newValue = choice.checked ? false : !choice.checked
      // var isOneChecked = false;
      var indexCount = 0;
      state[action.payload.name].map(roomName => {
        if (indexCount == action.payload.choiceIndex)
          roomName.checked = !roomName.checked
        else
          roomName.checked = false
        indexCount++;
      })
      // state[action.payload.name].filter(choice => {
      //   if (choice.checked)
      //     isOneChecked = true;
      // });
      // if (!isOneChecked || !newValue) {
      //   choice.checked = newValue;
      // }
    },
    addNewRoom: (state, action) => {
      // console.log(action.payload.roomName);
      let newRoom = InitRoom();
      // console.log(newRoom);
      newRoom.roomFk.room_names_Fk = action.payload.roomName;
      state.rooms.push(newRoom)
    },
    setCurrentRoomValues: (state, action) => {
      let room = state.rooms[action.payload.index]?.roomFk;
      state.rooms.splice(action.payload.index, 1);
      state.rooms.splice(2, 0, action.payload.value);
      // if (action.payload.value.width) {
      //   room.roomShap = "SqureShape";
      // } else {
      //   room.roomShap = "LShape";
      // }
    },
    setUnitRoomData: (state, action) => {
      let room = state.rooms[action.payload.index]?.roomFk;
      room[action.payload.param.name] = action.payload.param.value
    },
    setUnitRoomDoorsData: (state, action) => {
      let doors = state.rooms[action.payload.index].roomFk.doors;
      doors[0][action.payload.param.name] = action.payload.param.value
    },
    setUnitRoomDoorsOrWindowsCount: (state, action) => {
      let _count = state.rooms[action.payload.index].roomFk._count;
      _count[action.payload.param.name] = action.payload.param.value
    },
    setUnitRoomWindowsData: (state, action) => {
      let windows = state.rooms[action.payload.index].roomFk.windows;
      windows[0][action.payload.param.name] = action.payload.param.value
    },
    toggleUnitRoomAccessories: (state, action) => {
      let room = state.rooms[action.payload.index].roomFk;
      if (!room.utility_factors) {
        room.utility_factors = [];
      }
      var index = -1;
      var count = 0;
      room.utility_factors.forEach(element => {
        if (action.payload.value.utility_factor_Fk.id == element.utility_factor_Fk.id) {
          index = count;
          return;
        }
        count++;
      });
      if (index > -1) {
        room.utility_factors.splice(index, 1);
      } else {
        room.utility_factors.push(action.payload.value)
      }
    },
    emptyRoomDimentionsOnShapChange: (state, action) => {
      let room = state.rooms[action.payload.index].roomFk;
      room.roomShap = action.payload.roomShap;
      room.width = '';
      room.length = '';
      room.height = '';
      room.one = '';
      room.two = '';
      room.three = '';
      room.four = '';
    },
    resetUnitState: (state) => {
      localStorage.clear();
      return initialState;
    },
    initUnitState: (state) => {
      return initialState;
    }

  }
});
const InitRoom = () => {
  return {
    roomFk: {
      id: '',
      isDeleteable: '',
      english_name: '',
      arabic_name: '',
      isAirConditioning: '',
      name: '',
      width: '',
      length: '',
      height: '',
      one: '',
      two: '',
      three: '',
      four: '',
      isCalculatable: '',
      calculated_price: '',
      wall_color: '',
      isGallery: '',
      image_url: '',
      room_names_Fk: {
        id: '',
        english_name: '',
        arabic_name: '',
      },
      finishing_cost_Fk: {
        id: '',
        english_name: '',
        arabic_name: ''
      },
      finishing_style_Fk: {
        id: '',
        english_name: '',
        arabic_name: ''
      },
      wallMaterial: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      wallMaterialAddon1: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      wallMaterialAddon2: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      cielingMaterial: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      cielingMaterialAddon1: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      cielingMaterialAddon2: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      floorMaterial: {
        id: '',
        english_name: '',
        arabic_name: '',
        decoration_type: ''
      },
      utility_factors: [],
      // {
      //   utility_factor_Fk: {
      //     id: '',
      //     english_name: '',
      //     arabic_name: ''
      //   }
      // }
      _count: {
        doors: '',
        windows: ''
      },
      doors: [],
      windows: []
    }
  }
}
export const {
  setUnitIfnoParam,
  checkOnUnitTypesAsBook,
  checkOnUnitType,
  addList,
  markChoiceFromSelect,
  addNewRoom,
  setCurrentRoomValues,
  setUnitRoomData,
  setUnitRoomDoorsOrWindowsCount,
  setUnitRoomDoorsData,
  setUnitRoomWindowsData,
  toggleUnitRoomAccessories,
  emptyRoomDimentionsOnShapChange,
  resetUnitState,
  initUnitState
} = UnitSlice.actions;


export const selectUnit = (state) => state.unit;


export default UnitSlice.reducer;