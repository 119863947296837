import React, { useState, useEffect } from 'react'
import styles from './Complain.module.scss';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import * as Icons from '../../Assets/icons';
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client';
import { SEND_COMPLAINS } from '../../Graphql/Services/mutauions';
import { selectUser } from '../../Reducer/UserSLice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
const Complain = () => {
  const [complaionMsg, setComplaionMsg] = useState('')
  const [sendComplain, { data, loading, error }] = useMutation(SEND_COMPLAINS);
  const user = useSelector(selectUser);
  const navigate = useNavigate()
  const inSubmit_sendComplain = (e) => {
    sendComplain({
      variables: {
        message: complaionMsg,
        userID: +user.user.userId
      }
    })
  }
  useEffect(() => {
    if (data?.sendComplaint?.done) {
      toast.success(t('complaintSentSuccessfully'), { autoClose: 1000 });
      setComplaionMsg('');
    }
  }, [data]);
  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <div className={styles.Complain}>
      {loading && <LoadingSpinner />}
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start + ' col-12 col-lg-8'}>
              <Icons.AiOutlineArrowLeft onClick={() => navigate('/Home')} />
              <Link to='/'>{t('home')}</Link>
              <span> \ </span>
              <Link to='/Complain'>{t('applycomplains')}</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.formBody}>
          <div className={styles.headerTitle}>
            <p>{t('typeYourComplain')}</p>
          </div>
          <div className={styles.complainContainer}>
            <label htmlFor="">{t('message')}</label>
            <textarea type='text' rows="6" value={complaionMsg} className="form-group row" onChange={(e) => setComplaionMsg(e.target.value)} />
          </div>
        </div>
        <div className={styles.submit}>
          <div class='row justify-content-between'>
            <div className='col-auto'>
              <p>{t('willRespondSoon')}</p>
            </div>
            <div className='col-auto'>
              <button type="submit" class="btn" onClick={() => inSubmit_sendComplain()}>{t('send')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Complain
