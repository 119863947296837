import React, { useState, useEffect } from 'react'
import OTPInput from "otp-input-react"
import { Form, Button, Card } from "react-bootstrap";
import * as images from '../../Assets/images';
import { Link } from 'react-router-dom'
import styles from './Verification.module.scss'
import { useNavigate } from 'react-router-dom';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { setUseOtp, setUser } from '../../Reducer/UserSLice';
import { useDispatch, useSelector } from 'react-redux';
import { GET_OTP } from "../../../src/Graphql/Authentication/Queries"
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import {
  selectUser
} from '../../Reducer/UserSLice';

function Verification() {
  const phoneNum = useSelector((state) => state.user.user.phone)
  const storeOtp = useSelector((state) => state.user.otp)
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [OTP, setOTP] = useState("");
  var user = useSelector(selectUser);

  const [getOTP, OTPResult] = useLazyQuery(GET_OTP, {
    variables: { phoneNumber: phoneNum }
  });
  const resendOTP = (e) => {
    e.preventDefault();
    OTPResult.refetch({ phoneNumber: phoneNum });
    getOTP({
      variables: { phoneNumber: phoneNum },
    })
  }
  useEffect(() => {
    if (user.OTPMode === 'Regitser') {
      dispatch(setUseOtp(OTPResult?.data?.OTP?.OTP))
    }
  }, [OTPResult.data])

  useEffect(() => {
    if (user.OTPMode === 'Regitser') {
      OTPResult.refetch({ phoneNumber: phoneNum });
      getOTP({
        variables: { phoneNumber: phoneNum },
      })
    }
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault();
    if (storeOtp == OTP) {
      if (user.OTPMode === 'Regitser') {
        navigate('/Login');
      } else {
        navigate('/ResetPassword');
      }
    } else {
      toast.error(t('invalidOTP'), { autoClose: 1000 });
    }
  }
  return (
    <>
      <div className={styles.Verification}>
        <div className="container">
          <Card className={styles.Login}>
            <form>
              <div className=" p-4 box">
                <div className="row d-flex justify-content-center  text-center align-items-center ">
                  <img src={images.lock} alt="OTP Verification" className={styles.Lock} />
                  <h6>{t('veifyOTP')}</h6>
                  <p>{t('enterOTP')}</p>
                </div>

                <div className={styles.OTP_container}>
                  <OTPInput
                    otpType="number"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    disabled={false}
                  />
                </div>
                <div className={styles.Last}>
                  <div className={styles.resendBtn + ' d-flex'}>
                    <span>{t('coundRecieveOTP')}</span>
                    <button type='button' onClick={(e) => resendOTP(e)}>{t('resendOTP')}</button>
                  </div>
                  <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                    {t('next')}
                  </Button>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </>
  )
}


export default Verification