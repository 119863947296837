import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from './Login.module.scss'
import { IoIosEye, IoMdEyeOff } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../Reducer/UserSLice';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { LOGIN } from "../../../src/Graphql/Authentication/Queries"
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { deviceIDToken } from '../../ENV'
import {  } from '../../fireBase';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passwordInput, setPasswordInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [TokenFound, setTokenFound] = useState(null);
  const phoneChange = (evnt) => {
    setPhoneInput(evnt.target.value)
    // console.log(evnt.target.value)
  }
  const passwordChange = (evnt) => {
    setPasswordInput(evnt.target.value)
    // console.log(evnt.target.value)
  }
  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  const [LoginFun, UserData] = useLazyQuery(LOGIN);
  // const Notification = () => {
  //   requestPermission(setTokenFound);
  // }
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(TokenFound);
    if (phoneInput && passwordInput) {
      LoginFun({
        variables: {
          phoneNumber: phoneInput,
          password: passwordInput,
          device_id: TokenFound
        }
      })
    } else {
      return alert("invalid Data")
    }
  }
  useEffect(() => {
    if (UserData?.data?.login) {
      var user = UserData.data.login;
      dispatch(setUser(user))
      // Copy the below lines under window.fcWidget.init inside fw_chat_widget function in the above snippet
      // To set unique user id in your system when it is available
      window.fcWidget.setExternalId(`${user.username}.userId_${user.username}`);
      // To set user name
      window.fcWidget.user.setFirstName(user.username);
      // To set user email
      window.fcWidget.user.setEmail(user.email);
      // To set user properties
      // Note that any other fields passed as properties which are not in the CRM Contact properties already, they will be ignored.
      window.fcWidget.user.setProperties({
        cf_plan: "Pro",                 // meta property 1
        cf_status: "Active"             // meta property 2
      });
      console.log("user chat logged in for user name " +user.username);
      navigate('/home');
    }
  }, [UserData?.data]);
  useEffect(() => {
    if (UserData?.error) {
      toast.error(t('worngUserOrPassword'), { autoClose: 1000 });
    }
  }, [UserData?.error]);
  // useEffect(() => {
  //   Notification();
  // }, [])
  return (
    <>
      <div className={styles.SignIn}>
        <div className="container">
          <Card className={styles.Login}>
            <div className=" p-4 box">
              <div className="d-flex justify-content-between align-items-center">
                <h6><Link to="/Login">{t('loginHeader')}</Link></h6> &nbsp;
                <p ><Link to="/Signup">{t('createAccount')}</Link></p>
              </div>
              <Form >
                <Form.Group className="border-bottom mt-4" >
                  <Form.Control
                    type="text"
                    pattern="[0-9]*"
                    placeholder={t('phoneNumber')}
                    name="phone"
                    value={phoneInput}
                    onChange={(evnt) => phoneChange(evnt)}
                    autoComplete="off"
                    required

                  />
                </Form.Group>

                <Form.Group className={styles.passwordContainer + " border-bottom mt-2"} >
                  <Form.Control
                    placeholder={t('password')}
                    className='col '
                    type={changePassword ? "password" : "text"}
                    name="password"
                    value={passwordInput}
                    onChange={(evnt) => passwordChange(evnt)}
                    autoComplete="off"
                    required
                  />
                  <showpassword className={styles.showPass} onClick={() => { setChangePassword(changeIcon); }}>
                    {changeIcon ? <IoIosEye size={30} style={{ color: "#A9A9A9" }} /> : <IoMdEyeOff size={30} style={{ color: "#A9A9A9" }} />}
                  </showpassword>
                </Form.Group>

                <div className="p-4 box  text-center">
                  <Link to="/ForgetPassword" className={styles.ForgetPass}>{t('forgotPassword')}</Link>
                </div>
                <br /> <br />
                <div className="p-4 box  text-center">
                  <span>{t('confirmOn')}</span>
                  <br />
                  <Link to="/TermsOfUse">{t('termsAndCond')}</Link> &nbsp;
                  <Link to='/PrivacyPolicy'>{t('privayPolicy')}</Link>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-4">
                  {/* <div >
                  <img src={images.appleForm} alt="apple" className={styles.socialIcons} />
                  <img src={images.facebookForm} alt="facebook" className={styles.socialIcons} />
                  <img src={images.googleForm} alt="google" className={styles.socialIcons} />

                </div>
                <span>&rarr; أو التسجيل عن طريق  </span> */}

                  <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)} >
                    {t('next')}
                  </Button>
                </div>

              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Login