import React, { useState, useEffect } from 'react'
import styles from './ApplyServices.module.scss';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { APPLY_SERVICES } from '../../Graphql/Services/mutauions';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import * as Icons from '../../Assets/icons';
import { selectLang } from '../../Reducer/LangSlice';
import { selectUser } from '../../Reducer/UserSLice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomInput } from '../../components';
import { Validators } from '../../services/validators';

const ApplyServices = () => {
  const lang = useSelector(selectLang);
  const user = useSelector(selectUser);
  const navigate = useNavigate()
  const [fieldsOfWork, setFieldsOfWork] = useState([])
  const [companyData, setCompanyData] = useState({
    name: '',
    address: '',
    commercialRegister: '',
    taxNumber: '',
    hasPreviousWork: false,
    contactPerson: '',
    personPosition: '',
    personEmail: '',
    personPhone: '',
  })
  const Areas = [
    { name_ar: 'وحدات اضاءة و نجف', name_en: 'Lighting units and chandeliers', value: '' },
    { name_ar: 'تابلوهات و جاليري', name_en: 'Tableaus and galleries', value: '' },
    { name_ar: 'الستائر والمفروشات', name_en: 'Curtains  and furnishings', value: '' },
    { name_ar: 'غرف تبديل الملابس', name_en: 'Dressing rooms', value: '' },
    { name_ar: 'النوافذ والأبواب الخشبية', name_en: 'Windows and wooden doors', value: '' },
    { name_ar: 'وحدات المطبخ', name_en: 'Kitchen units', value: '' },
    { name_ar: 'اكسسوارات و فرش المطبخ', name_en: 'Kitchen accessories and brushes', value: '' },
    { name_ar: 'فرش المنزل', name_en: 'Home brushes', value: '' },
  ]
  const [errors, setErrors] = useState({})

  const onChange = (value, name) => {
    setCompanyData({ ...companyData, [name]: value })
  }
  const [addCompany, { loading, error, data }] = useMutation(APPLY_SERVICES, {
    variables: {
      userID: +user.user.userId,
      name: companyData.name,
      commercial_record: companyData.commercialRegister,
      tax_id: companyData.taxNumber,
      is_work_for_review: companyData.hasPreviousWork,
      contact_person: companyData.contactPerson,
      cp_phone: companyData.personPhone,
      cp_title: companyData.personPosition,
      email: companyData.personEmail,
      fields_of_work: fieldsOfWork
    },
  });
  const onChage_AreaChecked = (value) => {
    if (fieldsOfWork.indexOf(value) == -1)
      setFieldsOfWork(oldArray => [...oldArray, value]);
    else {
      var tempArray = fieldsOfWork.filter(ele => { return ele != value });
      setFieldsOfWork(tempArray);
    }
    // console.log(fieldsOfWork);
  }
  const onSubmit_sendApplication = (e) => {
    e.preventDefault();
    var errorFlag = false;
    console.log(companyData);
    for (const key in companyData) {
      if (Object.hasOwnProperty.call(companyData, key)) {
        const element = companyData[key];
        if (key == 'hasPreviousWork') {
          continue
        }
        if (!element) {
          toast.warn(t('requiredFileds'), { autoClose: 1000 });
          console.log("key " + key);
          errorFlag = true;
          return;
        }
      }
    }
    // if (errorFlag) {

    //   return errorFlag;
    // } else {
    addCompany({
      variables: {
        name: companyData.name,
        contact_person: companyData.contactPerson,
        cp_phone: companyData.personPhone,
        cp_title: companyData.personPosition,
        email: companyData.personEmail,
        commercial_record: companyData.commercialRegister,
        tax_id: companyData.taxNumber,
        is_work_for_review: companyData.hasPreviousWork,
        fields_of_work: fieldsOfWork
      }
    })
    // }
  }
  useEffect(() => {
    if (data?.FinishingSupplier?.done) {
      toast.success(t('requestSendSuccessfuly'), { autoClose: 1000 });
      navigate('/');
    }
  }, [data]);
  useEffect(() => {
    console.log(error)
  }, [error]);
  return (
    <div className={styles.ApplyServices}>
      <div className={styles.header}>
        <div className="container">
          <div className="row">
            <div className={styles.start}>
              <Icons.AiOutlineArrowRight onClick={() => navigate('/Home')} />
              <Link to='/'>{t('home')}</Link>
              <span> \ </span>
              <Link to='/ApplyingFinishingCompanies'>{t('materialsAndProducts')}</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        {/* <div className="container"> */}
        <div className="row">
          <div className={styles.AllFiledsCotaniner + " col-12 col-lg-6 p-0"}>
            <div className={styles.right}>
              <div className={styles.formContainer}>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('companyName')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='name'
                      value={companyData.name}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                    {/* <input type="text" name='name' value={companyData.name} onChange={onChange} /> */}
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('companyAddress')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      autocomplete="off"
                      type="text"
                      name='address'
                      value={companyData.address}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('taxNumber')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      acceptOnly='number'
                      name='taxNumber'
                      value={companyData.taxNumber}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('commercialRegister')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='commercialRegister'
                      acceptOnly='number'
                      value={companyData.commercialRegister}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('contactPerson')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='contactPerson'
                      value={companyData.contactPerson}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('personPosition')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='personPosition'
                      value={companyData.personPosition}
                      validators={[
                        { check: Validators.required, message: t('RequiredField') }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('email')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='personEmail'
                      value={companyData.personEmail}
                      validators={[
                        {
                          check: Validators.required, message: t('RequiredField'),
                          check: Validators.email, message: t('validData')
                        }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.fieldContainer + " required"}>
                  <label htmlFor="">{t('phone')}</label>
                  <div className={styles.field}>
                    <CustomInput
                      className='input-group-text'
                      type="text"
                      autocomplete="off"
                      name='personPhone'
                      acceptOnly='number'
                      value={companyData.personPhone}
                      validators={[
                        {
                          check: Validators.required, message: t('RequiredField'),
                          check: Validators.mobile, message: t('validData')
                        }
                      ]}
                      onChange={onChange} />
                  </div>
                </div>
                <div className={styles.question + " required"}>
                  <p>{t('previuosWorks')}</p>
                  <div className={styles.answer}>
                    <input type="radio" name='previuosWorks' />
                    <label htmlFor="previuosWorks">{t('yes')}</label>
                  </div>
                  <div className={styles.answer}>
                    <input type="radio" name='previuosWorks' />
                    <label htmlFor="previuosWorks">{t('no')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.checkListContainer + " col-12 col-lg-6 p-0"}>
            <div className={styles.left}>
              <div className='col' >
                <div className={styles.Left + " required"}>
                  <p>{t('chooseArea')}</p>
                  {Areas.map(area => {
                    return (
                      <div className={styles.checkBoxContainer}>
                        <label className="form-check-label">{lang.name === 'AR' ? area.name_ar : area.name_en}</label>
                        <input type="checkbox"
                          className="form-check-input"
                          onChange={() => onChage_AreaChecked(area.name_en)}
                          checked={fieldsOfWork.indexOf(area.name_en) > -1 ? true : false}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className={styles.footer}>
        <div className="container">
          <div className="row justify-content-start">
            <div className={styles.start + ' col-12 col-lg-4'}>
              <p></p>
            </div>
            <div className={styles.mid + ' col-12 col-lg-4 text-center'}>
              <button className='btn' type='button' onClick={(e) => onSubmit_sendApplication(e)} ><span>{t('sendApplication')}</span><Icons.AiOutlineArrowLeft /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyServices