import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './RoomCard.module.scss';
import * as icons from '../../Assets/icons';
import * as images from '../../Assets/images';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import {
  LIKE_ROOM,
  UN_LIKE_ROOM
} from '../../Graphql/Home/mutations';
import {
  selectUser
} from '../../Reducer/UserSLice';
import { useNavigate } from 'react-router-dom';
import {
  setroomDetialedObject
} from '../../Reducer/HomeSlice';

const RoomCard = ({ roomId, imagesList, roomDetailsObject, roomIndex, roomName, roomDetails, roomRate, isRecommended, isLiked, enableRedirect, checked, afterLikeAction }) => {
  const dispatch = useDispatch();
  const [callLikedRoom, likeRoomResp] = useMutation(LIKE_ROOM);
  const [callUnLikedRoom, unlikeRoomResp] = useMutation(UN_LIKE_ROOM);
  const [like, setLike] = useState(isLiked);
  var user = useSelector(selectUser);
  const navigate = useNavigate();
  const on_click_likeRoom = (roomId) => {
    if (user.user.userId)
      var nextAction = !like;
    if (nextAction) {
      callLikedRoom({
        variables: {
          userID: +user.user.userId,
          roomId: roomId
        }
      }).then(result => {
        if (result.data.LikeRoom.done) {
          setLike(nextAction);
          // navigate("/MyFavoriteRooms");
        }
      });
    } else {
      callUnLikedRoom({
        variables: {
          userID: +user.user.userId,
          roomId: roomId
        }
      }).then(result => {
        if (result.data.UnLikeRoom.done) {
          setLike(nextAction);
        }
      })
    }
    afterLikeAction();
  }
  const navigateToRoomDetails = () => {
    dispatch(setroomDetialedObject({ value: roomDetailsObject }));
    // console.log(`/RoomDetails/${isLiked ? 1 : 0}`);
    navigate(`/RoomDetails/${isLiked ? 1 : 0}`);
  }
  return (
    <div className={`${styles.RoomCard} ${!enableRedirect ? styles.disableMouseEvents : ''}`} id={roomId}>

      {/* {isRecommended && <p className={styles.recommendedForU}>{t('recommendedForU')}</p>} */}
      <div className={styles.roomGallaryContainer}>

        {/* <div className={styles.likeBtncontainer}>
          <button className={styles.like_btn} type='button' onClick={() => on_click_likeRoom(roomId)}> </button>
          {like ? <object className={styles.like_obj} type="image/svg+xml" data={icons.redLoveIcon}></object> : <object className={styles.like_obj} type="image/svg+xml" data={icons.loveIcon}></object>}
        </div> */}
        <div className={styles.roomGallary}>
          <div className={styles.image + " pointer"} onClick={() => navigateToRoomDetails()} style={{
            backgroundImage: `url(${imagesList[0]})`
          }}></div>
          {/* <div id={"carouselExampleIndicators" + roomId} className="carousel slide" data-bs-ride="carousel">
            <div className={styles.carouselIndicators + ' carousel-indicators'}>
              {imagesList.map(image => {
                return (
                  <button type="button" data-bs-target={"#carouselExampleIndicators" + roomId} data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                )
              })}
            </div>
            <div className={styles.carousel_inner + " carousel-inner"}>
              {imagesList.map(image => {
                return (
                  <div className={styles.carouselItem + " carousel-item"}>
                    <div className={styles.image} style={{
                      backgroundImage: `url(${image})`
                    }}></div>
                  </div>
                )
              })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={"#carouselExampleIndicators"+roomId} data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={"#carouselExampleIndicators"+roomId} data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div> */}
        </div>
        <span></span>
        {/* <Link to={`/roomDetails/${roomIndex}`} > */}
        <div className={styles.showMore} onClick={() => navigateToRoomDetails()}>
          <p>{t('viewMore')}</p>
          <p className={'arrow down'}></p>
        </div>
        {/* </Link> */}
        {checked && <div className={styles.checked}>
          <icons.BsFillCheckSquareFill />
        </div>}
      </div>
      <div className={styles.roomDetails}>
        <div className="row">
          <div className="col-9">
            {/* <Link to={`/roomDetails/${roomIndex}`} > */}
            <p className='pointer' onClick={() => navigateToRoomDetails()} >{roomName}</p>
            {/* </Link> */}
            <small>{roomDetails}</small>
          </div>
          <div className="col-3">
            {/* <span>{roomRate}</span>
            <i className={styles.rate}><object type="image/svg+xml" data={icons.rateIcon}></object></i> */}
          </div>
        </div>
      </div>
    </div >
  );
};

RoomCard.propTypes = {
  images: PropTypes.array.isRequired,
  roomIndex: PropTypes.number.isRequired,
  imagesList: PropTypes.array.isRequired,
  roomId: PropTypes.number.isRequired,
  roomName: PropTypes.string.isRequired,
  roomDetails: PropTypes.string.isRequired,
  roomRate: PropTypes.number.isRequired,
  isRecommended: PropTypes.bool,
  isLiked: PropTypes.bool,
  enableRedirect: PropTypes.bool,
  checked: PropTypes.bool,
  roomDetailsObject: PropTypes.object.isRequired,
  afterLikeAction: PropTypes.func
};

RoomCard.defaultProps = {
  isRecommended: true,
  isLiked: false,
  enableRedirect: true,
  checked: false,
  afterLikeAction: () => { }
};

export default RoomCard;
