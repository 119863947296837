import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './question.module.scss';
import * as icons from '../../Assets/icons';
import {
  selectLang
} from '../../Reducer/LangSlice';

const Question = ({ item }) => {
  const [showHide, setShowHide] = useState(true);
  var lang = useSelector(selectLang);
  return (
    <div className={styles.Question}>
      <div className={styles.questionHeader}>
        <h2>{lang.name === 'AR' ? item.arabic_title : item.english_title}</h2>
        {showHide ?
          <button type='button' className={styles.toView} onClick={() => setShowHide(!showHide)}><icons.HiPlusCircle /></button> :
          <button type='button' className={styles.toHide} onClick={() => setShowHide(!showHide)}><icons.HiMinusCircle /></button>
        }
      </div>
      <div className={`${styles.questionBody} ${!showHide ? styles.show : styles.hide}`}>
        <p>{lang.name === 'AR' ? item.arabic_content : item.english_content}</p>
      </div>
    </div>
  )
};

Question.propTypes = {
  item: PropTypes.object.isRequired,
};

Question.defaultProps = {};

export default Question;
