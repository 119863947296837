import { createSlice } from '@reduxjs/toolkit';
import i18n from "i18next";

const initialState = {
    lang: {
        name: 'AR',
        locale: 'AR-EG',
        dir: 'rtl'
        // name:'EN',
        // locale:'EN-US',
        // dir:'ltr'
    }
};

export const LangSlice = createSlice({
    name: "lang",
    initialState,
    reducers: {
        initLang: (state)=>{
            document.documentElement.setAttribute("lang", state.lang.name.toLocaleLowerCase());
        },
        toggleLang: (state, action) => {
            if (state.lang.name.toLocaleLowerCase() === 'ar') {
                state.lang = {
                    name: 'EN',
                    locale: 'EN-US',
                    dir: 'ltr'
                };
                i18n.changeLanguage("en");
                document.documentElement.setAttribute("lang", 'en');
                // const ele = document.getElementById("fc_frame");
                // ele.style.right = null;
                // ele.style.left = "90vw";
            } else {
                state.lang = {
                    name: 'AR',
                    locale: 'AR-EG',
                    dir: 'rtl'
                };
                i18n.changeLanguage("ar");
                document.documentElement.setAttribute("lang", 'ar');
                // const ele = document.getElementById("fc_frame");
                // ele.style.right = "15px";
                // ele.style.left = null;
            }
        }
    }
});

export const { 
    toggleLang,
    initLang
} = LangSlice.actions;


export const selectLang = (state) => state.lang.lang;


export default LangSlice.reducer;