import React from 'react'
import styles from './TermsOfUse.module.scss';
import { useQuery } from "@apollo/client";
import {GET_TERMS} from '../../../src/Graphql/Authentication/Queries';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function TermsOfUse() {

  const {loading , error , data}=useQuery(GET_TERMS)
  if(loading) return <LoadingSpinner />;
  if(error) return "errors";
  const result=data.Pages.arabic_terms;
  const lines=result.split('\r\n');
  // console.log(result);
  return (
      <div>
          <content className={styles.termsContent}>
              <div>
              {lines.map((line,index)=> {
                    if (index===0){
                        return <h1 key={index}>{line}</h1>
                    }else{

                 return <p key={index}> {line}</p> 
                }
                  
            })}
                  {/* <p> {data.Pages.arabic_terms}</p> */}
              </div> 
             
      </content>
      </div>
  )
}

export default TermsOfUse