import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticalCard.module.scss';
import * as images from '../../Assets/images';
import * as icons from '../../Assets/icons';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
const ArticalCard = ({ id, image, date, title, description }) => {
  return (
    <div className={styles.ArticalCard}>
      <div className={styles.body}>
        <div className={styles.imageContainer}>
          <p className={styles.date}>{date}</p>
          <img src={images.artical} alt="artical image" />
        </div>
        <div className={styles.detailsContainer}>
          <p>{title}</p>
          <div className={styles.description}>
            {description}
          </div>
        </div>
        <div className={styles.footer}>
          <Link to={`/ArticalDetails/${id}`}><img src={icons.leftGreenArrow} alt="arrow" /> <span>{t('readArtical')}</span></Link>
        </div>
      </div>

    </div>
  );
}

ArticalCard.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

ArticalCard.defaultProps = {};

export default ArticalCard;
