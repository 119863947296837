import './App.scss';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { Footer, NavBar, TopNav, ScrollToTop } from './components';
import {
  RoomDetails,
  Home,
  Rooms,
  ArticalDetails,
  Articals,
  About,
  Questions,
  Complain,
  UnitForm,
  UnitsDraft,
  Signup, Login,
  ForgetPassword,
  ResetPassword,
  ApplyingCompanies,
  ApplyServices,
  // RatingModel,
  TermsOfUse,
  PrivacyPolicy,
  Packages,
  Verification,
  UserProfile,
  MyFavoriteRooms
} from './Pages';
import * as icons from './Assets/icons';
import { useTranslation } from "react-i18next";
import {
  UnitDefine,
  UnitRooms,
  UnitRoomForm,
  UnitCost,
  UnitDesignPlans,
  UnitInspectionDate,
  UnitThanks
} from './Pages/UnitForm';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectLang
} from './Reducer/LangSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from "i18next";
import { onMessageListener, getCurrentToken } from './fireBase';
import { Toast } from 'react-bootstrap';
import {
  initLang
} from './Reducer/LangSlice';
import {
  selectUser
} from './Reducer/UserSLice';
const App = () => {
  const { t } = useTranslation();
  var user = useSelector(selectUser);
  const goUp = () => {
    window.scrollTo(0, 0);
  }
  const location = useLocation();
  const pathName = location.pathname;
  let lang = useSelector(selectLang);
  // var user = useSelector(selectUser);
  useEffect(() => {
    if (lang.name === 'AR') {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  getCurrentToken(setTokenFound);
  dispatch(initLang());
  onMessageListener().then(payload => {
    setShow(true);
    console.log(payload);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    console.log(payload);
  }).catch(err => console.log('failed: ', err));
  return (
    //TODO: change all icons from svg to react icons
    <div id="ojee-web" className='ojee-web' dir={lang.dir} lang={lang.name.toLocaleLowerCase()}>
      <Toast id="toast_notify" onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
        position: 'absolute',
        top: 20,
        right: 20,
        minWidth: 200
      }}>
        <Toast.Header>
          <div className="data">
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </div>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast>
      <TopNav />
      <NavBar />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/searchResult" element={<Rooms />} />
        <Route path="/RoomDetails/:isLiked" element={<RoomDetails />} />
        <Route path="/ArticalDetails/:id" element={<ArticalDetails />} />
        <Route path="/Articals" element={<Articals />} />
        <Route path="/About" element={<About />} />
        <Route path="/Questions" element={<Questions />} />
        <Route path="/Complain" element={<Complain />} />
        <Route path="/MyFavoriteRooms" element={<MyFavoriteRooms />} />
        <Route path="/UnitForm" element={<UnitForm />}>
          <Route index element={<UnitDefine />} />
          <Route path="rooms" element={<UnitRooms />} />
          <Route path="rooms/:unitId" element={<UnitRooms />} />
          <Route path="roomForm/:index" element={<UnitRoomForm />} />
          <Route path="roomForm/:index/:updateFlag" element={<UnitRoomForm />} />
          <Route path="cost/:unitId" element={<UnitCost />} />
          <Route path="designPlans/:unitId" element={<UnitDesignPlans />} />
          <Route path="inspectionDate" element={<UnitInspectionDate />} />
          <Route path="thanks" element={<UnitThanks />} />
          <Route path="*" element={<UnitDefine />} />
        </Route>
        <Route path="/UnitsDraft" element={<UnitsDraft />} />
        <Route path="/ApplyingFinishingCompanies" element={<ApplyingCompanies />} />
        <Route path="/ApplyServices" element={<ApplyServices />} />
        {/* <Route path="/RatingModel" element={<RatingModel />} /> */}
        <Route path="/Login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Verification" element={<Verification />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/TermsOfUse" element={<TermsOfUse />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Packages" element={<Packages />} />
        <Route path="/UserProfile" element={<UserProfile />} />
      </Routes>
      <Footer />
      {!pathName.toLocaleLowerCase().includes('unitform') && <Link className='addUnitBtn btn' to={'/UnitForm'}> <img src={icons.addUnitIcon} alt="" /> <span>{t('addUnit')}</span></Link>}
      <button className='goUpBtn' onClick={() => goUp()} type='button'><img src={icons.arrowUp} alt="" /></button>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ScrollToTop />
    </div>
  );
}

export default App;
