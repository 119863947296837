import React from 'react';
import PropTypes from 'prop-types';
import styles from './UnitForm.module.scss';
import { Outlet } from "react-router-dom";
const UnitForm = () => {
  // <Icons.AiOutlineArrowRight />
  return (
    <div className={styles.UnitForm}>
      {/* <div className="container"> */}
        <Outlet />
      {/* </div> */}
    </div>
  );
};

UnitForm.propTypes = {};

UnitForm.defaultProps = {};

export default UnitForm;
