import React, { useState, useEffect } from 'react'
import { Form, Button, Card } from "react-bootstrap";
import "react-phone-number-input/style.css";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from './ForgetPassword.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import {
  setForgetMode,
  setUseOtp,
  setPhone
} from '../../Reducer/UserSLice';
import { FORGET_PASSWORD } from "../../../src/Graphql/Authentication/Queries"
import { useLazyQuery } from '@apollo/client';
import { LoadingSpinner } from '..';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const ForgetPassword = () => {

  const EmailAccount = useSelector((state) => state.user.email)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [phoneNo, setEmailValue] = useState();
  const [OTP, setOTP] = useState();
  const onChange = (evnt) => {
    setEmailValue(evnt.target.value)
  }
  const [ForgetFun, { loading, error, data }] = useLazyQuery(FORGET_PASSWORD);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNo) {
      ForgetFun({
        variables: {
          phone: phoneNo,
        }
      })
    } else {
      toast.warn(t('PhoneRequired'), { autoClose: 1000 });
    }
  }
  useEffect(() => {
    if (data?.ForgetPassword?.OTP) {
      // console.log(data.ForgetPassword.OTP);
      dispatch(setUseOtp(data.ForgetPassword.OTP))
      dispatch(setPhone(phoneNo))
      dispatch(setForgetMode())
      navigate('/verification');
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      toast.error(t('userNotExist'), { autoClose: 1000 });
    }
  }, [error]);
  return (
    <>
      {loading && <LoadingSpinner />}
      <div className={styles.ForgetPassword}>
        <div className="container">
          <Card className={styles.Login}>
            <div className=" p-4 box">
              <div className="row d-flex">
                <h6 className="d-flex justify-content-center  text-center align-items-center">
                  {t('forgetPassword')}
                </h6>
              </div>
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Group className=" row border-bottom mt-2" >
                  <Form.Control
                    className={styles.Phone}
                    placeholder={t('phoneNumber')}
                    type='email'
                    value={phoneNo}
                    onChange={onChange}
                    required />
                </Form.Group>
                <div className="p-4 box  text-center mt-4">
                  <p>{t('acceptOn')} </p>
                  <Link to="/TermsOfUse">{t('termsAndCond')} </Link> &nbsp;
                  <Link to='/PrivacyPolicy'>{t('privayPolicy')} </Link>
                </div>
                <div className="d-flex justify-content-end align-items-center mt-4 ">
                  <Button variant="primary" type="button" onClick={(e) => handleSubmit(e)}>
                    {t('next')}
                  </Button>
                </div>

              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword