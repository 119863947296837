import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './OgeeButton.module.scss';
import { Button } from "react-bootstrap";


const OgeeButton = ({ onClick, varaiant, type, className,children }) => {
  const buttonRef = useRef(null);
  const onClick_HandleEvent = (event) => {
    event.preventDefault();
    var currentBtn = buttonRef.current;
    currentBtn.disabled = true;
    onClick();
    setTimeout(() => {
      currentBtn.disabled = false;
    }, 1000);
  }
  return (
    <Button className={className} varaiant={varaiant} ref={buttonRef} type={type} onClick={onClick_HandleEvent}>{children}</Button>
  )
};

OgeeButton.propTypes = {
  onClick: PropTypes.func,
  varaiant: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  children:PropTypes.any
};

OgeeButton.defaultProps = {
  onClick: () => { },
  type: 'button',
  varaiant: 'primary',
  className: ''
};

export default OgeeButton;
